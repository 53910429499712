import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { forEach, get, has, isUndefined, trim } from 'lodash'
import {
  Callout,
  Checkbox,
  ComboBox,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  Dropdown,
  FontWeights,
  IComboBoxOption,
  IDropdownOption,
  ITag,
  Label,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  TagPicker,
  Text,
  TextField
} from '@fluentui/react'
import AddNotes from './AddNotes'
import {
  dropdownStyles,
  headingStackTokens,
  rowSpacingStackTokens,
  sectionStackTokens,
  separatorStyles,
  stackStyles,
  textFieldStyles,
  unitDropdownStyles
} from '../../../styles'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import {
  getAbasCustomerData,
  insertMeterInfo,
  mapMeterSizeAsDropdownOption,
  searchMeterInfoBySerialNumber,
  selectGasTypes,
  selectMeterInfo,
  selectMeterSizes, TAbasCriteria
} from '../../../utils/azure_function_calls'
import { SAECalculator } from '../../../calculations/calculate_sae'
import {
  createEmptyMeterInfo,
  IFloMeterParameters,
  IMeterSizeDropdownOption,
  ISAeCalculatorState,
  ITblMeterInfo
} from '../../../interfaces'
import { PrintShopCardDialog } from './PrintShopCard'
import { getValidationErrors, persistMeterInfo } from './meterInfoUtils'
import { useId } from '@uifabric/react-hooks'
import { findPressureUnitByName, findScaleUnitByName, findTemperatureUnitByName, units } from '../../../utils/constants'
import { callAzureFunctionDelete, callAzureFunctionGet } from '../../../utils/callAzureFunction'
import { useParams } from 'react-router-dom'
import GasTypeSelector from '../../../components/GasTypeSelector'
import DuplicateMeterSizeDialog from './DuplicateMeterSize'
import lookupDashNo from '../../../utils/lookup_needle_size'
import { formatNum } from '../../../utils/formatters'
import { calculateFlow } from '../../../calculations/calculate_flow'
import { ScaleUnits } from '../../../utils/units'

let validatorTimeoutId: any = null

const LOG_ENABLED = false

const USE_DROPDOWNS = false

const blankMeterInfo = createEmptyMeterInfo()

const updateCalculator = (calc: SAECalculator, gasTypes: (IDropdownOption|IComboBoxOption)[], meterInfo: ITblMeterInfo) => {
  calc.updateFromState({
    scale: (meterInfo.numScale || '') + '',
    scaleUnit: findScaleUnitByName(meterInfo.strScaleUnit?.trim() || ''),
    temperature: (meterInfo.numTemperature || '') + '',
    temperatureUnit: findTemperatureUnitByName(meterInfo.strTempUnit?.trim() || ''),
    pressure: (meterInfo.numPressure || '') + '',
    pressureUnit: findPressureUnitByName(meterInfo.strPressUnit?.trim() || ''),
    gasType: gasTypes.find((gasType: any) => gasType.text === meterInfo.strGas?.trim())
  })
}

export default function ShopCardPage () {
  const calc = useRef(new SAECalculator('ShopCardPage')).current
  const [didFetchInitialMeterSizes, setDidFetchInitialMeterSizes] = useState(false)

  const [lastFloMeterParams, setLastFloMeterParams] = useState<IFloMeterParameters>({
    liquid: false,
    calCurve: false,
    sav: false,
    valveTronicPlus: false,
    valveTronic: false,
    tronic: false,
    savPlus: false,
    manualValve: false,
    rcvPlus: false,
    floTronic: false
  })

  const [isFetchingAbasData, setIsFetchingAbasData] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [meterSizes, setMeterSizes] = useState<IMeterSizeDropdownOption[]>([])
  const [meterSize, setMeterSize] = useState<IDropdownOption|IComboBoxOption>()
  const [meterInfo, setMeterInfo] = useState<ITblMeterInfo>(blankMeterInfo)
  const [saeString, setSaeString] = useState('')
  const [prevSaeString, setPrevSaeString] = useState('')
  const [dashNo, setDashNo] = useState('')
  const [gasTypes, setGasTypes] = useState([])
  const [saeCalculatorState, setSaeCalculatorState] = useState<ISAeCalculatorState>(calc.getState())
  const [validationErrors, setValidationErrors] = useState<any>(null)

  const [tmpSalesOrder, setTmpSalesOrder] = useState('')

  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<ITag[]>([])

  const [temporarySpecificGravity, setTemporarySpecificGravity] = useState('')
  const [isEditingSpecificGravity, setIsEditingSpecificGravity] = useState(false)

  const newRecordButtonId = useId('newRecordButton')
  const labelId = useId('callout-label')
  const descriptionId = useId('callout-description')

  const [errorTooltipVisible, setErrorTooltipVisible] = useState(false)

  const [calCurveOptions, setCalCurveOptions] = useState([])

  const { inProgress } = useMsal()

  const params: { id: string } = useParams()

  const updateDashNo = (info: ITblMeterInfo|null = null) => {
    const i = info || meterInfo
    if (i.strMeterSize) {
      lookupDashNo(calc, i.strMeterSize).then(result => setDashNo(result || ''))
    }
  }

  const setNewMeterSize = (size: IDropdownOption|IComboBoxOption|undefined) => {
    setMeterSize(size)

    if (meterInfo) {
      persistMeterInfoAndShowMessage({ strMeterSize: size?.text || '' })
      updateDashNo()
    }
  }

  const loadMeterById = async (id: number) => {
    const info = await selectMeterInfo(undefined, id)
    setMeterInfo(info)

    if (LOG_ENABLED) {
      console.log('meterInfo', info)
    }

    updateCalculator(calc, gasTypes, info)

    const params = getFloMeterParamsFromMeterInfo(info)
    setLastFloMeterParams(params)

    if (calc.sae >= 0) {
      selectMeterSizes(params, calc).then(setMeterSizes)
    }

    if (info.numSAE) {
      setSaeString(calc.formatSAEAsString(info.numSAE))
    }

    updateDashNo(info)
    setTmpSalesOrder(info?.strShopOrder || '')
  }

  const onSerialNumberSelected = async (items: ITag[]|undefined) => {
    if (!items?.length) {
      setMeterInfo(blankMeterInfo)
      setSelectedSerialNumbers([])
    } else {
      setSelectedSerialNumbers([items[0]])
      await loadMeterById(+items[0].key)
    }
  }

  const setNewMeterInfo = useCallback((info: ITblMeterInfo) => {
    setMeterInfo(info)

    const params = getFloMeterParamsFromMeterInfo(info)

    const shouldReFetch = !meterSizes.length ||
      calc.saeAsString !== prevSaeString ||
      Object.keys(params).some(key => {
        const oldVal = get(lastFloMeterParams, key)
        const newVal = get(params, key)
        const differs = oldVal !== newVal
        if (differs && LOG_ENABLED) {
          console.log(key, oldVal, '!==', newVal)
        }
        return differs
      })

    if (LOG_ENABLED) {
      console.log('new meter info', info, { shouldReFetch })
    }

    if (calc.saeAsString !== prevSaeString) {
      setPrevSaeString(calc.saeAsString)
    }

    if (!shouldReFetch) {
      if (LOG_ENABLED) {
        console.log('no need to re-fetch flo-meter')
      }
      return
    }

    setLastFloMeterParams(params)

    // immediately fetch meter sizes for the above params
    if (calc.sae >= 0) {
      selectMeterSizes(params, calc).then(setMeterSizes)
    } else {
      selectMeterSizes(params).then(setMeterSizes)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFloMeterParams, meterSizes])

  useEffect(() => {
    if (meterInfo?.strMeterSize && meterSizes.length) {
      const matchingMeterSize = meterSizes.find(ms => ms.text === meterInfo?.strMeterSize)
      if (matchingMeterSize) {
        setMeterSize(matchingMeterSize)
      }
    }
  }, [meterInfo, meterSizes, setMeterSize])

  const toggleMeterInfoField = (ev?: any, checked?: boolean): void => {
    const key = ev?.target?.name
    if (key && meterInfo && has(meterInfo, key)) {
      if (LOG_ENABLED) {
        console.log(`meterInfo.${key}`, !!checked)
      }
      setNewMeterInfo({ ...meterInfo, [key]: !!checked })
      persistMeterInfoAndShowMessage({ [key]: !!checked })
    }
  }

  const setNewValueForMeterInfoField = (key: string, newValue: string|number|undefined|null) => {
    if (key && meterInfo) {
      if (LOG_ENABLED) {
        console.log(`updateMeterInfoField.${key}`, newValue)
      }
      setNewMeterInfo({ ...meterInfo, [key]: newValue })
      if (key !== 'strShopOrder') {
        persistMeterInfoAndShowMessage({ [key]: newValue })
      }
    }
  }

  const updateMeterInfoField = (ev: any, newValue?: string): void => {
    const key = ev?.target?.name
    const str = newValue || ''
    setNewValueForMeterInfoField(key, str)
  }

  const updateMeterInfoDate = (date: Date|null|undefined, prop: string) => {
    if (meterInfo && date) {
      persistMeterInfoAndShowMessage({ [prop]: date })
    }
  }

  // fetch init data
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      selectGasTypes().then(setGasTypes)

      // bind SAE form with this component
      calc.setUpdateCallback(() => {
        setSaeString(calc.saeAsString)
        setSaeCalculatorState(calc.getState())
        updateDashNo()
      })

      callAzureFunctionGet('SelectCalCurveOptions').then(setCalCurveOptions)
    }

    return () => {
      calc.setUpdateCallback(undefined) // unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress])

  useEffect(() => {
    // load meter by serial number if it's provided as param in url
    if (gasTypes.length && calCurveOptions.length && params?.id) {
      setSelectedSerialNumbers([{ key: params.id, name: params.id }])
      // loadMeterBySerialNumber(params.id).catch(err => console.error(err))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gasTypes, calCurveOptions])

  useEffect(() => {
    if (meterSizes.length === 0 && !didFetchInitialMeterSizes) {
      setDidFetchInitialMeterSizes(true)
      setNewMeterInfo(blankMeterInfo)
    }
  }, [meterSizes, didFetchInitialMeterSizes, setNewMeterInfo])

  // validate form fields on each change
  useEffect(() => {
    if (validatorTimeoutId) {
      clearTimeout(validatorTimeoutId)
    }

    validatorTimeoutId = setTimeout(() => {
      const errors = getValidationErrors(meterInfo, meterSize)
      setValidationErrors(errors)
    }, 250)
  }, [meterInfo, meterSize])

  const persistMeterInfoAndShowMessage = (delta: any) => {
    if (!meterInfo) {
      console.log('no meter to persist')
    } else if (!meterInfo.numAuto) {
      setMeterInfo({ ...meterInfo, ...delta })
    } else {
      if (calc.isSufficientlyFilled() && calc.sae) {
        delta.numSAE = calc.sae
      }

      if (LOG_ENABLED) {
        console.log('delta', delta)
      }

      persistMeterInfo(meterInfo, delta, () => {
        setSuccessMessage('Saved')
        setTimeout(() => setSuccessMessage(''), 1000)
      })
    }
  }

  const getFloMeterParamsFromMeterInfo = (info: ITblMeterInfo): IFloMeterParameters => {
    return {
      liquid: info.ynLiquid,
      calCurve: info.ynCalCurve,
      sav: info.ynSAV,
      valveTronicPlus: info.fValveTronicPlus,
      valveTronic: info.ynValveTronic,
      tronic: info.ynTronicRod,
      savPlus: info.fRCVPlus || info.fSAVPlus,
      manualValve: info.ynManualValve,
      rcvPlus: info.fRCVPlus || info.fSAVPlus,
      floTronic: info.fFloTronic
    }
  }

  const styles = mergeStyleSets({
    button: {
      width: 130,
    },
    callout: {
      width: 320,
      padding: '20px 24px',
    },
    title: {
      marginBottom: 12,
      fontWeight: FontWeights.semilight,
    }
  })

  const canEditSpecificGravity = () => calc.canEditSpecificGravity() || !meterInfo.numAuto

  const canEditSAE = () => {
    return saeCalculatorState.gasType?.key === 23 || // Methanol
      saeCalculatorState.gasType?.key === 34 // Water
  }

  const onCreateNewEntryClick = async (delta?: Object) => {
    const isInvalid = Object.keys(validationErrors).length > 0

    setErrorTooltipVisible(isInvalid)

    if (isInvalid) {
      return
    }

    const valuesToSave:any = {}

    forEach(meterInfo, (val, key) => {
      if (key !== 'numAuto' && !isUndefined(val)) {
        valuesToSave[key] = val
      }
    })

    if (delta) {
      forEach(delta, (val, key) => {
        if (key !== 'numAuto' && !isUndefined(val)) {
          valuesToSave[key] = val
        }
      })
    }

    if (meterSize) {
      valuesToSave.strMeterSize = meterSize.text
    }

    if (saeCalculatorState.sae > 0 || canEditSAE()) {
      if (canEditSAE()) {
        valuesToSave.numSAE = parseFloat(saeString)
      } else {
        valuesToSave.numSAE = saeCalculatorState.sae
      }
      valuesToSave.strGas = saeCalculatorState?.gasType?.text
      valuesToSave.numSpecificGravity = parseFloat(calc.specificGravityAsString)
      valuesToSave.numScale = parseFloat(saeCalculatorState.scale || '0')
      valuesToSave.strScaleUnit = saeCalculatorState.scaleUnit?.text
      valuesToSave.numPressure = parseFloat(saeCalculatorState.pressure || '0')
      valuesToSave.strPressUnit = saeCalculatorState.pressureUnit?.text
      valuesToSave.numTemperature = parseFloat(saeCalculatorState.temperature || '0')
      valuesToSave.strTempUnit = saeCalculatorState.temperatureUnit?.text
    }

    if (LOG_ENABLED) {
      console.log({ valuesToSave })
    }

    const res = await insertMeterInfo(valuesToSave)
    const serial = valuesToSave["strSerial#"]
    setMeterInfo({ meterInfo, ...res })

    const matching = await searchMeterInfoBySerialNumber(serial)
    setSelectedSerialNumbers([matching[0]])

    if (res) {
      await loadMeterById(res.numAuto)
    }
  }

  const onDeleteClick = () => {
    if (window.confirm('Are you sure you want to delete this card?')) {
      callAzureFunctionDelete('DeleteMeterInfo', { id: meterInfo.numAuto })
        .then(() => {
          setSelectedSerialNumbers([])
          setNewMeterInfo(blankMeterInfo)
        })
    }
  }

  const getCLE = () => {
    if (meterInfo.numScale) {
      // @ts-ignore
      const cle = calculateFlow(meterInfo.numScale, meterInfo.strScaleUnit as keyof ScaleUnits, ScaleUnits.GPH)
      return formatNum(cle, 2)
    }
    return ''
  }

  const canFetchAbasData = () => {
    return !!meterInfo["numCustId#"] || !!meterInfo.strBillName || tmpSalesOrder
  }

  const loadCustomerFromAbas = (criteria: TAbasCriteria) => {
    if (!criteria) {
      return
    } else if (!criteria.salesOrder && !criteria.clientId && !criteria.clientName) {
      return
    }

    setIsFetchingAbasData(true)

    getAbasCustomerData(criteria).then(data => {
      setIsFetchingAbasData(false)

      if (data && (data.customer || data.order)) {
        const newMeterInfo = { ...meterInfo }

        if (data.customer) {
          newMeterInfo["numCustId#"] = data.customer.nummer
          newMeterInfo.strBillName = data.customer.ans2 || data.customer.ans
          newMeterInfo.strBillAddress = data.customer.str2 || data.customer.str
          newMeterInfo.strBillAddress2 = data.customer.nort2 || data.customer.nort
          newMeterInfo.strBillAddress3 = data.customer["region^kenn"]
          newMeterInfo.strBillAddress4 = data.customer["staat^iso3"]
          newMeterInfo.strBillZip = data.customer.plz
        }

        if (data.order) {
          newMeterInfo.strShopOrder = tmpSalesOrder
          newMeterInfo.strShipName = data.order.ans2 || data.order.ans
          newMeterInfo.strShipAddress = data.order.str2 || data.order.str
          newMeterInfo.strShipAddress2 = data.order.nort2 || data.order.nort
          newMeterInfo.strShipAddress3 = data.order["region2^kenn"] || data.order["region^kenn"]
          newMeterInfo.strShipAddress4 = data.order["staat2^iso3"] || data.order["staat^iso3"]
          newMeterInfo.strShipZip = data.order.plz2 || data.order.plz
        }

        setMeterInfo(newMeterInfo)
      }
    })
  }

  const onAbasDataClicked = () => {
    if (isFetchingAbasData || !canFetchAbasData()) {
      return
    }

    if (meterInfo["numCustId#"]) {
      loadCustomerFromAbas({ clientId: meterInfo["numCustId#"] })
    } else if (meterInfo.strBillName) {
      loadCustomerFromAbas({ clientName: meterInfo.strBillName })
    } else if (tmpSalesOrder) {
      loadCustomerFromAbas({ salesOrder: trim(tmpSalesOrder) })
    }
  }

  return (
    <div>
      {successMessage && <div style={{position: 'absolute', right: 0, top: '50px', width: '200px'}}><MessageBar messageBarType={MessageBarType.success}>
        {successMessage}
      </MessageBar></div>}

      <h3>Shop Card Entry</h3>

      <Stack tokens={sectionStackTokens}>

        <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
          <TagPicker
            itemLimit={1}
            inputProps={{ placeholder: 'Find S/N' }}
            selectedItems={selectedSerialNumbers}
            onChange={items => onSerialNumberSelected(items)}
            onResolveSuggestions={searchMeterInfoBySerialNumber}
            resolveDelay={200}
          />

          <DefaultButton
            text="Delete"
            disabled={meterInfo.numAuto === 0}
            onClick={onDeleteClick}
          />

          {/*<DefaultButton text="Check Meters" disabled={true}/>*/}
          <AddNotes meterSizes={meterSizes} />

          <DefaultButton
            text="Save New Record"
            id={newRecordButtonId}
            onClick={() => onCreateNewEntryClick()}
            disabled={meterInfo.numAuto > 0}
          />

          {errorTooltipVisible && (
            <Callout
              className={styles.callout}
              ariaLabelledBy={labelId}
              ariaDescribedBy={descriptionId}
              role="alertdialog"
              gapSpace={0}
              target={`#${newRecordButtonId}`}
              onDismiss={() => setErrorTooltipVisible(false)}
              setInitialFocus
            >
              <Text block variant="xLarge" className={styles.title} id={labelId}>
                Please fix errors
              </Text>
              <Text block variant="small" id={descriptionId}>
                {Object.values(validationErrors).map((error: any) => (<p key={error}>{error}</p>))}
              </Text>
            </Callout>
          )}

          {/*<DefaultButton text="Duplicate Meter" disabled={true} />*/}

          {meterSize && <PrintShopCardDialog meterInfo={meterInfo} calCurveOptions={calCurveOptions} label="Print Shop Card" />}
        </Stack>

        <Separator styles={separatorStyles} />

        <Stack horizontal wrap tokens={headingStackTokens}>
          <Stack tokens={{ padding: '29px 0 0 0' }}>
            <DefaultButton
              text="Get Abas Data"
              disabled={!canFetchAbasData() || isFetchingAbasData}
              onClick={onAbasDataClicked}
            >
              {isFetchingAbasData && <Spinner size={SpinnerSize.medium} />}
            </DefaultButton>

            <TextField
              label="Serial #"
              name='strSerial#'
              value={meterInfo?.["strSerial#"] || ''}
              onChange={updateMeterInfoField}
            />

            <Label>Tab</Label>

            <GasTypeSelector
              gasTypes={gasTypes}
              calc={calc}
              onGasTypeSelected={gasType => {
                if (gasType) {
                  setNewValueForMeterInfoField('strGas', gasType.text)
                }
              }}
            />

            <TextField
              label="Specific Gravity"
              value={isEditingSpecificGravity ? temporarySpecificGravity : saeCalculatorState.gasSpecificGravityAsString}
              readOnly={!canEditSpecificGravity()}
              onFocus={() => {
                if (canEditSpecificGravity() && !isEditingSpecificGravity) {
                  setIsEditingSpecificGravity(true)
                  setTemporarySpecificGravity(saeCalculatorState.gasSpecificGravityAsString)
                }
              }}
              onChange={(event, newValue) => {
                if (isEditingSpecificGravity) {
                  setTemporarySpecificGravity(newValue || '')
                }
              }}
              onBlur={() => {
                if (isEditingSpecificGravity && canEditSpecificGravity()) {
                  setIsEditingSpecificGravity(false)
                  calc.setUserProvidedSpecificGravity(temporarySpecificGravity)
                }
              }}
            />
          </Stack>
          <Stack>
            <Stack styles={stackStyles}>
              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Scale"
                  value={saeCalculatorState.scale}
                  onChange={(event, newValue) => {
                    calc.setScale(newValue)
                    const newNumScale = newValue ? parseFloat(newValue) : undefined
                    setNewValueForMeterInfoField('numScale', newNumScale)
                  }}
                />

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  allowFreeform={false}
                  options={units.scale}
                  label="Unit"
                  styles={unitDropdownStyles}
                  selectedKey={saeCalculatorState.scaleUnit?.key}
                  onChange={(event, option) => {
                    calc.setScaleUnit(option)
                    setNewValueForMeterInfoField('strScaleUnit', option?.key)
                  }}
                />}

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.scale}
                  styles={unitDropdownStyles}
                  selectedKey={saeCalculatorState.scaleUnit?.key}
                  onChange={(event, option) => {
                    calc.setScaleUnit(option)
                    setNewValueForMeterInfoField('strScaleUnit', option?.key)
                  }}
                />}
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Dual Scale"
                  value={meterInfo.strDualScale}
                  onChange={(event, newValue) => {
                    setNewValueForMeterInfoField('strDualScale', newValue)
                  }}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.scale}
                  styles={unitDropdownStyles}
                  selectedKey={meterInfo.strDualScaleUnit}
                  onChange={(event, option) => {
                    setNewValueForMeterInfoField('strDualScaleUnit', option?.key || undefined)
                  }}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.scale}
                  styles={unitDropdownStyles}
                  selectedKey={meterInfo.strDualScaleUnit}
                  onChange={(event, option) => {
                    setNewValueForMeterInfoField('strDualScaleUnit', option?.key || undefined)
                  }}
                />}
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Pressure"
                  value={saeCalculatorState.pressure}
                  onChange={(event, newValue) => {
                    calc.setPressure(newValue)
                    const newPressure = isUndefined(newValue) ? undefined : parseFloat(newValue)
                    setNewValueForMeterInfoField('numPressure', newPressure)
                  }}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.pressure}
                  styles={unitDropdownStyles}
                  selectedKey={saeCalculatorState.pressureUnit?.key}
                  onChange={(event, option) => {
                    calc.setPressureUnit(option)
                    setNewValueForMeterInfoField('strPressUnit', option?.key || undefined)
                  }}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.pressure}
                  styles={unitDropdownStyles}
                  selectedKey={saeCalculatorState.pressureUnit?.key}
                  onChange={(event, option) => {
                    calc.setPressureUnit(option)
                    setNewValueForMeterInfoField('strPressUnit', option?.key || undefined)
                  }}
                />}
              </Stack>
              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Temperature"
                  value={saeCalculatorState.temperature}
                  onChange={(event, newValue) => {
                    calc.setTemperature(newValue)
                    const newTemperature = isUndefined(newValue) ? undefined : parseFloat(newValue)
                    setNewValueForMeterInfoField('numTemperature', newTemperature)
                  }}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.temperature}
                  styles={unitDropdownStyles}
                  selectedKey={saeCalculatorState.temperatureUnit?.key}
                  onChange={(event, option) => {
                    calc.setTemperatureUnit(option)
                    setNewValueForMeterInfoField('strTempUnit', option?.key || undefined)
                  }}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.temperature}
                  styles={unitDropdownStyles}
                  selectedKey={saeCalculatorState.temperatureUnit?.key}
                  onChange={(event, option) => {
                    calc.setTemperatureUnit(option)
                    setNewValueForMeterInfoField('strTempUnit', option?.key || undefined)
                  }}
                />}
              </Stack>
            </Stack>
          </Stack>
          <Stack className="group-box">
            {!meterInfo.ynLiquid && <TextField
              label="SAE"
              value={saeString || ''}
              readOnly={!canEditSAE()}
              onChange={(event, newValue) => {
                if (canEditSAE()) {
                  setSaeString(newValue || '')
                }
              }}
              onBlur={() => {
                const numSAE = parseFloat(saeString)
                if (canEditSAE() && numSAE > 0) {
                  setNewMeterInfo({ ...meterInfo, numSAE })
                  if (meterInfo.numAuto) {
                    persistMeterInfoAndShowMessage({ numSAE })
                  }
                }
              }}
            />}

            {meterInfo.ynLiquid && <TextField
              label="CLE"
              value={getCLE()}
              readOnly={true}
            />}

            <Dropdown
              placeholder="Select a meter size"
              label="Meter Size"
              options={meterSizes}
              selectedKey={meterSize?.key}
              onChange={(event, option) => setNewMeterSize(option)}
              styles={dropdownStyles}
            />

            {meterSize?.key && <DuplicateMeterSizeDialog meterSize={meterSize.data} onCreated={created => {
              const newDropdownOption = mapMeterSizeAsDropdownOption(created)

              const newMeterSizes = [newDropdownOption].concat(meterSizes).sort((ms1, ms2) => {
                return ms1.data.strMeterSize.localeCompare(ms2.data.strMeterSize)
              })

              setMeterSizes([...newMeterSizes])
            }}/>}

            {/*<Dropdown*/}
            {/*  placeholder="Select a meter series"*/}
            {/*  label="Meter Series"*/}
            {/*  options={[]}*/}
            {/*  styles={dropdownStyles}*/}
            {/*/>*/}
          </Stack>
        </Stack>

        <Separator styles={separatorStyles}/>

        <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
          <TextField
            label="Sales Order"
            name='strShopOrder'
            value={tmpSalesOrder}
            onChange={(e: any) => setTmpSalesOrder(e.target.value)}
            onBlur={() => {
              if (tmpSalesOrder !== meterInfo.strShopOrder && meterInfo.numAuto) {
                const newData = { ...meterInfo, numAuto: 0, strShopOrder: tmpSalesOrder }
                setMeterInfo(newData)
                return onCreateNewEntryClick(newData)
              }
            }}
          />
          <TextField
            label="Order By"
            name="strOrderBy"
            value={meterInfo?.strOrderBy || ''}
            onChange={updateMeterInfoField}
          />
          <TextField
            label="Purchase Order"
            name="strCO#"
            value={meterInfo?.["strCO#"] || ''}
            onChange={updateMeterInfoField}
          />
          <DatePicker
            label="Ship Date"
            firstDayOfWeek={DayOfWeek.Monday}
            value={meterInfo?.datShipDate}
            initialPickerDate={meterInfo?.datShipDate}
            onSelectDate={date => updateMeterInfoDate(date, 'datShipDate')}
          />
          <Checkbox
            label="Rush"
            name='ynRush'
            checked={meterInfo?.ynRush}
            onChange={toggleMeterInfoField}
          />
        </Stack>

        <Stack horizontal wrap tokens={headingStackTokens}>
          <Stack>
            <Stack styles={stackStyles}>
              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Customer No"
                  name="numCustId#"
                  value={meterInfo?.["numCustId#"] || ''}
                  onChange={updateMeterInfoField}
                />
                <DatePicker
                  label="Order Date"
                  firstDayOfWeek={DayOfWeek.Monday}
                  value={meterInfo?.strEntryDate}
                  initialPickerDate={meterInfo?.strEntryDate}
                  onSelectDate={date => updateMeterInfoDate(date, 'strEntryDate')}
                />
              </Stack>
              <Stack>
                <TextField
                  label="Sold To"
                  placeholder="Name"
                  name="strBillName"
                  disabled={isFetchingAbasData}
                  value={meterInfo?.strBillName || ''}
                  errorMessage={validationErrors?.strBillName}
                  onChange={updateMeterInfoField}
                />
                <TextField
                  styles={textFieldStyles}
                  placeholder="Address"
                  name="strBillAddress"
                  disabled={isFetchingAbasData}
                  value={meterInfo?.strBillAddress || ''}
                  errorMessage={validationErrors?.strBillAddress}
                  onChange={updateMeterInfoField}
                />
                <TextField
                  styles={textFieldStyles}
                  placeholder="Address"
                  name="strBillAddress2"
                  disabled={isFetchingAbasData}
                  value={meterInfo?.strBillAddress2 || ''}
                  onChange={updateMeterInfoField}
                />
                <TextField
                  styles={textFieldStyles}
                  placeholder="Address"
                  name="strBillAddress3"
                  disabled={isFetchingAbasData}
                  value={meterInfo?.strBillAddress3 || ''}
                  onChange={updateMeterInfoField}
                />
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <TextField
                  styles={textFieldStyles}
                  placeholder="Address"
                  name="strBillAddress4"
                  disabled={isFetchingAbasData}
                  value={meterInfo?.strBillAddress4 || ''}
                  onChange={updateMeterInfoField}
                />
                <TextField
                  styles={textFieldStyles}
                  name="strBillZip"
                  value={meterInfo?.strBillZip || ''}
                  disabled={isFetchingAbasData}
                  onChange={updateMeterInfoField}
                />
              </Stack>
              <Stack>
                <TextField
                  label="Ship To"
                  placeholder="Name"
                  name="strShipName"
                  value={meterInfo?.strShipName || ''}
                  errorMessage={validationErrors?.strShipName || ''}
                  disabled={isFetchingAbasData}
                  onChange={updateMeterInfoField}
                />

                <TextField
                  styles={textFieldStyles}
                  placeholder="Address"
                  name="strShipAddress"
                  value={meterInfo?.strShipAddress || ''}
                  errorMessage={validationErrors?.strShipAddress}
                  disabled={isFetchingAbasData}
                  onChange={updateMeterInfoField}
                />

                <TextField
                  styles={textFieldStyles}
                  placeholder="Address line 2"
                  name="strShipAddress2"
                  value={meterInfo?.strShipAddress2 || ''}
                  disabled={isFetchingAbasData}
                  onChange={updateMeterInfoField}
                />
                <TextField
                  styles={textFieldStyles}
                  placeholder="Address line 3"
                  name="strShipAddress3"
                  value={meterInfo?.strShipAddress3 || ''}
                  disabled={isFetchingAbasData}
                  onChange={updateMeterInfoField}
                />
              </Stack>

              <Stack horizontal horizontalAlign="space-between">
                <TextField
                  styles={textFieldStyles}
                  placeholder="Address line 4"
                  name="strShipAddress4"
                  value={meterInfo?.strShipAddress4 || ''}
                  disabled={isFetchingAbasData}
                  onChange={updateMeterInfoField}
                />

                <TextField
                  styles={textFieldStyles}
                  placeholder="zipcode"
                  name="strShipZip"
                  value={meterInfo?.strShipZip || ''}
                  disabled={isFetchingAbasData}
                  onChange={updateMeterInfoField}
                />
              </Stack>

              <Stack horizontal horizontalAlign="space-between">
                <TextField
                  label="Model"
                  placeholder="Model"
                  name="strModel#"
                  value={meterInfo?.["strModel#"] || ''}
                  onChange={updateMeterInfoField}
                />
                <TextField
                  label="C.B. S/N"
                  name="strCBSN"
                  value={meterInfo?.strCBSN || ''}
                  onChange={updateMeterInfoField}
                />
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <TextField
                  label="Sensor S/N"
                  name="strSensorSN"
                  value={meterInfo?.strSensorSN || ''}
                  onChange={updateMeterInfoField}
                />
                <TextField
                  label="Motor S/N"
                />
              </Stack>
              <TextField
                label="Notes"
                multiline
                rows={3}
                name="strNotes"
                value={meterInfo?.strNotes || ''}
                onChange={updateMeterInfoField}
              />
            </Stack>
          </Stack>
          <Stack wrap tokens={rowSpacingStackTokens}>
            <Stack wrap tokens={rowSpacingStackTokens} className="group-box">
              <Stack horizontal horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Label>Flo-Meter Options</Label>
                <Label disabled={!meterInfo["strSerial#"]}>Serial #</Label>
                <Label disabled={!dashNo}>Needle Size (Dash #)</Label>
              </Stack>

              <Stack horizontal horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='Liquid'
                  name='ynLiquid'
                  defaultChecked={meterInfo.ynLiquid}
                  onChange={toggleMeterInfoField}
                />
                <Label>{meterInfo["strSerial#"]}</Label>
                <Label>{dashNo}</Label>
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='Cap'
                  name='Cap'
                  checked={meterInfo?.Cap}
                  onChange={toggleMeterInfoField}
                />

                <Checkbox
                  label='Tronic Fit Assy'
                  name='ynTronicRod'
                  checked={meterInfo.ynTronicRod}
                  onChange={toggleMeterInfoField}
                />
              </Stack>
              <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='Manual Valve'
                  name='ynManualValve'
                  checked={meterInfo.ynManualValve}
                  onChange={(ev, checked) => {
                    if (checked) {
                      setMeterInfo({ ...meterInfo, fValveTronicPlus: false, fSAVPlus: false })
                    }
                    toggleMeterInfoField(ev, checked)
                  }}
                  disabled={meterInfo.fValveTronicPlus || meterInfo.fSAVPlus}
                />
                <TextField disabled={true}/>
                <TextField
                  disabled={meterInfo.fValveTronicPlus || meterInfo.fSAVPlus}
                  value={meterInfo["strMVNeedle#"]}
                  name="strMVNeedle#"
                  onChange={updateMeterInfoField}
                />
              </Stack>
              <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='Flo-Tronic Plus'
                  name='fFloTronic'
                  checked={meterInfo.fFloTronic}
                  onChange={(ev, checked) => {
                    if (checked) {
                      setMeterInfo({ ...meterInfo, fValveTronicPlus: false, fSAVPlus: false })
                    }
                    toggleMeterInfoField(ev, checked)
                  }}
                  disabled={meterInfo.fValveTronicPlus || meterInfo.fSAVPlus}
                />
                <TextField disabled={true}/>
                {Math.random() > 1 && <TextField
                  disabled={meterInfo.fValveTronicPlus || meterInfo.fSAVPlus}
                  value={meterInfo["strMVNeedle#"]}
                  name="strMVNeedle#"
                  onChange={updateMeterInfoField}
                />}
                <TextField disabled={true}/>
              </Stack>
              <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='Valve-Tronic Plus'
                  name='fValveTronicPlus'
                  checked={meterInfo.fValveTronicPlus}
                  onChange={(ev, checked) => {
                    if (checked) {
                      setMeterInfo({ ...meterInfo, ynManualValve: false, fFloTronic: false, fSAVPlus: false })
                    }
                    toggleMeterInfoField(ev, checked)
                  }}
                  disabled={meterInfo.ynManualValve || meterInfo.fFloTronic || meterInfo.fSAVPlus}
                />

                <TextField disabled={true}/>

                <TextField
                  disabled={meterInfo.ynManualValve || meterInfo.fFloTronic || meterInfo.fSAVPlus}
                  value={meterInfo["strVTNeedle#"] || dashNo}
                  name="strVTNeedle#"
                  onChange={updateMeterInfoField}
                />
              </Stack>
              <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='SAV/RCV Plus'
                  name='fSAVPlus'
                  checked={meterInfo.fSAVPlus}
                  onChange={(ev, checked) => {
                    if (checked) {
                      setMeterInfo({ ...meterInfo, ynManualValve: false, fFloTronic: false, fValveTronicPlus: false })
                    }
                    toggleMeterInfoField(ev, checked)
                  }}
                  disabled={meterInfo.ynManualValve || meterInfo.fFloTronic || meterInfo.fValveTronicPlus}
                />
                <TextField disabled={true}/>
                <TextField
                  disabled={meterInfo.ynManualValve || meterInfo.fFloTronic || meterInfo.fValveTronicPlus}
                  value={meterInfo["strVTNeedle#"] || dashNo}
                  name="strVTNeedle#"
                  onChange={updateMeterInfoField}
                />
              </Stack>
              <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='Flo-Alarm'
                  name="ynFlo-Alarm"
                  checked={meterInfo?.["ynFlo-Alarm"]}
                  onChange={toggleMeterInfoField}
                />
                <TextField/>
                <Checkbox
                  label='Single?'
                  name="fFloAlarmSingle"
                  checked={meterInfo?.fFloAlarmSingle}
                  onChange={toggleMeterInfoField}
                />
              </Stack>
              <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='Cal Curve'
                  name='ynCalCurve'
                  checked={meterInfo.ynCalCurve}
                  onChange={toggleMeterInfoField}
                />
                <Dropdown
                  placeholder="Select"
                  options={calCurveOptions}
                  selectedKey={meterInfo.CalCurveOptionID}
                  onChange={(event, option) => {
                    if (option && Number.isInteger(option.key)) {
                      setNewMeterInfo({ ...meterInfo, CalCurveOptionID: +option.key })
                      persistMeterInfoAndShowMessage({ CalCurveOptionID: +option.key })
                    }
                  }}
                  styles={dropdownStyles}
                />
              </Stack>

              <Checkbox
                label='Panel/Manifold'
                checked={meterInfo?.PanelManifold}
                name='PanelManifold'
                onChange={toggleMeterInfoField}
              />

              <Checkbox
                label='Purge Orifice'
                name='PurgeOrifice'
                checked={meterInfo?.PurgeOrifice}
                onChange={toggleMeterInfoField}
              />
            </Stack>
            <Checkbox
              label='Check this Box Only After Checking this Record!'
              name="ynChecked"
              checked={meterInfo.ynChecked}
              onChange={toggleMeterInfoField}
            />
          </Stack>
        </Stack>

      </Stack>

    </div>
  )
}
