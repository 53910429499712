import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { DefaultButton, Stack, TextField, Checkbox } from '@fluentui/react'
import { ComboBox, Dropdown, IDropdownOption, IComboBoxOption } from '@fluentui/react'
import { units } from '../../utils/constants'
import {
  columnSpacingStackTokens,
  rowSpacingStackTokens,
  seaTextFieldStyles,
  sectionStackTokens,
  stackStyles,
  unitDropdownStyles
} from '../../styles'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { selectGasTypes, selectMeterSizes } from '../../utils/azure_function_calls'
import { SAECalculator } from '../../calculations/calculate_sae'
import { IFloMeterParameters, IMeterSizeDropdownOption } from '../../interfaces'
import lookupDashNo from '../../utils/lookup_needle_size'
import { isUndefined } from 'lodash'

const USE_DROPDOWNS = false

const baseCopyToClipboardText = 'Copy to clipboard'

const defaultMeterParameters: IFloMeterParameters = {
  liquid: false,
  tronic: false,
  valveTronic: false,
  valveTronicPlus: false,
  rcvPlus: false,
  sav: false,
  savPlus: false,
  manualValve: false,
  floTronic: false,
  calCurve: false
}

export default function FloMeterPage () {
  const { inProgress } = useMsal()

  const calc = useRef(new SAECalculator('FloMeterPage')).current
  const [sae, setSae] = useState(0)
  const [lastUpdate, setLastUpdate] = useState(0)
  const [calculatorState, setCalculatorState] = useState(calc.getState())
  const [gasTypes, setGasTypes] = useState([])
  const [isFetchingMeterSizes, setIsFetchingMeterSizes] = useState(false)
  const [dualScale, setDualScale] = useState<number|undefined>(undefined)
  const [dualScaleUnit, setDualScaleUnit] = useState<string|undefined>('')

  const [meterSizes, setMeterSizes] = useState<IMeterSizeDropdownOption[]>([])
  const [meterParameters, setMeterParameters] = useState<IFloMeterParameters>(defaultMeterParameters)
  const [meter, setMeter] = useState<IDropdownOption|IComboBoxOption|undefined>(undefined)
  const [notes, setNotes] = useState('')
  const [dashNo, setDashNo] = useState('')

  const [copyToClipboardText, setCopyToClipboardText] = useState(baseCopyToClipboardText)

  useEffect(() => {
    console.log('calculatorState or meter changed')

    setDashNo('')

    if (!calc.isSufficientlyFilled()) {
      console.log('calculator needs more info')
    } else if (!meter?.data.strMeterSize) {
      console.log('no meter size selected', meter)
    } else {
      const parts = calc.getNotesComponents(dualScale, dualScaleUnit)
      setNotes(`${meter.data.strMeterSize} Flo-Meter calibrated for ` + parts.join(', '))

      lookupDashNo(calc, meter.data.strMeterSize)
        .then(result => {
          setDashNo(result || '')
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdate, meter])

  const reFetchMeterSizes = () => {
    console.log('reFetchMeterSizes: start')

    if (!calc.isSufficientlyFilled()) {
      console.log('reFetchMeterSizes: calculator not ready')
      return
    }

    setIsFetchingMeterSizes(true)

    selectMeterSizes(meterParameters, calc).then(foundSizes => {
      console.log('reFetchMeterSizes: found %d sizes', foundSizes.length)
      setMeterSizes(foundSizes)

      if (meter && !foundSizes.some(size => size.key === meter.key)) {
        // if a meter was selected before, and now it's not found in the list
        // then select first from the fetched list
        setMeter(foundSizes[0])
      }

      setIsFetchingMeterSizes(false)
    })
  }

  useEffect(() => {
    reFetchMeterSizes()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sae, meterParameters])

  // fetch gas (tab) types and meter sizes on page enter
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      selectGasTypes().then(setGasTypes)
      selectMeterSizes(defaultMeterParameters).then(sizes => setMeterSizes(sizes))

      calc.setUpdateCallback(() => {
        const state = calc.getState()
        console.log(`calculator did update [SAE = ${calc.saeAsString}]`)
        setCalculatorState(state)
        setSae(state.sae)
        setLastUpdate(Date.now())
        reFetchMeterSizes()
      })

      calc.resetState()
    }

    return () => {
      calc.setUpdateCallback(undefined)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress])

  const onMeterParameterCheckboxChanged = (ev?: any, checked?: boolean): void => {
    if (ev?.target?.name) {
      setMeterParameters({ ...meterParameters, [ev.target.name]: !!checked })
    }
  }

  const didCopyToClipboard = () => {
    setCopyToClipboardText('Copied!')
    setTimeout(() => setCopyToClipboardText(baseCopyToClipboardText), 2000)
  }

  return (
    <div>
      <h3>Meter Specifications</h3>

      <Stack tokens={sectionStackTokens}>

        <Stack horizontal wrap tokens={columnSpacingStackTokens}>

          <Stack>
            <Stack styles={stackStyles}>
              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Scale"
                  type="number"
                  onChange={(event, newValue) => calc.setScale(newValue)}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.scale}
                  onChange={(event, option) => calc.setScaleUnit(option)}
                  styles={unitDropdownStyles}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.scale}
                  onChange={(event, option) => calc.setScaleUnit(option)}
                  styles={unitDropdownStyles}
                />}
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Dual Scale"
                  type="number"
                  onChange={(event, newValue) => {
                    const dualScale = isUndefined(newValue) ? undefined : parseFloat(newValue)
                    setDualScale(dualScale)
                  }}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.scale}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => {
                    setDualScaleUnit(option?.text)
                  }}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.scale}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => {
                    setDualScaleUnit(option?.text)
                  }}
                />}
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Pressure"
                  type="number"
                  onChange={(event, newValue) => calc.setPressure(newValue)}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.pressure}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setPressureUnit(option)}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.pressure}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setPressureUnit(option)}
                />}
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Temperature"
                  type="number"
                  onChange={(event, newValue) => calc.setTemperature(newValue)}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.temperature}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setTemperatureUnit(option)}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.temperature}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setTemperatureUnit(option)}
                />}
              </Stack>

              <Dropdown
                placeholder="Select a tab"
                label="Tab"
                onChange={(event, option) => calc.setGasType(option)}
                options={gasTypes}
              />

              <Dropdown
                placeholder="Select a meter size"
                label="Meter size"
                disabled={isFetchingMeterSizes || meterSizes.length === 0}
                options={meterSizes}
                selectedKey={meter?.key}
                onChange={(event, option) => setMeter(option)}
              />

              <TextField label="Needle for SAV and VT : Dash #" value={dashNo} readOnly/>

              <TextField
                label="Specific Gravity"
                value={calculatorState.gasSpecificGravityAsString}
                readOnly={!calc.canEditSpecificGravity()}
                onChange={(event, newValue) => {
                  if (calc.canEditSpecificGravity()) {
                    calc.setUserProvidedSpecificGravity(newValue)
                  }
                }}
              />
            </Stack>
          </Stack>

          <Stack tokens={{ padding: '29px 0 0 0', childrenGap: 10 }}>
            <Checkbox label='Liquid' name="liquid" checked={meterParameters.liquid} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='Tronic' name="tronic" checked={meterParameters.tronic} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='Valve-Tronic' name="valveTronic" checked={meterParameters.valveTronic} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='Valve-Tronic Plus' name="valveTronicPlus" checked={meterParameters.valveTronicPlus} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='RCV Plus' name="rcvPlus" checked={meterParameters.rcvPlus} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='SAV' name="sav" checked={meterParameters.sav} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='SAV Plus' name="savPlus" checked={meterParameters.savPlus} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='Manual Valve' name="manualValve" checked={meterParameters.manualValve} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='Flo-Tronic' name="floTronic" checked={meterParameters.floTronic} onChange={onMeterParameterCheckboxChanged} />
            <Checkbox label='CalCurve' name="calCurve" checked={meterParameters.calCurve} onChange={onMeterParameterCheckboxChanged} />
          </Stack>

          <Stack tokens={{ padding: '29px 0 0 0', childrenGap: 10 }}>
            <div className="group-box">
              <TextField label="SAE" readOnly value={calculatorState.saeAsString} styles={seaTextFieldStyles}/>
            </div>
          </Stack>

        </Stack>

        <Stack horizontal tokens={columnSpacingStackTokens}>
          <Stack grow>
            <Stack horizontal horizontalAlign="end" wrap>
              <CopyToClipboard text={notes} onCopy={didCopyToClipboard}>
                <DefaultButton text={copyToClipboardText} allowDisabledFocus/>
              </CopyToClipboard>
            </Stack>
            <TextField label="Notes" multiline readOnly={true} value={notes} rows={3}/>
          </Stack>
        </Stack>

      </Stack>

    </div>
  )
}
