import HomePage from './containers/HomePage'
import FloMeterPage from './containers/processing/FloMeterPage'
import CalibrationPage from './containers/processing/CalibrationPage'
import ReamerPage from './containers/processing/ReamerPage'
import ShopCardPage from './containers/processing/ShopCardPage'
import AssignWeightsPage from './containers/maintenance/AssignWeightsPage'
import SaeCalculatorPage from './containers/maintenance/SaeCalculatorPage'
import NoAccessPage from './containers/accessInfo/NoAccessPage'
import ReadOnlyAccessPage from './containers/accessInfo/ReadOnlyAccessPage'
import MeterHistoryPage from './containers/maintenance/meterHistory'
import DynamicReportsPage from './containers/reports/DynamicReportsPage'
import MixedGasPage from './containers/processing/MixedGasPage'

const routes = [
  {
    path: '/',
    exact: true,
    component: HomePage
  },
  {
    path: '/processing/flo-meter',
    component: FloMeterPage
  },
  {
    path: '/processing/mixed-gas',
    component: MixedGasPage
  },
  {
    path: '/processing/calibration',
    component: CalibrationPage
  },
  {
    path: '/processing/reamer',
    component: ReamerPage
  },
  {
    path: '/processing/shopCard/:id?',
    component: ShopCardPage
  },
  {
    path: '/reports/dynamicReports',
    component: DynamicReportsPage
  },
  {
    path: '/maintenance/assignWeights',
    component: AssignWeightsPage
  },
  {
    path: '/maintenance/saeCalculator',
    component: SaeCalculatorPage
  },
  {
    path: '/maintenance/meterHistory',
    component: MeterHistoryPage
  },
  {
    path: '/noAccess',
    component: NoAccessPage
  },
  {
    path: '/readOnlyAccess',
    component: ReadOnlyAccessPage
  }
]

export default routes
