import { callAzureFunctionGet } from './callAzureFunction'
import { SAECalculator } from '../calculations/calculate_sae'

type TNeedle = {
  DashNo: string,
  EF1: number,
  EF2: number,
  MF1: number,
  MF2: number,
  MTypes?: string
}

function filterNeedles (needles: TNeedle[], sae: number, psig: number) {
  const efMultiplier = Math.sqrt(14.7 / (14.7 + psig)) * 0.9

  return needles.filter(needle => {
    const mf1 = (needle.MF1 * Math.pow(psig, needle.EF1) * efMultiplier)
    const mf2 = (needle.MF2 * Math.pow(psig, needle.EF2) * efMultiplier)
    return mf1 > sae && mf2 < sae
  })
}

export function getMatchingNeedles (calc: SAECalculator, needles: TNeedle[]) {
  return filterNeedles(needles, calc.sae, calc.getInletPressurePSIG())
}

export function lookupAllMatchingNeedles (calc: SAECalculator): Promise<TNeedle[]> {
  return callAzureFunctionGet('SearchNeedles')
    .then(needles => getMatchingNeedles(calc, needles))
}

export function lookupNeedle (calc: SAECalculator, meter: string): Promise<TNeedle|null> {
  console.log(`Fetching needles for meter ${meter}`)

  return callAzureFunctionGet('SearchNeedles', { meter })
    .then((needles: TNeedle[]) => {
      const matching = getMatchingNeedles(calc, needles)

      return matching.length === 1 ? matching[0] : null
    })
}

export default function lookupDashNo (calc: SAECalculator, meter: string): Promise<string|null> {
  return lookupNeedle(calc, meter).then(needle => needle ? needle.DashNo : null)
}

export function lookupDashNoByPressure (sae: number, psig: number, meter: string) {
  console.log(`Fetching needles for meter ${meter}`)

  return callAzureFunctionGet('SearchNeedles', { meter })
    .then((needles: TNeedle[]) => {
      const matching = filterNeedles(needles, sae, psig)

      return matching.length === 1 ? matching[0] : null
    })
}
