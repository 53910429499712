import { isNull, isUndefined, isNumber } from 'lodash'
import moment from 'moment'

export function formatNum (num: number|undefined, digits = 2): string {
  if (isUndefined(num) || isNull(num) || isNaN(num)) {
    return ''
  } else if (!isNumber(num)) {
    console.log('invalid value:', num)
    return ''
  } else {
    if (digits === 0) {
      return num.toFixed(digits + 2).slice(0, -3)
    } else {
      return num.toFixed(digits + 2).slice(0, -2)
    }
    // const multiplier = Math.pow(10, digits)
    // return (Math.trunc(num * multiplier) / multiplier) + ''
  }
}

export function formatInt (num: number|undefined): string {
  if (num === undefined || num === null) {
    return ''
  } else if (!isNumber(num)) {
    console.log('invalid value', num)
    return ''
  } else {
    return num + ''
  }
}

export function formatSG (sg: number|undefined): string {
  return formatNum(sg, 3)
}

export function formatSAE (sae: number|undefined): string {
  return formatNum(sae, 2)
}

export function formatDate (d: Date|string|undefined): string {
  return (d === undefined || !d ) ? '' : moment(d).format('MM/DD/YYYY')
}
