import { get, set, isFunction } from 'lodash'
import { IComboBoxOption, IDropdownOption } from '@fluentui/react'
import { ITblMeterInfo } from '../../../interfaces'
import { callAzureFunctionPut } from '../../../utils/callAzureFunction'

export function getValidationErrors (info: ITblMeterInfo, meterSize: IDropdownOption|IComboBoxOption|undefined) {
  const errors: any = {}

  if (!meterSize?.text) {
    errors.strMeterSize = 'No meter size selected'
  }

  if (!info["strSerial#"]) {
    errors["strSerial#"] = 'No serial number'
  }

  if (!info.strBillName) {
    errors.strBillName = 'No billing name'
  }

  if (!info.strBillAddress && !info.strBillAddress2 && info.strBillAddress3 && !info.strBillAddress4) {
    errors.strBillAddress = 'No billing address'
  }

  if (!info.strShipName) {
    errors.strShipName = 'No shipping name'
  }

  if (!info.strShipAddress && !info.strShipAddress2 && info.strShipAddress3 && !info.strShipAddress4) {
    errors.strShipAddress = 'No shipping address'
  }

  return errors
}

const pendingWrites: any = {}

export function persistMeterInfo (info: ITblMeterInfo, delta: any, callback: Function|undefined = undefined, saveAlways = false) {
  if (!info.numAuto) {
    return
  }

  const hasChanges = Object.keys(delta).some(key => {
    return get(info, key) !== delta[key]
  })

  if (!hasChanges && !saveAlways) {
    console.log('no changes detected')
    return
  }

  if (pendingWrites[info.numAuto]) {
    clearTimeout(pendingWrites[info.numAuto])
  }

  pendingWrites[info.numAuto] = setTimeout(() => {
    console.log('persist meter info', info)
    const where = `numAuto=${info.numAuto}`
    callAzureFunctionPut('UpdateMeterInfo', { delta, where }).then(res => {
      console.log('update result', res)

      // apply changes to the model
      Object.keys(delta).forEach(key => set(info, key, delta[key]))

      if (isFunction(callback)) {
        callback()
      }
    })
  }, 350)
}
