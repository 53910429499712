import * as React from 'react'
import {
  TextField,
  Dropdown,
  DefaultButton,
  Label,
  Stack
} from '@fluentui/react'
import {
  headingStackTokens,
  rowSpacingStackTokens,
  dropdownStyles,
  sectionStackTokens,
  stackStyles,
  columnSpacingStackTokens
} from '../../../styles'
import Table from './table'
import { PressureUnits, TemperatureUnits } from '../../../utils/units'

export default function ReamerPage () {
  return (
    <div>

      <h3>Reamer Check-Out Form</h3>

      <Stack tokens={rowSpacingStackTokens}>

        <Stack horizontal wrap tokens={headingStackTokens}>
          <Stack>
            <Stack styles={stackStyles} tokens={sectionStackTokens}>
              <Dropdown
                placeholder="Select a Check"
                label="Find Check"
                options={[]}
                styles={dropdownStyles}
              />
              <Dropdown
                placeholder="Select a meter size"
                label="Meter Size"
                options={[]}
                styles={dropdownStyles}
              />
              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField label="Test ID"/>
                <Label>90</Label>
              </Stack>
              <Stack className="group-box" horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <Label>SAE</Label>
                <Label>250.0</Label>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={{ padding: '29px 0 0 0', ...sectionStackTokens }}>
            <DefaultButton text="Print Reamer Check" allowDisabledFocus/>
            <Stack className="group-box" tokens={sectionStackTokens}>
              <Label>Calibration Conditions</Label>
              <TextField label="Test Barometer" suffix="psig"/>
              <TextField label="Test Temperature" suffix={TemperatureUnits.FAHRENHEIT}/>
              <TextField label="Test Pressure" suffix={PressureUnits.DEGREES_WCG}/>
              <Dropdown
                label="Test Meter"
                options={[]}
                styles={dropdownStyles}
              />
              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <Label>CAE</Label>
                <Label>382.50</Label>
              </Stack>
            </Stack>
          </Stack>
        </Stack>


        <Stack horizontal>
          <Stack className="group-box" tokens={sectionStackTokens}>
            <Table/>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ padding: '0 0 29px 0', ...columnSpacingStackTokens }}>
          <TextField label="Float"/>
          <TextField label="TFRA"/>
          <TextField label="Weights"/>
          <TextField label="#Weights"/>
          <TextField label="Calibrated By"/>
          <TextField label="Date"/>
        </Stack>

      </Stack>

    </div>
  )
}
