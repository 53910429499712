import './index.css'
import * as React from 'react'
import {
  DefaultButton,
  Modal,
  IconButton,
  IIconProps,
  getTheme,
  mergeStyleSets
} from '@fluentui/react'
import { useId, useBoolean } from '@uifabric/react-hooks'
import { ITblMeterInfo } from '../../../../interfaces'
import ReactToPrint from 'react-to-print'
import { persistMeterInfo } from '../meterInfoUtils'
import { formatDate, formatNum, formatSAE, formatSG } from '../../../../utils/formatters'
import isMeterCalibrated from '../../../../utils/is_meter_calibrated'
import { calculateFlow } from '../../../../calculations/calculate_flow'
import { ScaleUnits } from '../../../../utils/units'
import { useEffect, useState } from 'react'
import { lookupDashNoByPressure } from '../../../../utils/lookup_needle_size'
import { pressureToPSIG } from '../../../../calculations/calculate_psig'
import { units } from '../../../../utils/constants'

const Barcode = require('react-barcode')

const theme = getTheme()

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  header: [
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      justifyContent: 'flex-end'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  }
})

const iconButtonStyles = {
  root: { color: theme.palette.neutralPrimary },
  rootHovered: { color: theme.palette.neutralDark }
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }
const printerIcon: IIconProps = { iconName: 'Print' }

interface IPrintShopCardDialogProps {
  meterInfo: ITblMeterInfo | null,
  calCurveOptions: any[],
  disabled?: boolean,
  condensed?: boolean,
  label: string,
  isNegative?: boolean
}

export function PrintShopCardDialog (props: IPrintShopCardDialogProps) {
  const [dashNo, setDashNo] = useState('')
  const [hasDashNo, setHasDashNo] = useState(false)

  const pageWidth = 880
  const pageHeight = 600

  useEffect(() => {
    const pVal = props.meterInfo?.numPressure
    const pUnit = props.meterInfo?.strPressUnit
    const sae = props.meterInfo?.numSAE
    const size = props.meterInfo?.strMeterSize

    if (!hasDashNo && pVal && pUnit && size && sae) {
      const realPressureUnit = units.pressure.find(unit => unit.key === pUnit || unit.text === pUnit)
      if (realPressureUnit) {
        const psig = pressureToPSIG(pVal, realPressureUnit.key)
        if (psig) {
          lookupDashNoByPressure(sae, psig, size).then(res => {
            setHasDashNo(true)
            setDashNo(res ? res.DashNo : '')
          })
        }
      }
    }
  }, [hasDashNo, props.meterInfo])

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title')

  const barcode = (val: any, height: number = 30) => {
    if (!val) {
      return (<div/>)
    } else {
      const str = (val || '').toString().replace('°', '')
      return (<Barcode value={str} height={height} displayValue={false} />)
    }
  }

  const barcodeNum = (n: number|undefined) => barcode(formatNum(n, 2))

  let elementToPrint: any = null

  const getContentToPrint = () => {
    if (props.meterInfo) {
      persistMeterInfo(props.meterInfo, { ynPrinted: true })
    }
    return elementToPrint
  }

  const getCalCurveName = () => {
    let calCurve = null
    if (Number.isInteger(props.meterInfo?.CalCurveOptionID)) {
      calCurve = props.calCurveOptions.find(opt => opt.key === props.meterInfo?.CalCurveOptionID)
    }
    return calCurve ? calCurve.text : ''
  }

  const getCLE = () => {
    if (props.meterInfo?.numScale) {
      // @ts-ignore
      const cle = calculateFlow(props.meterInfo.numScale, props.meterInfo.strScaleUnit as keyof ScaleUnits, ScaleUnits.GPH)
      return formatNum(cle, 2)
    }
    return ''
  }

  const isPrintable = (printable?: boolean|undefined) => !props.isNegative || printable

  return (
    <div>
      {!!props.condensed && <IconButton
        className="w-default-button"
        styles={iconButtonStyles}
        iconProps={printerIcon}
        onClick={showModal}
        disabled={props.disabled}
      />}

      {!props.condensed && <DefaultButton
        className="w-default-button"
        text={props.label}
        onClick={showModal}
        disabled={props.disabled}
      />}

      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <ReactToPrint
            trigger={() => <IconButton styles={iconButtonStyles} iconProps={printerIcon} ariaLabel="Print" />}
            content={getContentToPrint}
          />
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close modal popup"
            onClick={hideModal}
          />
        </div>
        <div id="shop-card" className="shop-card" style={{ width: pageWidth, height: pageHeight }} ref={el => elementToPrint = el}>
          <div className={`${props.isNegative ? 'is-negative' : ''}`}>
            <div id="mainProperty">
              <div className="w-100 d-flex">
                <div className="row">
                  <div className="column w-25">
                    <span><strong className="value-label">Sales Order:</strong> &nbsp; {isPrintable() && props.meterInfo?.strShopOrder}</span>
                  </div>
                  <div className="column w-25">
                    <span><strong className="value-label">Order date:</strong> &nbsp; {isPrintable() && formatDate(props.meterInfo?.strEntryDate)}</span>
                  </div>
                  <div className="column w-25">
                    <span><strong className="value-label">Serial #:</strong> &nbsp; {isPrintable() && props.meterInfo?.["strSerial#"]}</span>
                  </div>
                  <div className="column w-25">
                    {isPrintable() && barcode(props.meterInfo?.["strSerial#"], 28)}
                  </div>
                </div>
              </div>

              <div className="v-spacer"/>

              <div className="row">
                <div className="column w-50">
                  <span><strong className="value-label">Sold to:</strong> &nbsp; {isPrintable() && props.meterInfo?.strBillName}</span>
                </div>
                <div className="column w-50">
                  <span><strong className="value-label">Ship to:</strong> &nbsp; {isPrintable() && props.meterInfo?.strShipName}</span>
                </div>
              </div>

              <div className="row">
                <div className="column w-25">
                  <span><strong className="value-label">Cust #:</strong> &nbsp; {isPrintable() && props.meterInfo?.["numCustId#"]}</span>
                </div>
                <div className="column w-25">
                  <span><strong className="value-label">PO #:</strong> &nbsp; {isPrintable() && props.meterInfo?.["strCO#"]}</span>
                </div>
                <div className="column w-50">
                  <span><strong className="value-label">Ship Date:</strong> &nbsp; {isPrintable() && formatDate(props.meterInfo?.datShipDate)}</span>
                </div>
              </div>
            </div>

            <div className="v-spacer"/>

            <div className="w-100 d-flex">
              <div id="meterSpecification">
                <p className="section-header"><u><b>Meter Specifications:</b></u></p>
                <div className="row">
                  <label><b>Meter Type:</b></label>
                  <div className="column w-20 input">
                    {isPrintable() && props.meterInfo?.strMeterSize}
                    {isPrintable() && (props.meterInfo?.["strSAVNeedle#"] || props.meterInfo?.["strMVNeedle#"])}
                  </div>
                </div>
                <div className="row">
                  <div className="column w-20">
                    <label><b>Pri. Scale:</b></label>
                  </div>
                  <div className="column w-20 input">
                    {isPrintable() && props.meterInfo?.numScale}
                  </div>
                  <div className="column w-20">
                    {isPrintable() && barcode(props.meterInfo?.numScale)}
                  </div>
                  <div className="column w-20 input">
                    {isPrintable() && props.meterInfo?.strScaleUnit}
                  </div>
                  <div className="column w-20">
                    {isPrintable() && barcode(props.meterInfo?.strScaleUnit)}
                  </div>
                </div>
                <div className="row">
                  <div className="column w-20">
                    <label><b>Sec. Scale:</b></label>
                  </div>
                  <div className="column w-20 input">{isPrintable() && props.meterInfo?.strDualScale}</div>
                  <div className="column w-20">{isPrintable() && props.meterInfo?.strDualScaleUnit}</div>
                  <div className="column w-20 input">{isPrintable() && ''}</div>
                  <div className="column w-20">{isPrintable() && ''}</div>
                </div>
                <div className="row">
                  <div className="column w-20">
                    <label><b>Tab:</b></label>
                  </div>
                  <div className="column w-40 pr-2">
                    <div className="input w-100">
                      {isPrintable() && props.meterInfo?.strGas}
                    </div>
                  </div>
                  <div className="column w-20 input">
                    {isPrintable() && formatSG(props.meterInfo?.numSpecificGravity)}
                  </div>
                  <div className="column w-20">
                    {isPrintable() && barcodeNum(props.meterInfo?.numSpecificGravity)}
                  </div>
                </div>
                <div className="row">
                  <div className="column w-20">
                    <label><b>Temp:</b></label>
                  </div>
                  <div className="column w-20 input">
                    {isPrintable() && formatNum(props.meterInfo?.numTemperature, 2)}
                  </div>
                  <div className="column w-20">
                    {isPrintable() && barcodeNum(props.meterInfo?.numTemperature)}
                  </div>
                  <div className="column w-20 input">
                    {isPrintable() && props.meterInfo?.strTempUnit}
                  </div>
                  <div className="column w-20">
                    {isPrintable() && barcode(props.meterInfo?.strTempUnit)}
                  </div>
                </div>
                <div className="row">
                  <div className="column w-20">
                    <label><b>Press:</b></label>
                  </div>
                  <div className="column w-20 input">
                    {isPrintable() && formatNum(props.meterInfo?.numPressure, 2)}
                  </div>
                  <div className="column w-20">
                    {isPrintable() && barcodeNum(props.meterInfo?.numPressure)}
                  </div>
                  <div className="column w-20 input">
                    {isPrintable() && props.meterInfo?.strPressUnit}
                  </div>
                  <div className="column w-20">
                    {isPrintable() && barcode(props.meterInfo?.strPressUnit)}
                  </div>
                </div>
              </div>

              <div id="calibrationInfo">
                <div>
                  <p className="section-header"><u><b>Calibration info:</b></u></p>
                  <div className="w-100 d-flex">
                    <div className="w-40">
                      {!props.meterInfo?.ynLiquid && <div className="row">
                        <label className="w-30"><b>SAE:</b></label>
                        <div className="column w-40 input">{isPrintable() && formatSAE(props.meterInfo?.numSAE)}</div>
                      </div>}

                      {props.meterInfo?.ynLiquid && <div className="row">
                        <label className="w-30"><b>CLE:</b></label>
                        <div className="column w-40 input">{isPrintable() && getCLE()}</div>
                      </div>}

                      <div className="row">
                        <label className="w-30"><b>TFRA:</b></label>
                        <div className="column w-40 input">{props.isNegative && formatNum(props.meterInfo?.numTFRA, 2)}</div>
                      </div>
                      <div className="row">
                        <label className="w-30"><b>Float:</b></label>
                        <div className="column w-40 input">{props.isNegative && formatNum(props.meterInfo?.numFloat, 4)}</div>
                      </div>
                    </div>
                    <div className="w-60">
                      {isPrintable() && <div className="grey-box">
                        <div className="row">
                          <label className="w-50 text-center"><u><b>Suggested</b></u></label>
                          <label className="w-50 text-center"><u><b>Assy.</b></u></label>
                        </div>
                        <div className="row">
                          <div className="column w-50">
                            <label><b>TFRA:</b></label>
                            <div className="column w-100 input">{formatNum(props.meterInfo?.numSugTFRA, 2)}</div>
                          </div>
                          <div className="column w-50">
                            <input />
                          </div>
                        </div>
                        <div className="row">
                          <div className="column w-50">
                            <label className="w-100 flex-end"><b>Weights:</b></label>
                          </div>
                          <div className="column w-50">
                            <input />
                          </div>
                        </div>
                        <div className="row">
                          <div className="column w-50">
                            <label><b>Float:</b></label>
                            <div className="column w-100 input">{formatNum(props.meterInfo?.numSugFloat, 4)}</div>
                          </div>
                          <div className="column w-50">
                            <input />
                          </div>
                        </div>
                      </div>}
                    </div>
                  </div>
                  <div className="row">
                    <label><b>Weights:</b></label>
                    <div className="column input w-30">
                      {props.isNegative && formatNum(props.meterInfo?.numWeight, 2)}
                      {props.isNegative && props.meterInfo && (
                        <span>&nbsp; [#{formatNum(props.meterInfo?.numCountWeight, 0)}]</span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="w-50 d-flex">
                      <label><b>Tronic rod:</b></label>
                      <div className="input w-100">{isPrintable() && (props.meterInfo?.ynTronicRod ? 'Yes' : 'No')}</div>
                    </div>
                    <div className="w-50 d-flex">
                      <label style={{ padding: '0 8px' }}>Length:</label>
                      <div className="input w-100">{isPrintable(true) && props.meterInfo?.numTrnLength}</div>
                    </div>
                  </div>
                  <div className="row">
                    <label><b>Float Stop Tube Height:</b></label>
                    <div className="input w-100">{isPrintable() && ''}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="v-spacer"/>

            <div id="floMeterOptions" className="w-100 d-flex">
              <div id="floMeterOptionsFirst">
                { isPrintable() && <div>
                  <div className="w-100 d-flex">
                    <div className="w-40 d-flex">
                      <p className="section-header"><u><b>Flo-Meter Options:</b></u></p>
                    </div>
                    <div className="w-60 flex-end align-end">
                      <button className="grey-btn"><b>Pressure Check</b></button>
                    </div>
                  </div>
                  <div className="w-100">
                    <input type="checkbox" checked={props.meterInfo?.ynTronicRod} readOnly/>
                    <label><b>Tronic Fit Assy</b></label><br/>

                    <div className="row">
                      <div className="column w-40">
                        <input type="checkbox" checked={props.meterInfo?.Cap} readOnly/>
                        <label><b>Cap</b></label><br/>
                      </div>
                      <div className="column w-60 flex-end">
                        <label><b>Needle Size (Dash #):</b></label><br/>
                      </div>
                    </div>

                    <div className="row">
                      <div className="column w-40">
                        <input type="checkbox" checked={props.meterInfo?.ynManualValve} readOnly />
                        <label><b>Manual valve</b></label><br/>
                      </div>
                      <div className="column input w-60">{dashNo}</div>
                    </div>

                    <div className="row">
                      <div className="column w-40">
                        <input type="checkbox" checked={props.meterInfo?.fFloTronic} readOnly />
                        <label><b>Flo Tronic Plus</b></label><br/>
                      </div>
                      <div className="column input w-30"/>
                      <div className="column w-30">
                        <input type="checkbox" checked={props.meterInfo?.PanelManifold} readOnly />
                        <label><b>Panel/Manifold</b></label><br/>
                      </div>
                    </div>

                    <div className="row">
                      <div className="column w-40">
                        <input type="checkbox" checked={props.meterInfo?.fValveTronicPlus} readOnly />
                        <label><b>Valve Tronic Plus</b></label><br/>
                      </div>
                      <div className="column input w-30"/>
                      <div className="column w-30">
                        <input type="checkbox" checked={props.meterInfo?.PurgeOrifice} readOnly />
                        <label><b>Purge Orifice</b></label><br/>
                      </div>
                    </div>

                    <div className="row">
                      <div className="column w-40">
                        <input type="checkbox" checked={props.meterInfo?.fSAVPlus} readOnly />
                        <label><b>SAV / RCV Plus</b></label><br/>
                      </div>
                      <div className="column input w-30 mr-1"/>
                      <div className="column input w-30"/>
                    </div>

                    <div className="row">
                      <div className="column w-40">
                        <input type="checkbox" checked={props.meterInfo?.['ynFlo-Alarm']} readOnly />
                        <label><b>Flo-Alarm</b></label><br/>
                      </div>
                      <div className="column input w-30"/>
                      <div className="column w-30">
                        <label><b>Dual Flow Alarm</b></label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="column w-40">
                        <input type="checkbox" checked={props.meterInfo?.ynCalCurve} readOnly />
                        <label><b>Cal Curve</b></label><br/>
                      </div>
                      <div className="column input w-30">{getCalCurveName()}</div>
                    </div>
                  </div>
                </div> }
              </div>

              <div id="floMeterOptionsSecond">
                <div style={{ padding: '0 0 8px 8px' }}>
                  <div className="row">
                    <div className="column w-40">
                      <label><b>Flo-Meter ass'y by:</b></label>
                    </div>
                    <div className="column input w-60">{isPrintable() && ''}</div>
                  </div>
                  <div className="row">
                    <div className="column w-40">
                      <label><b>Calibrated by:</b></label>
                    </div>
                    <div className="column input w-60">
                      {isMeterCalibrated(props.meterInfo) && <>
                        <small>{isPrintable(true) && props.meterInfo?.strCalibrator}</small>
                        &nbsp; &nbsp;
                        <small>{isPrintable(true) && formatDate(props.meterInfo?.strCalDate)}</small>
                      </>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="column w-40">
                      <label><b>Pressure checked by:</b></label>
                    </div>
                    <div className="column input w-60">{isPrintable() && ''}</div>
                  </div>
                  <div className="row">
                    <div className="column w-40">
                      <label><b>Final Insp. by:</b></label>
                    </div>
                    <div className="column input w-60">{isPrintable() && ''}</div>
                  </div>
                  <div className="row">
                    <div className="column w-40">
                      <label><b>Rush:</b></label>
                    </div>
                    <div className="column input w-60">
                      <input type="checkbox" checked={props.meterInfo?.ynRush} readOnly />
                    </div>
                  </div>
                </div>

                <div className="v-spacer"/>

                <div className="pa-2">
                  <div className="row">
                    <label><b>Notes:</b></label><br/>
                    { isPrintable() && props.meterInfo?.strNotes }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </Modal>
    </div>
  )
}
