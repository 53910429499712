import * as React from 'react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { Stack } from '@fluentui/react'
import { loginRequest } from '../authConfig'

export default function Redirect () {
  const authRequest = { ...loginRequest }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      // loadingComponent={Loading}
    />
  )
};

function ErrorComponent ({ error }: any) {
  return <Stack horizontalAlign="center"><p>An Error Occurred: {`${error}`}</p></Stack>
}
