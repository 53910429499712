import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  ActionButton,
  Image,
  IStackStyles,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaSize,
  Stack
} from '@fluentui/react'
import UPCMarathon from '../assets/images/UPCMarathon_Black_2Lignes.png'
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu'
import { msalInstance } from '../index'
import { useConst } from '@fluentui/react-hooks'
import { callAzureFunctionGet } from '../utils/callAzureFunction'
import { useHistory } from 'react-router'

const height = 50

const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '100%',
    height,
    borderBottom: '1px solid rgb(243, 242, 241)'
  }
}

const leftStackStyles: Partial<IStackStyles> = { root: { width: 300, height, paddingLeft: 34 } }
const leftSpacerStyles: Partial<IStackStyles> = { root: { width: '100%', height }}
const middleStackStyles: Partial<IStackStyles> = { root: { width: 200, height, textTransform: 'uppercase' }}
const rightSpacerStyles: Partial<IStackStyles> = { root: { width: 20, height }}
const rightStackStyles: Partial<IStackStyles> = { root: { height } }

export const AppBar: React.FunctionComponent = () => {
  const [persona, setPersona] = useState({ imageInitials: '' })
  const [readOnlyAccess, setReadOnlyAccess] = useState(false)
  const [noAccess, setNoAccess] = useState(false)
  const history = useHistory()

  const signOut = () => {
    if (msalInstance && window.confirm('Are you sure you want to sign out?')) {
      msalInstance.logoutRedirect({ account: msalInstance.getActiveAccount() }).then(() => {
        console.log('did logout')
      })
    }
  }

  const menuProps = useConst<IContextualMenuProps>({
    shouldFocusOnMount: true,
    shouldFocusOnContainer: true,
    items: [
      { key: 'version', text: 'Build 2023-05-24.1' },
      { key: 'sign-out', text: 'Sign out', onClick: signOut }
    ]
  })

  useEffect(() => {
    let didCheckPermissions = false

    const setupAvatar = () => {
      if (msalInstance && !persona.imageInitials) {
        const account = msalInstance.getActiveAccount()
        if (account) {
          const imageInitials = (account.name || '')
            .split(' ')
            .map(part => part.charAt(0))
            .join('')
            .toUpperCase()

          if (imageInitials && imageInitials !== persona.imageInitials) {
            setPersona({ imageInitials })
          }

          if (!didCheckPermissions) {
            didCheckPermissions = true

            callAzureFunctionGet('GetPermissions')
              .then(permissions => {
                if (permissions.length < 1) {
                  setNoAccess(true)
                } else if (!permissions.includes('write')) {
                  setReadOnlyAccess(true)
                }
              })
              .catch(() => {
                didCheckPermissions = false
              })
          }
        }
      }
    }

    const id = setInterval(setupAvatar, 250)

    setTimeout(setupAvatar, 0)

    return () => {
      clearInterval(id)
    }
  }, [setPersona, persona.imageInitials])

  return (
    <Stack styles={stackStyles} horizontal>
      <Stack styles={leftStackStyles} horizontalAlign={'start'} verticalAlign={'center'}>
        <Image src={UPCMarathon} height={32} alt="UPC Marathon" />
      </Stack>

      <Stack styles={leftSpacerStyles}>&nbsp;</Stack>

      <Stack styles={middleStackStyles} horizontalAlign={'end'} verticalAlign={'center'}>
        {noAccess && <MessageBar className="w-message-bar" onClick={() => history.push('/noAccess')} messageBarType={MessageBarType.warning}>
          <small className="d-flex">no access</small>
        </MessageBar>}

        {readOnlyAccess && <MessageBar className="w-message-bar" onClick={() => history.push('/readOnlyAccess')} messageBarType={MessageBarType.warning}>
          <small className="d-flex">read only access</small>
        </MessageBar>}
      </Stack>

      <Stack styles={rightSpacerStyles}>&nbsp;</Stack>

      <Stack styles={rightStackStyles} horizontalAlign={'end'} verticalAlign={'center'}>
        <ActionButton persistMenu menuProps={menuProps}>
          <Persona {...persona} size={PersonaSize.size32} />
        </ActionButton>
      </Stack>
    </Stack>
  )
}
