import * as React from 'react'
import { Nav, INavLink, INavStyles, INavLinkGroup } from '@fluentui/react'
import { useHistory } from 'react-router-dom'

const navStyles: Partial<INavStyles> = {
  root: {
    width: 250,
    height: 'calc(100vh - 50px)',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
  },
}

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Home',
        icon: 'Home',
        url: '/',
        key: 'home',
        isExpanded: true
      },
      {
        name: 'Processing',
        url: '/processing',
        expandAriaLabel: 'Expand Processing section',
        collapseAriaLabel: 'Collapse Processing section',
        links: [
          {
            name: 'Create shop card',
            url: '/processing/shopCard',
            key: 'shopCard'
          },
          {
            name: 'Calibration',
            url: '/processing/calibration',
            key: 'calibration'
          },
          {
            name: 'Reamer Check',
            url: '/processing/reamer',
            key: 'reamer'
          },
          {
            name: 'Flo-Meter Selection',
            url: '/processing/flo-meter',
            key: 'floMeter'
          },
          {
            name: 'Mixed Gas',
            url: '/processing/mixed-gas',
            key: 'mixedGas'
          }
        ],
        isExpanded: true,
      },
      {
        name: 'Reports',
        url: '/reports',
        expandAriaLabel: 'Expand Reports section',
        collapseAriaLabel: 'Collapse Reports section',
        links: [
          {
            name: 'Dynamic Reporting',
            url: '/reports/dynamicReports',
            key: 'dynamicReports'
          },
          {
            name: 'Meter Scheduling',
            url: '/reports/meterScheduling',
            disabled: true,
            key: 'meterScheduling'
          },
          {
            name: 'Entry Report',
            url: '/reports/entryReport',
            disabled: true,
            key: 'entryReport'
          },
          {
            name: 'Print Shop Cards',
            url: '/reports/printShopCards',
            disabled: true,
            key: 'printShopCards'
          },
          {
            name: 'Print Selection Charts',
            url: '/reports/printSelectionCharts',
            disabled: true,
            key: 'printSelectionCharts'
          }
        ],
        isExpanded: true,
      },
      {
        name: 'Maintenance',
        url: '/maintenance',
        expandAriaLabel: 'Expand Maintenance section',
        collapseAriaLabel: 'Collapse Maintenance section',
        links: [
          {
            name: 'Assign Weights',
            url: '/maintenance/assignWeights',
            key: 'assignWeights'
          },
          {
            name: 'SAE Calculator',
            url: '/maintenance/saeCalculator',
            key: 'saeCalculator'
          },
          {
            name: 'Meter History',
            url: '/maintenance/meterHistory',
            key: 'meterHistory'
          }
        ],
        isExpanded: true,
      },
    ],
  },
]

export const Navigation: React.FunctionComponent = () => {
  const history = useHistory()

  function _onLinkClick (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    if (ev && item) {
      ev.preventDefault()
      if (item.name !== 'Processing' && item.name !== 'Reports' && item.name !== 'Maintenance') {
        history.push(item.url)
      }
    }
  }

  return (
    <Nav
      onLinkClick={_onLinkClick}
      selectedKey="home"
      styles={navStyles}
      groups={navLinkGroups}
    />
  )
}
