import * as React from 'react'
import { IDropdownOption, IComboBoxOption, ITag, TagPicker, ComboBox, IComboBox } from '@fluentui/react'
import { FormEvent, useEffect, useState } from 'react'
import { SAECalculator } from '../calculations/calculate_sae'
import { comboBoxStyles } from '../styles'

const USE_COMBO = false

interface IGasTypeSelectorProps {
  gasTypes: (IDropdownOption|IComboBoxOption)[]
  calc: SAECalculator
  onGasTypeSelected?: (gasType: IDropdownOption|IComboBoxOption|undefined) => void
}

const GasTypeSelector: React.FC <IGasTypeSelectorProps> = (props: IGasTypeSelectorProps) => {
  const [selectedGasTypes, setSelectedGasTypes] = useState<ITag[]>([])

  useEffect(() => {
    const { gasType } = props.calc

    console.log('[GasTypeSelector] gas type changed', gasType)

    if (!gasType) {
      console.log('[GasTypeSelector] no gas selected in SAE calculator')
    } else if (!USE_COMBO) {
      const newSelectedGasTypes = [{ name: gasType.text, key: gasType.key }]
      if (newSelectedGasTypes.length > selectedGasTypes.length || gasType.key !== selectedGasTypes[0].key) {
        setSelectedGasTypes(newSelectedGasTypes)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.calc.gasType])

  const setGas = (gasType: string|number|undefined) => {
    if (gasType) {
      props.calc.setGasType(props.gasTypes.find(gt => gt.key === gasType))
    } else {
      props.calc.setGasType(undefined)
    }

    if (props.onGasTypeSelected) {
      props.onGasTypeSelected(props.calc.gasType)
    }
  }

  const onComboBoxChanged = (event: FormEvent<IComboBox>, option: IComboBoxOption|undefined) => {
    setGas(option ? option.key : undefined)
  }

  const onTagPickerChanged = (items: ITag[]|undefined) => {
    if (items && items.length) {
      setSelectedGasTypes(items)
      setGas(items[0].key)
    } else {
      setSelectedGasTypes([])
      setGas(undefined)
    }
  }

  const tagPickerSuggestionsFilter = (filter: string) => {
    filter = filter.toLowerCase()

    return props.gasTypes
      .filter(gt => gt.text.toLowerCase().includes(filter))
      .map((gt: IDropdownOption|IComboBoxOption): ITag => ({ key: gt.key, name: gt.text }))
  }

  if (USE_COMBO) {
    return <ComboBox
      openOnKeyboardFocus={true}
      options={props.gasTypes}
      allowFreeform={false}
      selectedKey={props.calc.gasType?.key}
      label="Select a tab"
      styles={comboBoxStyles}
      onChange={onComboBoxChanged}
    />
  } else {
    return <TagPicker
      itemLimit={1}
      inputProps={{ placeholder: 'Select a tab' }}
      selectedItems={selectedGasTypes}
      onResolveSuggestions={tagPickerSuggestionsFilter}
      onChange={onTagPickerChanged}
    />
  }
}

export default GasTypeSelector
