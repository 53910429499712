import * as React from 'react'
import { headingStackTokens, sectionStackTokens } from '../../styles'
import { Stack, Text } from '@fluentui/react'

export default function ReadOnlyAccessPage () {
  return <Stack tokens={sectionStackTokens}>
    <Stack verticalAlign="center" grow horizontalAlign="center" tokens={headingStackTokens}>
      <Text>You have read only access rights</Text>
      <Text>Please contact <a href="mailto:helpdesk@nitrex.com">helpdesk@nitrex.com</a> for escalating rights to read/write</Text>
    </Stack>
  </Stack>
}
