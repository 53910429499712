import * as React from 'react'
import {
  Checkbox,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode
} from '@fluentui/react'
import { ITblMeterInfo, TTblSearchResults } from '../../../interfaces'

export interface IMeterSearchResultsProps {
  results: TTblSearchResults,
  onSelect: Function
}

export function MeterSearchResults (props: IMeterSearchResultsProps) {
  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Serial #',
      minWidth: 50,
      maxWidth: 100,
      fieldName: 'strSerial#'
    },
    {
      key: 'column2',
      name: 'Scale',
      minWidth: 50,
      maxWidth: 50,
      fieldName: 'numScale'
    },
    {
      key: 'column3',
      name: 'Unit',
      minWidth: 50,
      maxWidth: 50,
      fieldName: 'strScaleUnit'
    },
    {
      key: 'column4',
      name: 'Pressure',
      minWidth: 50,
      maxWidth: 50,
      fieldName: 'numPressure'
    },
    {
      key: 'column5',
      name: 'Pressure unit',
      minWidth: 50,
      maxWidth: 50,
      fieldName: 'strPressUnit'
    },
    {
      key: 'column6',
      name: 'Tab',
      minWidth: 150,
      maxWidth: 200,
      fieldName: 'strGas'
    },
    {
      key: 'column7',
      name: 'Size',
      minWidth: 50,
      maxWidth: 100,
      fieldName: 'strMeterSize'
    },
    {
      key: 'column8',
      name: 'Sold To',
      minWidth: 150,
      maxWidth: 200,
      fieldName: 'strBillName'
    },
    {
      key: 'column9',
      name: 'Ship To',
      minWidth: 150,
      maxWidth: 200,
      fieldName: 'strShipName'
    },
    {
      key: 'column10',
      name: 'Liquid',
      minWidth: 35,
      maxWidth: 35,
      onRender: (meter: ITblMeterInfo) => <Checkbox checked={meter.ynLiquid} />
    },
    {
      key: 'column11',
      name: 'Cap',
      minWidth: 35,
      maxWidth: 35,
      onRender: (meter: ITblMeterInfo) => <Checkbox checked={meter.Cap} />
    },
    {
      key: 'column12',
      name: 'Manual Valve',
      minWidth: 35,
      maxWidth: 35,
      onRender: (meter: ITblMeterInfo) => <Checkbox checked={meter.ynManualValve} />
    },
    {
      key: 'column13',
      name: 'Flo-Tronic Plus',
      minWidth: 35,
      maxWidth: 35,
      onRender: (meter: ITblMeterInfo) => <Checkbox checked={meter.fFloTronic} />
    },
    {
      key: 'column14',
      name: 'Valve-Tronic Plus',
      minWidth: 35,
      maxWidth: 35,
      onRender: (meter: ITblMeterInfo) => <Checkbox checked={meter.fValveTronicPlus} />
    },
    {
      key: 'column15',
      name: 'Flo-Alarm',
      minWidth: 35,
      maxWidth: 35,
      onRender: (meter: ITblMeterInfo) => <Checkbox checked={meter['ynFlo-Alarm']} />
    },
    {
      key: 'column16',
      name: 'Purge Orifice',
      minWidth: 35,
      maxWidth: 35,
      onRender: (meter: ITblMeterInfo) => <Checkbox checked={meter.PurgeOrifice} />
    }
  ]

  return <DetailsList
    items={props.results}
    compact={false}
    columns={columns}
    selectionMode={SelectionMode.none}
    layoutMode={DetailsListLayoutMode.justified}
    onActiveItemChanged={item => props.onSelect(item)}
    isHeaderVisible={true}
  />
}
