import { PressureUnits, ScaleUnits, TemperatureUnits } from './units'
import { ITblMeterInfo } from '../interfaces'

interface IUnit {
  key: string
  text: string
}

export const units = {
  scale: [
    { key: ScaleUnits.CFM, text: 'CFM' },
    { key: ScaleUnits.CFH, text: 'CFH' },
    { key: ScaleUnits.CMH, text: 'CMH' },
    { key: ScaleUnits.GPH, text: 'GPH' },
    { key: ScaleUnits.GPM, text: 'GPM' },
    { key: ScaleUnits.L_H, text: 'l/h' },
    { key: ScaleUnits.L_MIN, text: 'l/min' },
    { key: ScaleUnits.M3_H, text: 'm\xB3/h' },
    { key: ScaleUnits.M3_MIN, text: 'm\xB3/min' },
    { key: ScaleUnits.PPH, text: 'PPH' },
    { key: ScaleUnits.CC_MIN, text: 'cc/min' }
  ],
  pressure: [
    { key: PressureUnits.DEGREES_HG, text: '"Hg' },
    { key: PressureUnits.DEGREES_WCG, text: '"WCG' },
    // { key: PressureUnits.DEGREES_HG, text: '°Hg' },
    // { key: PressureUnits.DEGREES_WCG, text: '°WCG' },
    { key: PressureUnits.ATMOS, text: 'Atmos' },
    { key: PressureUnits.BAR, text: 'bar' },
    { key: PressureUnits.G_PER_CM2, text: 'g/cm\xB2' },
    { key: PressureUnits.KG_PER_CM2, text: 'kg/cm\xB2' },
    { key: PressureUnits.KPA, text: 'kPa' },
    { key: PressureUnits.MB, text: 'mb' },
    { key: PressureUnits.MBAR, text: 'mbar' },
    { key: PressureUnits.MM_AG, text: 'mmAq' },
    { key: PressureUnits.MM_H2O, text: 'mmH20' },
    { key: PressureUnits.MM_HG, text: 'mmHg' },
    { key: PressureUnits.mmWCG, text: 'mmwcg' },
    { key: PressureUnits.OSIG, text: 'osig' },
    { key: PressureUnits.PSIA, text: 'psia' },
    { key: PressureUnits.PSIG, text: 'psig' },
    { key: PressureUnits.MPA, text: 'Mpa' }
  ],
  temperature: [
    { key: TemperatureUnits.CELSIUS, text: '°C' },
    { key: TemperatureUnits.FAHRENHEIT, text: '°F' }
  ]
}

const scaleLookup = [
  { unit: ScaleUnits.CFH, numFlowConvtoCFH: 1, barCodeValue: 0 },
  { unit: ScaleUnits.CFM, numFlowConvtoCFH: 60.0, barCodeValue: 4 },
  { unit: ScaleUnits.CMH, numFlowConvtoCFH: 35.31501, barCodeValue: 1 },
  { unit: ScaleUnits.GPH, numFlowConvtoCFH: 0.1336806, barCodeValue: 3 },
  { unit: ScaleUnits.GPM, numFlowConvtoCFH: 8.0208, barCodeValue: 7 },
  { unit: ScaleUnits.L_H, numFlowConvtoCFH: 0.03531, barCodeValue: 2 },
  { unit: ScaleUnits.L_MIN, numFlowConvtoCFH: 2.1186, barCodeValue: 6 },
  { unit: ScaleUnits.M3_H, numFlowConvtoCFH: 35.315, barCodeValue: 1 },
  { unit: ScaleUnits.M3_MIN, numFlowConvtoCFH: 2118.88, barCodeValue: 5 },
  { unit: ScaleUnits.PPH, numFlowConvtoCFH: 21.74, barCodeValue: 8 },
  { unit: ScaleUnits.CC_MIN, numFlowConvtoCFH: 471.947, barCodeValue: 9 }
]

const findUnit = (haystack: IUnit[], name: string) => {
  if (/^\d+$/.test(name)) {
    return haystack[parseInt(name)]
  } else {
    return haystack.find(unit => unit.text === name)
  }
}

export const findScaleUnitByName = (name: string) => findUnit(units.scale, name) || units.scale[0]

export const findPressureUnitByName = (name :string) => findUnit(units.pressure, name) || units.pressure[0]

export const findTemperatureUnitByName = (name: string) => findUnit(units.temperature, name) || units.temperature[0]

export function unifyTemperatureUnit (info: ITblMeterInfo) {
  if (info.strTempUnit === '0') {
    info.strTempUnit = TemperatureUnits.CELSIUS
  } else if (info.strTempUnit === '1') {
    info.strTempUnit = TemperatureUnits.FAHRENHEIT
  }
}

export function unifyScaleUnits (info: ITblMeterInfo) {
  const primaryScaleNum = parseFloat(info.strScaleUnit || '-1')

  if (!isNaN(primaryScaleNum)) {
    const matchingPrimaryScale = scaleLookup.find(entry => entry.numFlowConvtoCFH === primaryScaleNum)
    if (matchingPrimaryScale) {
      console.log('replace primary scale to', matchingPrimaryScale.unit)
      info.strScaleUnit = matchingPrimaryScale.unit
    }
  }

  const dualScaleNum = parseFloat(info.strDualScaleUnit || '-1')
  if (!isNaN(dualScaleNum)) {
    const matchingDualScale = scaleLookup.find(entry => entry.numFlowConvtoCFH === dualScaleNum)
    if (matchingDualScale) {
      console.log('replace dual scale to', matchingDualScale.unit)
      info.strDualScaleUnit = matchingDualScale.unit
    }
  }
}

export const meterInfoLabels: { [key in keyof ITblMeterInfo]?: string } = {
  'strSerial#': 'serial #',
  'numScale': 'scale',
  'strScaleUnit': 'scale unit',
  'numTemperature': 'temperature',
  'strTempUnit': 'temperature unit',
  'numPressure': 'pressure',
  'strPressUnit': 'pressure unit',
  'strGas': 'gas',
  'strShopOrder': 'sales order',
  'strOrderBy': 'order by',
  'strCO#': 'purchase order',
  'ynRush': 'rush',
  'numCustId#': 'customer no',
  'strEntryDate': 'order date',
  'strBillName': 'sold to',
  'strBillAddress': 'address',
  'strBillAddress2': 'address',
  'strBillAddress3': 'address',
  'strBillAddress4': 'address',
  'strBillZip': 'zip',
  'strShipName': 'ship to',
  'strShipAddress': 'address',
  'strShipAddress2': 'address',
  'strShipAddress3': 'address',
  'strShipAddress4': 'address',
  'strShipZip': 'zip',
  'strModel#': 'model',
  'strCBSN': 'C.B. S/N',
  'strSensorSN': 'sensor S/N',
  'strNotes': 'notes',
  'ynLiquid': 'liquid',
  'Cap': 'cap',
  'ynTronicRod': 'tronic fit assy',
  'ynManualValve': 'manual valve',
  'fFloTronic': 'flo-tronic plus',
  'fValveTronicPlus': 'valve-tronic plus',
  'fSAVPlus': 'SAV/RCV plus',
  'ynFlo-Alarm': 'flo-alarm',
  'fFloAlarmSingle': 'single?',
  'ynCalCurve': 'cal curve',
  'CalCurveOptionID': 'cal curve option',
  'PanelManifold': 'panel/manifold',
  'PurgeOrifice': 'purge orifice',
  'ynChecked': 'Check this Box Only After Checking this Record!'
}
