import * as React from 'react'
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from '@fluentui/react'

export interface IDetailsListDocumentsExampleState {
  columns: IColumn[];
  items: IDocument[];
  isCompactMode: boolean;
}

export interface IDocument {
  key: string;
  percentage: number | undefined;
  requiredFlow: number | undefined;
  requiredUnit: number | undefined;
  actualUnit: number | undefined;
  actualFlow: number | undefined;
  readError: number | undefined;
}

export default class Table extends React.Component<{}, IDetailsListDocumentsExampleState> {
  private _allItems: IDocument[]

  constructor (props: {}) {
    super(props)

    this._allItems = [
      { key: '1', percentage: 25, requiredFlow: 250, requiredUnit: undefined, actualUnit: 300.0, actualFlow: 3000.0, readError: 771.46 },
      { key: '2', percentage: 30, requiredFlow: 300, requiredUnit: undefined, actualUnit: undefined, actualFlow: undefined, readError: undefined },
      { key: '3', percentage: 40, requiredFlow: 400, requiredUnit: undefined, actualUnit: undefined, actualFlow: undefined, readError: undefined },
      { key: '4', percentage: 50, requiredFlow: 500, requiredUnit: undefined, actualUnit: undefined, actualFlow: undefined, readError: undefined },
      { key: '5', percentage: 60, requiredFlow: 600, requiredUnit: undefined, actualUnit: undefined, actualFlow: undefined, readError: undefined },
      { key: '6', percentage: 70, requiredFlow: 700, requiredUnit: undefined, actualUnit: undefined, actualFlow: undefined, readError: undefined },
      { key: '7', percentage: 80, requiredFlow: 800, requiredUnit: undefined, actualUnit: undefined, actualFlow: undefined, readError: undefined },
      { key: '8', percentage: 90, requiredFlow: 900, requiredUnit: undefined, actualUnit: undefined, actualFlow: undefined, readError: undefined }
    ]

    const columns: IColumn[] = [
      {
        key: 'column0',
        name: '',
        fieldName: 'percentage',
        minWidth: 70,
        maxWidth: 90,
        isRowHeader: false,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return <span>{item.percentage + '%'}</span>
        },
        isPadded: true,
      },
      {
        key: 'column1',
        name: 'Required Flow (scfh)',
        fieldName: 'requiredFlow',
        minWidth: 70,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return <span>{item.requiredFlow}</span>
        },
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Required Units',
        fieldName: 'requiredUnit',
        minWidth: 70,
        maxWidth: 90,
        isSortedDescending: false,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return <span>{item.requiredUnit}</span>
        },
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Actual Units',
        fieldName: 'actualUnit',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        data: 'number',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <span>{item.actualUnit}</span>
        },
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Actual Flow (scfh)',
        fieldName: 'actualFlow',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        data: 'number',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <span>{item.actualFlow}</span>
        },
      },
      {
        key: 'column5',
        name: 'Error of Reading (%)',
        fieldName: 'readError',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        data: 'number',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <span>{ item.readError && item.readError + '%'}</span>
        },
      }
    ]

    this.state = {
      items: this._allItems,
      columns: columns,
      isCompactMode: true
    }
  }

  public render () {
    const { columns, isCompactMode, items } = this.state

    return (
      <div>
        <DetailsList
          items={items}
          compact={isCompactMode}
          columns={columns}
          selectionMode={SelectionMode.none}
          getKey={this._getKey}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onItemInvoked={this._onItemInvoked}
        />
      </div>
    )
  }

  public componentDidUpdate (previousProps: any, previousState: IDetailsListDocumentsExampleState) {}

  private _getKey (item: any, index?: number): string {
    return item.key
  }

  private _onItemInvoked (item: any): void {
    alert(`Item invoked: ${item.name}`)
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state
    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending)
    this.setState({
      columns: newColumns,
      items: newItems,
    })
  }
}

function _copyAndSort<T> (items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
}
