import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  DefaultButton,
  Stack,
  Modal,
  IconButton,
  IIconProps,
  TextField,
  getTheme,
  mergeStyleSets, Checkbox
} from '@fluentui/react'
import { rowSpacingStackTokens, sectionStackTokens } from '../../../../styles'
import { useId, useBoolean } from '@uifabric/react-hooks'
import { IMeterSize } from '../../../../interfaces'
import NumericTextField from '../../../../components/NumericTextField'
import { createNewMeterSize } from '../../../../utils/azure_function_calls'

const theme = getTheme()

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
})
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }

interface IDuplicateMeterSizeDialogProps {
  meterSize: IMeterSize
  onCreated: (created: IMeterSize) => void
}

interface ICreateNewMeterSize {
  fFT: boolean
  fRCVPlus: boolean
  fSAVPlus: boolean
  fVT: boolean
  numBaseCalTime: number
  numHighSAE: number
  numLowSAE: number
  strMeterNum: number
  strMeterSize: string
  ynHighPress: boolean
  ynLiquid: boolean
  ynManualValve: boolean
  ynOver10psig: boolean
  ynSAV: boolean
  ynTronicRod: boolean
  ynValveTronic: boolean
}

const DuplicateMeterSizeDialog = (props: IDuplicateMeterSizeDialogProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title')

  const setInitialValues: () => ICreateNewMeterSize = () => ({
    fFT: props.meterSize.fFT,
    fRCVPlus: props.meterSize.fRCVPlus,
    fSAVPlus: props.meterSize.fSAVPlus,
    fVT: props.meterSize.fVT,
    numBaseCalTime: props.meterSize.numBaseCalTime,
    numHighSAE: props.meterSize.numHighSAE,
    numLowSAE: props.meterSize.numLowSAE,
    strMeterNum: props.meterSize.strMeterNum,
    strMeterSize: `Copy of ${props.meterSize.strMeterSize}`,
    ynHighPress: props.meterSize.ynHighPress,
    ynLiquid: props.meterSize.ynLiquid,
    ynManualValve: props.meterSize.ynManualValve,
    ynOver10psig: props.meterSize.ynOver10psig,
    ynSAV: props.meterSize.ynSAV,
    ynTronicRod: props.meterSize.ynTronicRod,
    ynValveTronic: props.meterSize.ynValveTronic
  })

  const [meterSize, setMeterSize] = useState<ICreateNewMeterSize>(setInitialValues())

  useEffect(() => {
    setMeterSize(setInitialValues())
    // eslint-disable-next-line
  }, [props])

  const handleInputChange = (ev: any, value: any) => {
    const { name } = ev.currentTarget
    setMeterSize({ ...meterSize, [name]: value })
  }

  const handleNumberChange = (name: string, value: any) => {
    setMeterSize({ ...meterSize, [name]: value })
  }

  const onCreateNewMeterSize = () => {
    console.log('[create new meter size props]: ', meterSize)

    createNewMeterSize(meterSize as IMeterSize).then(createdMeterSize => {
      console.log('[create new meter size response]: ', createdMeterSize)
      props.onCreated(createdMeterSize)
      hideModal()
    })
  }

  return (
    <div>
      <DefaultButton text="Duplicate Meter Size" className="mt-1 w-100" onClick={showModal} />
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
          <div className={contentStyles.header}>
            <span id={titleId}>Duplicate Meter Size</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close modal popup"
              onClick={hideModal}
            />
          </div>

          <div className={contentStyles.body}>

            <Stack tokens={sectionStackTokens}>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField label="Meter Size" name="strMeterSize" value={meterSize.strMeterSize} onChange={handleInputChange}/>
                <NumericTextField
                  label="Meter Num"
                  name="strMeterNum"
                  numericValue={meterSize.strMeterNum}
                  precision={0}
                  updateFunction={(strMeterNum: number|undefined) => handleNumberChange('strMeterNum', strMeterNum)}
                />
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <Checkbox label='FT' name='fFT' checked={meterSize.fFT} onChange={handleInputChange} />
                <Checkbox label='RCV Plus' name='fRCVPlus' checked={meterSize.fRCVPlus} onChange={handleInputChange} />
                <Checkbox label='SAV Plus' name='fSAVPlus' checked={meterSize.fSAVPlus} onChange={handleInputChange} />
                <Checkbox label='VT' name='fVT' checked={meterSize.fVT} onChange={handleInputChange} />
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <NumericTextField
                  label="Base Cal Time"
                  name="numBaseCalTime"
                  numericValue={meterSize.numBaseCalTime}
                  precision={0}
                  updateFunction={(numBaseCalTime: number|undefined) => handleNumberChange('numBaseCalTime', numBaseCalTime)}
                />
                <NumericTextField
                  label="High SAE"
                  name="numHighSAE"
                  numericValue={meterSize.numHighSAE}
                  precision={4}
                  updateFunction={(numHighSAE: number|undefined) => handleNumberChange('numHighSAE', numHighSAE)}
                />
                <NumericTextField
                  label="Low SAE"
                  name="numLowSAE"
                  numericValue={meterSize.numLowSAE}
                  precision={4}
                  updateFunction={(numLowSAE: number|undefined) => handleNumberChange('numLowSAE', numLowSAE)}
                />
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <Checkbox
                  label='High Press'
                  name='ynHighPress'
                  checked={meterSize.ynHighPress}
                  onChange={handleInputChange}
                />
                <Checkbox
                  label='Liquid'
                  name='ynLiquid'
                  checked={meterSize.ynLiquid}
                  onChange={handleInputChange}
                />
                <Checkbox
                  label='Manual Valve'
                  name='ynManualValve'
                  checked={meterSize.ynManualValve}
                  onChange={handleInputChange}
                />
                <Checkbox
                  label='Over 10 psig'
                  name='ynOver10psig'
                  checked={meterSize.ynOver10psig}
                  onChange={handleInputChange}
                />
                <Checkbox
                  label='SAV'
                  name='ynSAV'
                  checked={meterSize.ynSAV}
                  onChange={handleInputChange}
                />
                <Checkbox
                  label='Tronic Rod'
                  name='ynTronicRod'
                  checked={meterSize.ynTronicRod}
                  onChange={handleInputChange}
                />
                <Checkbox
                  label='Valve Tronic'
                  name='ynValveTronic'
                  checked={meterSize.ynValveTronic}
                  onChange={handleInputChange}
                />
              </Stack>

              <DefaultButton text="Add meter size" onClick={onCreateNewMeterSize} />

            </Stack>
          </div>
      </Modal>
    </div>
  )
}

export default DuplicateMeterSizeDialog
