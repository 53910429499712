import { LogLevel } from '@azure/msal-browser'
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito
const isLocalhost = (window.location.hostname === 'localhost')
const redirectUri = isLocalhost ? 'http://localhost:3000/' : 'https://probe-cloud.upc-marathon.com/'

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: 'ddee832b-421a-4659-acd2-b3552a01f086',
    authority: 'https://login.microsoftonline.com/e14c4d0c-ad25-410f-8f75-0450ad84932b',
    redirectUri
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox || Math.random() > -1
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // console.info(message)
            return
          case LogLevel.Verbose:
            // console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      }
    }
  }
}

const scopes = ['openid', 'https://waukee-api.upc-marathon.com/user_impersonation']
export const loginRequest = { scopes: scopes.concat('User.Read'), prompt: 'login' }
export const apiRequest = { scopes }
export const graphConfig = { functionBaseUrl: 'https://waukee-api.upc-marathon.com/api/' }
