import {
  IconButton,
  IIconProps,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react'
import { sectionStackTokens } from '../../styles'
import { useEffect, useState } from 'react'
import { selectGasTypes } from '../../utils/azure_function_calls'
import NumericTextField from '../../components/NumericTextField'
import { IGasType, IGasTypeDropdownOption } from '../../interfaces'
import { callAzureFunctionDelete, callAzureFunctionPost, callAzureFunctionPut } from '../../utils/callAzureFunction'

const deleteIcon: IIconProps = { iconName: 'Delete' }
// const saveIcon: IIconProps = { iconName: 'Save' }

export default function MixedGasPage () {
  const [successMessage, setSuccessMessage] = useState('')
  const [tabs, setTabs] = useState<IGasTypeDropdownOption[]>([])

  const addNewGas = async () => {
    const data = { numSG: 0, strGasName: 'New Gas ' + Date.now() }
    const { numAuto } = await callAzureFunctionPost('CreateMixedGas', data)

    const newGas: IGasTypeDropdownOption = {
      key: numAuto,
      text: data.strGasName,
      data: { ...data, autonum: numAuto }
    }

    setTabs(tabs.concat([newGas]).sort((t1, t2) => t1.text.localeCompare(t2.text)))
  }

  const isEditable = (option: IGasTypeDropdownOption) => option.key > 34

  const deleteGas = async (option: IGasTypeDropdownOption) => {
    if (isEditable(option)) {
      const id = option.data.autonum
      await callAzureFunctionDelete('DeleteMixedGas', { id })
      setTabs(tabs.filter(tab => tab.data.autonum !== id))
    }
  }

  const persistOption = (option: IGasTypeDropdownOption, delta: Partial<IGasType>) => {
    const where = `autonum=${option.key}`
    callAzureFunctionPut('UpdateMixedGas', { delta, where })
      .then(res => {
        console.log('res', res)
        setSuccessMessage('Saved')
        setTimeout(() => setSuccessMessage(''), 1000)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const updateName = (option: IGasTypeDropdownOption, name: string|undefined) => {
    if (isEditable(option) && name) {
      const t = tabs.map(tab => {
        if (tab.key === option.key) {
          tab.text = name
          tab.data.strGasName = name
        }
        return tab
      })
      setTabs(t)
    }
  }

  useEffect(() => {
    selectGasTypes().then(tabs => {
      console.log(tabs)
      setTabs(tabs)
    })
  }, [])

  return <div>
    {successMessage && <div style={{position: 'absolute', right: 0, top: '50px', width: '200px'}}>
      <MessageBar messageBarType={MessageBarType.success}>
        {successMessage}
      </MessageBar>
    </div>}

    <h3>Mixed Gas</h3>
    <Stack tokens={sectionStackTokens}>
      <table className="calibration-table" cellPadding={2} cellSpacing={2}>
        <thead>
          <tr>
            <th><Label>Name</Label></th>
            <th><Label>Specific Gravity</Label></th>
          </tr>
        </thead>

        <tbody>
          {tabs.map((option, idx) => <tr key={idx}>
            <td>
              <TextField
                readOnly={!isEditable(option)}
                value={option.text}
                onChange={(event, newValue) => updateName(option, newValue)}
                onBlur={() => {
                  if (isEditable(option) && option.text) {
                    persistOption(option, { strGasName: option.text })
                  }
                }}
              />
            </td>
            <td>
              <NumericTextField
                readOnly={!isEditable(option)}
                numericValue={option.data.numSG}
                precision={3}
                updateFunction={val => {
                  if (isEditable(option) && val) {
                    persistOption(option, { numSG: val })
                  }
                }}
              />
            </td>
            <td>
              {isEditable(option) && <IconButton onClick={() => deleteGas(option)} iconProps={deleteIcon} />}
            </td>
          </tr>)}

          <tr>
            <td colSpan={2}>
              <PrimaryButton onClick={addNewGas}>Add new</PrimaryButton>
            </td>
          </tr>
        </tbody>
      </table>
    </Stack>
  </div>
}
