import * as React from 'react'
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn, Dropdown, TextField,
} from '@fluentui/react'
import DatePicker from '../../../../components/DatePicker'

export interface IDetailsListDocumentsExampleState {
  columns: IColumn[];
  items: IDocument[];
  isCompactMode: boolean;
}

export interface IDocument {
  key: string;
  date: string | undefined;
  remarkType: string | undefined;
  remark: string | undefined;
}

const remarkTypes = [
  { key: 'conversion', text: 'Conversion' },
  { key: 'reCalibration', text: 'ReCalibration' },
  { key: 'replacement', text: 'Replacement' },
  { key: 'spareParts', text: 'Spare Parts' }
]

export default class Table extends React.Component<{}, IDetailsListDocumentsExampleState> {
  private _allItems: IDocument[]

  constructor (props: {}) {
    super(props)

    this._allItems = [
      { key: '1', date: new Date().toISOString(), remarkType: 'Conversion', remark: 'From: 40 psig To: 10 psig' }
    ]

    const columns: IColumn[] = [
      {
        key: 'column0',
        name: 'Date',
        fieldName: 'date',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: false,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: (item: IDocument) => {
          return <DatePicker/>
        },
        isPadded: true,
      },
      {
        key: 'column1',
        name: 'Remark Type',
        fieldName: 'remarkType',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: (item: IDocument) => {
          return <Dropdown
            options={remarkTypes}
            styles={{ dropdown: { width: 150 } }}
          />
        },
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Remark',
        fieldName: 'remark',
        minWidth: 150,
        maxWidth: 200,
        isSortedDescending: false,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return <TextField multiline rows={3}/>
        },
        isPadded: true,
      }
    ]

    this.state = {
      items: this._allItems,
      columns: columns,
      isCompactMode: true
    }
  }

  public render () {
    const { columns, isCompactMode, items } = this.state

    return (
      <div>
        <DetailsList
          items={items}
          compact={isCompactMode}
          columns={columns}
          selectionMode={SelectionMode.none}
          getKey={this._getKey}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onItemInvoked={this._onItemInvoked}
        />
      </div>
    )
  }

  public componentDidUpdate (previousProps: any, previousState: IDetailsListDocumentsExampleState) {}

  private _getKey (item: any, index?: number): string {
    return item.key
  }

  private _onItemInvoked (item: any): void {
    alert(`Item invoked: ${item.name}`)
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state
    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending)
    this.setState({
      columns: newColumns,
      items: newItems,
    })
  }
}

function _copyAndSort<T> (items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
}
