import { ITextFieldProps } from '@fluentui/react/lib/components/TextField/TextField.types'
import * as React from "react"
import { TextField } from '@fluentui/react'
import { formatNum } from '../utils/formatters'

interface INumericTextFieldProps extends ITextFieldProps {
  numericValue: number|undefined
  precision: number
  clearable?: boolean
  updateFunction: (val: number|undefined) => any
}

interface INumericTextFieldState {
  textValue: string,
}

export default class NumericTextField extends React.Component<INumericTextFieldProps, INumericTextFieldState> {
  constructor (props: INumericTextFieldProps) {
    super(props)
    this.state = { textValue: formatNum(props.numericValue, props.precision) }
  }

  private updateWithNumber (value: number|undefined) {
    this.setState({ textValue: formatNum(value, this.props.precision) })
  }

  componentDidUpdate (prevProps: Readonly<INumericTextFieldProps>, prevState: Readonly<INumericTextFieldState>, snapshot?: any) {
    if (prevProps.numericValue !== this.props.numericValue || prevProps.precision !== this.props.precision) {
      this.updateWithNumber(this.props.numericValue)
    }
  }

  render () {
    return <TextField
      {...this.props}
      type="number"
      value={this.state.textValue}
      onChange={(event, textValue) => {
        if (textValue) {
          this.setState({ textValue })
        } else if ((textValue === '' || textValue === undefined) && this.props.clearable) {
          this.setState({ textValue: '' })
        }
      }}
      onBlur={() => {
        if (this.state.textValue) {
          const newNumber = parseFloat(this.state.textValue)
          if (!isNaN(newNumber) && Number.isFinite(newNumber)) {
            this.updateWithNumber(newNumber)
            this.props.updateFunction(newNumber)
            return
          }
        }

        // fallback to undefined (e.g. clear property on object)
        this.updateWithNumber(undefined)
        this.props.updateFunction(undefined)
      }}
    />
  }
}
