import { IDropdownOption, IComboBoxOption } from '@fluentui/react'

export interface ISaeCalculatorInput {
  pressure: string|undefined,
  pressureUnit: IDropdownOption|IComboBoxOption|undefined,
  scale: string|undefined,
  scaleUnit: IDropdownOption|IComboBoxOption|undefined,
  temperature: string|undefined,
  temperatureUnit: IDropdownOption|IComboBoxOption|undefined,
  gasType: IDropdownOption|IComboBoxOption|undefined,
}

export interface ISAeCalculatorState extends ISaeCalculatorInput {
  gasSpecificGravity: number
  gasSpecificGravityAsString: string
  userProvidedSpecificGravity: string
  sae: number
  saeAsString: string
}

export interface IGasType {
  autonum: number // ID
  numSG: number // specific gravity
  strGasName: string // name
}

export interface IGasTypeDropdownOption {
  key: number
  text: string
  data: IGasType
}

export interface IMeterSize {
  autonum: number // ID
  MaxError: number
  fActive: boolean
  fFT: boolean
  fRCVPlus: boolean
  fSAVPlus: boolean
  fVT: boolean
  numBaseCalTime: number
  numHighSAE: number
  numLowSAE: number
  strMeterNum: number
  strMeterSize: string // name
  ynHighPress: boolean
  ynLiquid: boolean
  ynManualValve: boolean
  ynOver10psig: boolean
  ynSAV: boolean
  ynTronicRod: boolean
  ynValveTronic: boolean
}

export interface IFloMeterParameters {
  liquid: boolean
  tronic: boolean
  valveTronic: boolean
  valveTronicPlus: boolean
  rcvPlus: boolean
  sav: boolean
  savPlus: boolean
  manualValve: boolean
  floTronic: boolean
  calCurve: boolean
}

export interface IMeterSizeDropdownOption {
  key: number|string
  text: string
  data: IMeterSize
}

export interface ITblMeterInfo {
  numAuto: number
  'strSerial#': string|undefined
  'numCustId#': string|undefined
  'strCO#': string|undefined
  strShopOrder: string|undefined
  strMeterSize: string|undefined
  numScale: number|undefined
  strScaleUnit: string|undefined
  strDualScale: string|undefined
  strDualScaleUnit: string|undefined
  numPressure: number|undefined
  strPressUnit: string|undefined
  numTemperature: number|undefined
  strTempUnit: string|undefined
  strGas: string|undefined
  numSpecificGravity: number|undefined
  datShipDate: Date|undefined
  strNotes: string|undefined
  numSAE: number|undefined
  strShield: string|undefined
  numTestTemp: number|undefined
  numTestPress: number|undefined
  numTestBarometer: number|undefined
  numTestMeter: number|undefined
  numCAE: number|undefined
  'num95r%': number|undefined
  'num90r%': number|undefined
  'num80%r': number|undefined
  'num70r%': number|undefined
  'num60r%': number|undefined
  'num50r%': number|undefined
  'num40r%': number|undefined
  'num30r%': number|undefined
  'num25r%': number|undefined
  'num20r%': number|undefined
  'num15r%': number|undefined
  'num10r%': number|undefined
  'num5r%': number|undefined
  'num95%': number|undefined
  'num90%': number|undefined
  'num80%': number|undefined
  'num70%': number|undefined
  'num60%': number|undefined
  'num50%': number|undefined
  'num40%': number|undefined
  'num30%': number|undefined
  'num25%': number|undefined
  'num20%': number|undefined
  'num15%': number|undefined
  'num10%': number|undefined
  'num5%': number|undefined
  'ynFlo-Alarm': boolean
  'strFASerial #': string|undefined
  ynWaukeeTronic: boolean
  'strWTSerial#': string|undefined
  ynValveTronic: boolean
  'strVTSerial#': string|undefined
  'strVTNeedle#': string|undefined
  ynSAV: boolean
  'strSAVNeedle#': string|undefined
  'strSAVSerial#': string|undefined
  ynManualValve: boolean
  'strMVNeedle#': string|undefined
  ynTronicRod: boolean
  ynCalCurve: boolean
  strShipName: string|undefined
  strShipAddress: string|undefined
  strShipAddress2: string|undefined
  strShipAddress3: string|undefined
  strShipAddress4: string|undefined
  strShipZip: string|undefined
  strCalibrator: string|undefined
  strCalDate: Date|undefined
  numerr95: number|undefined
  numerr90r: number|undefined
  numerr80r: number|undefined
  numerr70r: number|undefined
  numerr60r: number|undefined
  numerr50r: number|undefined
  numerr40r: number|undefined
  numerr30r: number|undefined
  numerr25r: number|undefined
  numerr95r: number|undefined
  numerr20r: number|undefined
  numerr15r: number|undefined
  numerr10r: number|undefined
  numerr5r: number|undefined
  numerr20: number|undefined
  numerr15: number|undefined
  numerr10: number|undefined
  numerr5: number|undefined
  numerr90: number|undefined
  numerr80: number|undefined
  numerr70: number|undefined
  numerr60: number|undefined
  numerr50: number|undefined
  numerr40: number|undefined
  numerr30: number|undefined
  numerr25: number|undefined
  strBillName: string|undefined
  strBillAddress: string|undefined
  strBillAddress2: string|undefined
  strBillAddress3: string|undefined
  strBillAddress4: string|undefined
  strBillZip: string|undefined
  numFloat: number|undefined
  numTFRA: number|undefined
  numWeight: number|undefined
  numCountWeight: number|undefined
  numSugFloat: number|undefined
  numSugTFRA: number|undefined
  strEntryDate: Date|undefined
  ynPrinted: boolean
  ynChecked: boolean
  numZin: string|undefined
  numTrnLength: string|undefined
  ynPressureCheck: boolean
  ynRush: boolean
  ynReCal: boolean
  numPrePostCal: number|undefined
  strOrderBy: string|undefined
  ynLiquid: boolean
  datCalB: Date|undefined
  datCalE: Date|undefined
  AsReceivedNotes: string|undefined
  AfterCalNotes: string|undefined
  'strModel#': string|undefined
  strSensorSN: string|undefined
  strCBSN: string|undefined
  TronicModel: string|undefined
  TronicSensorSN: string|undefined
  TronicCBSN: string|undefined
  TronicMotorSN: string|undefined
  OrderDate: string|undefined
  ScheduleMinutes: number|undefined
  MilliAmp95: number|undefined
  MilliAmp90: number|undefined
  MilliAmp80: number|undefined
  MilliAmp70: number|undefined
  MilliAmp60: number|undefined
  MilliAmp50: number|undefined
  MilliAmp40: number|undefined
  MilliAmp30: number|undefined
  MilliAmp25: number|undefined
  MilliAmp20: number|undefined
  MilliAmp15: number|undefined
  MilliAmp10: number|undefined
  MilliAmp5: number|undefined
  fPowerLight: boolean
  fSensorAlarmLight: boolean
  fOutputAlarmLight: boolean
  fTempAlarmLight: boolean
  fAlarmRelay: boolean
  fDisplay: boolean
  fKeypad: boolean
  fMotor: boolean
  fHomeSwitch: boolean
  fAlarmRelays: boolean
  fFactoryDefaultsSet: boolean
  fFlowSensorCalibrated: boolean
  fAnalogInputCalibrated: boolean
  fConfigFileUploaded: boolean
  fVerification: boolean
  Needle: string|undefined
  MaxError: number|undefined
  Accuracy: number|undefined
  SAE: number|undefined
  ActualFlow100: number|undefined
  ActualFlow95: number|undefined
  ActualFlow90: number|undefined
  ActualFlow80: number|undefined
  ActualFlow70: number|undefined
  ActualFlow60: number|undefined
  ActualFlow50: number|undefined
  ActualFlow40: number|undefined
  ActualFlow30: number|undefined
  ActualFlow25: number|undefined
  ActualFlow20: number|undefined
  ActualFlow15: number|undefined
  ActualFlow10: number|undefined
  ActualFlow5: number|undefined
  ActualFlow4: number|undefined
  ActualFlow3: number|undefined
  ActualFlow2: number|undefined
  FinalInspection: string|undefined
  FinalDate: Date|undefined
  DisplayReading95: number|undefined
  DisplayReading90: number|undefined
  DisplayReading80: number|undefined
  DisplayReading70: number|undefined
  DisplayReading60: number|undefined
  DisplayReading50: number|undefined
  DisplayReading40: number|undefined
  DisplayReading30: number|undefined
  DisplayReading25: number|undefined
  DisplayReading20: number|undefined
  DisplayReading15: number|undefined
  DisplayReading10: number|undefined
  DisplayReading5: number|undefined
  Polynomial: string|undefined
  fValveTronicPlus: boolean
  fRCVPlus: boolean
  fSAVPlus: boolean
  fFloTronic: boolean
  Cap: boolean
  CalCurveOptionID: number|undefined
  PanelManifold: boolean
  PurgeOrifice: boolean
  fFloAlarmSingle: boolean
  'strMVSerial#': string|undefined
  'strFTSerial#': string|undefined
}

export function createEmptyMeterInfo (keys?: (keyof ITblMeterInfo)[]): ITblMeterInfo {
  const initialValues = {
    numAuto: 0,
    'strSerial#': undefined,
    'numCustId#': undefined,
    'strCO#': undefined,
    strShopOrder: undefined,
    strMeterSize: undefined,
    numScale: undefined,
    strScaleUnit: undefined,
    strDualScale: undefined,
    strDualScaleUnit: undefined,
    numPressure: undefined,
    strPressUnit: undefined,
    numTemperature: undefined,
    strTempUnit: undefined,
    strGas: undefined,
    numSpecificGravity: undefined,
    datShipDate: undefined,
    strNotes: undefined,
    numSAE: undefined,
    strShield: undefined,
    numTestTemp: undefined,
    numTestPress: undefined,
    numTestBarometer: undefined,
    numTestMeter: undefined,
    numCAE: undefined,
    'num95r%': undefined,
    'num90r%': undefined,
    'num80%r': undefined,
    'num70r%': undefined,
    'num60r%': undefined,
    'num50r%': undefined,
    'num40r%': undefined,
    'num30r%': undefined,
    'num25r%': undefined,
    'num20r%': undefined,
    'num15r%': undefined,
    'num10r%': undefined,
    'num5r%': undefined,
    'num95%': undefined,
    'num90%': undefined,
    'num80%': undefined,
    'num70%': undefined,
    'num60%': undefined,
    'num50%': undefined,
    'num40%': undefined,
    'num30%': undefined,
    'num25%': undefined,
    'num20%': undefined,
    'num15%': undefined,
    'num10%': undefined,
    'num5%': undefined,
    'ynFlo-Alarm': false,
    'strFASerial #': undefined,
    ynWaukeeTronic: false,
    'strWTSerial#': undefined,
    ynValveTronic: false,
    'strVTSerial#': undefined,
    'strVTNeedle#': undefined,
    ynSAV: false,
    'strSAVNeedle#': undefined,
    'strSAVSerial#': undefined,
    ynManualValve: false,
    'strMVNeedle#': undefined,
    ynTronicRod: false,
    ynCalCurve: false,
    strShipName: undefined,
    strShipAddress: undefined,
    strShipAddress2: undefined,
    strShipAddress3: undefined,
    strShipAddress4: undefined,
    strShipZip: undefined,
    strCalibrator: undefined,
    strCalDate: undefined,
    numerr95: undefined,
    numerr90r: undefined,
    numerr80r: undefined,
    numerr70r: undefined,
    numerr60r: undefined,
    numerr50r: undefined,
    numerr40r: undefined,
    numerr30r: undefined,
    numerr25r: undefined,
    numerr95r: undefined,
    numerr20r: undefined,
    numerr15r: undefined,
    numerr10r: undefined,
    numerr5r: undefined,
    numerr20: undefined,
    numerr15: undefined,
    numerr10: undefined,
    numerr5: undefined,
    numerr90: undefined,
    numerr80: undefined,
    numerr70: undefined,
    numerr60: undefined,
    numerr50: undefined,
    numerr40: undefined,
    numerr30: undefined,
    numerr25: undefined,
    strBillName: undefined,
    strBillAddress: undefined,
    strBillAddress2: undefined,
    strBillAddress3: undefined,
    strBillAddress4: undefined,
    strBillZip: undefined,
    numFloat: undefined,
    numTFRA: undefined,
    numWeight: undefined,
    numCountWeight: undefined,
    numSugFloat: undefined,
    numSugTFRA: undefined,
    strEntryDate: undefined,
    ynPrinted: false,
    ynChecked: false,
    numZin: undefined,
    numTrnLength: undefined,
    ynPressureCheck: false,
    ynRush: false,
    ynReCal: false,
    numPrePostCal: undefined,
    strOrderBy: undefined,
    ynLiquid: false,
    datCalB: undefined,
    datCalE: undefined,
    AsReceivedNotes: undefined,
    AfterCalNotes: undefined,
    'strModel#': undefined,
    strSensorSN: undefined,
    strCBSN: undefined,
    TronicModel: undefined,
    TronicSensorSN: undefined,
    TronicCBSN: undefined,
    TronicMotorSN: undefined,
    OrderDate: undefined,
    ScheduleMinutes: undefined,
    MilliAmp95: undefined,
    MilliAmp90: undefined,
    MilliAmp80: undefined,
    MilliAmp70: undefined,
    MilliAmp60: undefined,
    MilliAmp50: undefined,
    MilliAmp40: undefined,
    MilliAmp30: undefined,
    MilliAmp25: undefined,
    MilliAmp20: undefined,
    MilliAmp15: undefined,
    MilliAmp10: undefined,
    MilliAmp5: undefined,
    fPowerLight: false,
    fSensorAlarmLight: false,
    fOutputAlarmLight: false,
    fTempAlarmLight: false,
    fAlarmRelay: false,
    fDisplay: false,
    fKeypad: false,
    fMotor: false,
    fHomeSwitch: false,
    fAlarmRelays: false,
    fFactoryDefaultsSet: false,
    fFlowSensorCalibrated: false,
    fAnalogInputCalibrated: false,
    fConfigFileUploaded: false,
    fVerification: false,
    Needle: undefined,
    MaxError: undefined,
    Accuracy: undefined,
    SAE: undefined,
    ActualFlow100: undefined,
    ActualFlow95: undefined,
    ActualFlow90: undefined,
    ActualFlow80: undefined,
    ActualFlow70: undefined,
    ActualFlow60: undefined,
    ActualFlow50: undefined,
    ActualFlow40: undefined,
    ActualFlow30: undefined,
    ActualFlow25: undefined,
    ActualFlow20: undefined,
    ActualFlow15: undefined,
    ActualFlow10: undefined,
    ActualFlow5: undefined,
    ActualFlow4: undefined,
    ActualFlow3: undefined,
    ActualFlow2: undefined,
    FinalInspection: undefined,
    FinalDate: undefined,
    DisplayReading95: undefined,
    DisplayReading90: undefined,
    DisplayReading80: undefined,
    DisplayReading70: undefined,
    DisplayReading60: undefined,
    DisplayReading50: undefined,
    DisplayReading40: undefined,
    DisplayReading30: undefined,
    DisplayReading25: undefined,
    DisplayReading20: undefined,
    DisplayReading15: undefined,
    DisplayReading10: undefined,
    DisplayReading5: undefined,
    Polynomial: undefined,
    fValveTronicPlus: false,
    fRCVPlus: false,
    fSAVPlus: false,
    fFloTronic: false,
    Cap: false,
    CalCurveOptionID: undefined,
    PanelManifold: false,
    PurgeOrifice: false,
    fFloAlarmSingle: false,
    'strMVSerial#': undefined,
    'strFTSerial#': undefined
  }

  if (keys) {
    const initialsByKey: any = {}
    keys.forEach(key => {
      initialsByKey[key] = initialValues[key]
    })
    return initialsByKey
  }

  return initialValues
}

export interface iTblLookupCalFactor {
  numTestMeter: number
  numMeterFactor: number
  'numSerial#': string
  'strManf': string
  strLastCalDate: Date
  strUnits: string
  Version: number
  CMC: number
}

export type TTblSearchResult = {[key in keyof ITblMeterInfo]?: ITblMeterInfo[key]}
export type TTblSearchResults = TTblSearchResult[]
