import * as React from 'react'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import {
  Checkbox,
  ChoiceGroup,
  DatePicker,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  IComboBoxOption,
  ITag,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
  TagPicker,
  TextField
} from '@fluentui/react'
import { get, isUndefined } from 'lodash'
import {
  dropdownStyles,
  headingStackTokens,
  rowSpacingStackTokens,
  sectionStackTokens,
  stackStyles
} from '../../../styles'
import { searchMeterInfoBySerialNumber, selectMeterInfo } from '../../../utils/azure_function_calls'
import { createEmptyMeterInfo, ITblMeterInfo } from '../../../interfaces'
import { formatNum } from '../../../utils/formatters'
import { callAzureFunctionGet } from '../../../utils/callAzureFunction'
import { persistMeterInfo } from '../ShopCardPage/meterInfoUtils'
import { PrintCalibrationDialog } from './PrintCalibration'
import './index.css'
import NumericTextField from '../../../components/NumericTextField'
import { PrintShopCardDialog } from '../ShopCardPage/PrintShopCard'
import { PressureUnits, TemperatureUnits } from '../../../utils/units'

const boldText: CSSProperties = { fontWeight: 'bold' }

const optionsTestMeters: IChoiceGroupOption[] = [
  { key: 'all', text: 'All Test Meters' },
  { key: 'inactive', text: 'Inactive Test Meters' },
  // { key: 'original', text: 'Original Test Meters' },
  { key: 'new', text: 'New Test Meters' }
]

const blankMeterInfo = createEmptyMeterInfo()

export const percents = [95, 90, 80, 70, 60, 50, 40, 30, 25, 20, 15, 10, 5]

export const permissibleErrors = {
  L: [3, 3, 3, 3, 3, 3, 3.75, 5, 6, 7.5, 10, 15, 30],
  M: [4, 4, 4, 4, 4, 4, 5, 6.667, 8, 10, 13.333, 20, 40],
  S: [5, 5, 5, 5, 5, 5, 6.25, 8.333, 10, 12.5, 16.667, 25, 50]
}

const meterSeries: (IDropdownOption|IComboBoxOption)[] = [
  { key: 'S', text: 'S' },
  { key: 'M', text: 'M' },
  { key: 'L', text: 'L' }
]

export default function CalibrationPage () {
  const [calCurveOptions, setCalCurveOptions] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<ITag[]>([])
  const [meterInfo, setMeterInfo] = useState<ITblMeterInfo>(blankMeterInfo)
  const [testMeters, setTestMeters] = useState<(IDropdownOption|IComboBoxOption)[]>([])
  const [selectedTestMeter, setSelectedTestMeter] = useState<number|undefined>(undefined)
  const [allTestMeters, setAllTestMeters] = useState<(IDropdownOption|IComboBoxOption)[]>([])
  const [testMeterVersion, setTestMeterVersion] = useState<string>(optionsTestMeters[0].key)
  const [calibrationTechs, setCalibrationTechs] = useState<(IDropdownOption|IComboBoxOption)[]>([])
  const [selectedMeterSeries, setSelectedMeterSeries] = useState(meterSeries[0].key)

  const getFilteredTestMeters = useCallback((meters = allTestMeters, version = testMeterVersion): (IDropdownOption|IComboBoxOption)[] => {
    switch (version) {
      case 'inactive': return meters.filter((m: any) => m.data.version === 0)
      case 'original': return meters.filter((m: any) => m.data.version === 1)
      case 'new': return meters.filter((m: any) => m.data.version === 2)
      default: return meters.filter(() => true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const meterToDropdown = (result: any): IDropdownOption|IComboBoxOption => {
    return { key: result.numTestMeter, text: result.numTestMeter, data: result }
  }

  const techToDropdown = (tech: any): IDropdownOption|IComboBoxOption => {
    return { key: tech.CalibrationTech, text: tech.CalibrationTech }
  }

  useEffect(() => {
    callAzureFunctionGet('SelectTestMeters')
      .then(results => {
        const meters = Array.isArray(results) ? results.map(meterToDropdown) : []
        setAllTestMeters(meters)
        setTestMeters(getFilteredTestMeters(meters))
      })

    callAzureFunctionGet('SelectCalibrationTechs')
      .then(techs => setCalibrationTechs(techs.map(techToDropdown)))

    callAzureFunctionGet('SelectCalCurveOptions').then(setCalCurveOptions)
  }, [getFilteredTestMeters])

  const onSerialNumberSelected = async (serialNumbers: ITag[]|undefined) => {
    if (!serialNumbers?.length) {
      setSelectedSerialNumbers([])
      setMeterInfo(blankMeterInfo)
    } else {
      setSelectedSerialNumbers([serialNumbers[0]])
      const info = await selectMeterInfo(undefined, +serialNumbers[0].key)

      setMeterInfo(info)

      if (info.numTestMeter !== undefined) {
        setSelectedTestMeter(info.numTestMeter)
      }
    }
  }

  const getScaleUnits = (percent: number) => {
    let val
    if (meterInfo.numTestMeter && meterInfo.numTestMeter > 11000) {
      val = meterInfo.numCAE || 0
    } else {
      val = meterInfo.numScale || 0
    }

    return val * percent / 100
  }

  const getPermissibleError = (percentage: number) => {
    const index = percents.indexOf(percentage)
    // @ts-ignore
    return permissibleErrors[selectedMeterSeries][index]
  }

  const updateMeter = (delta: any) => {
    if (meterInfo) {
      setMeterInfo({ ...meterInfo, ...delta })
    }
  }

  const saveMeterInfo = (delta: any, saveAlways = false) => {
    if (meterInfo) {
      updateMeter(delta)

      persistMeterInfo(meterInfo, delta, () => {
        setSuccessMessage('Saved')
        setTimeout(() => setSuccessMessage(''), 2500)
      }, saveAlways)
    }
  }

  const updateCAE = (delta: any) => {
    const meter = { ...meterInfo, delta }

    const isValid = Number.isFinite(meter.numTestTemp) &&
      Number.isFinite(meter.numTestPress) &&
      Number.isFinite(meter.numSpecificGravity) &&
      Number.isFinite(meter.numSAE)

    if (isValid) {
      // @ts-ignore
      const cae = meter.numSAE * Math.sqrt((406.89 + meter.numTestPress) / 406.89) * Math.sqrt(530 / (460 + meter.numTestTemp))
      if (cae !== meter.numCAE) {
        delta.numCAE = cae
      }
    }

    saveMeterInfo(delta)
  }

  const getReadingError = (percentage: number) => {
    const reading = get(meterInfo, `num${percentage}%`)
    if (reading === null || isUndefined(reading) || reading === '') {
      return reading
    } else {
      const units = getScaleUnits(percentage)
      return 100 * ((reading - units) / units)
    }
  }

  const updateMeterInfoField = (ev: any, newValue?: string): void => {
    if (meterInfo && ev?.target) {
      const key = ev.target.name
      const str = newValue || ''
      if (ev.target.type === 'number') {
        const num = parseFloat(str)
        if (!isNaN(num)) {
          console.log(`updateMeterInfoField.${key}`, num)
          saveMeterInfo({ [key]: num })
        }
      } else {
        console.log(`updateMeterInfoField.${key}`, str)
        saveMeterInfo({ [key]: str })
      }
    }
  }

  return (
    <div>
      {successMessage && <div style={{position: 'absolute', right: 0, top: '50px', width: '200px'}}><MessageBar messageBarType={MessageBarType.success}>
        {successMessage}
      </MessageBar></div>}

      <h3>Calibration</h3>

      <Stack tokens={sectionStackTokens}>

        <Stack horizontal wrap tokens={headingStackTokens}>
          <Stack wrap tokens={sectionStackTokens}>
            <Stack styles={stackStyles} className="group-box">

              <Stack horizontal verticalAlign="start" horizontalAlign={'space-between'} tokens={rowSpacingStackTokens}>
                <Label>Customer Information</Label>
                <TagPicker
                  itemLimit={1}
                  inputProps={{ placeholder: 'Find S/N' }}
                  selectedItems={selectedSerialNumbers}
                  onChange={items => onSerialNumberSelected(items)}
                  onResolveSuggestions={searchMeterInfoBySerialNumber}
                  resolveDelay={200}
                />
              </Stack>

              <Stack horizontal tokens={headingStackTokens}>
                <Stack>
                  <TextField readOnly={true} borderless={true} label="Customer ID#" value={meterInfo["numCustId#"]} />
                  <TextField readOnly={true} borderless={true} label="Customer" value={meterInfo.strBillName} />
                  <TextField readOnly={true} borderless={true} label="Serial #" value={meterInfo["strSerial#"]} />
                  <TextField readOnly={true} borderless={true} label="Shop Order #" value={meterInfo.strShopOrder} />
                </Stack>

                <Stack>
                  <TextField readOnly={true} borderless={true} label="Billing Address" value={meterInfo.strBillAddress || '-'} />
                  <TextField readOnly={true} borderless={true} label="Line 2" value={meterInfo.strBillAddress2 || '-'} />
                  <TextField readOnly={true} borderless={true} label="Line 3" value={meterInfo.strBillAddress3 || '-'} />
                  <TextField readOnly={true} borderless={true} label="Line 4" value={meterInfo.strBillAddress4 || '-'} />
                </Stack>

                <Stack>
                  <TextField readOnly={true} borderless={true} label="Zip Code" value={meterInfo.strBillZip} />
                </Stack>
              </Stack>

            </Stack>
            <Stack styles={stackStyles}>

              <Stack horizontal verticalAlign={'stretch'} horizontalAlign={'space-between'} tokens={headingStackTokens}>

                <Stack className="group-box" horizontal verticalAlign={'start'} wrap tokens={headingStackTokens}>

                  <Stack tokens={sectionStackTokens}>
                    <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                      <TextField label="Meter size" readOnly={true} borderless={true} value={meterInfo.strMeterSize} />
                    </Stack>

                    <TextField label="Scale" readOnly={true} borderless={true} value={formatNum(meterInfo.numScale) + '  ' + (meterInfo.strScaleUnit || '')} />

                    {/*<Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>*/}
                    {/*  <TextField label="Dual Scale" readOnly={true} borderless={true} value={meterInfo.strDualScale}/>*/}
                    {/*  <TextField value={meterInfo.strDualScaleUnit} readOnly={true} borderless={true} />*/}
                    {/*</Stack>*/}

                    <TextField label="Pressure" readOnly={true} borderless={true} value={formatNum(meterInfo.numPressure) + '  ' + (meterInfo.strPressUnit || '')} />

                    <TextField label="Temperature" readOnly={true} borderless={true} value={formatNum(meterInfo.numTemperature) + '  ' + (meterInfo.strTempUnit || '')} />

                    <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                      <TextField label="Gas Type" readOnly={true} borderless={true} value={meterInfo.strGas}/>
                      <TextField label="SAE" readOnly={true} borderless={true} value={formatNum(meterInfo.numSAE)}/>
                      <TextField label="SG" readOnly={true} borderless={true} value={formatNum(meterInfo.numSpecificGravity)} />
                    </Stack>
                  </Stack>

                  <Stack tokens={sectionStackTokens}>
                    <Checkbox label="Liquid Meter" checked={meterInfo.ynLiquid} />
                    <Checkbox label="Tronic Rod" checked={meterInfo.ynTronicRod} />
                  </Stack>
                </Stack>

                <Stack className="group-box">
                  <Label>Calibration Conditions</Label>

                  <Stack horizontal>
                    <Stack>
                      <NumericTextField
                        label="Test Barometer"
                        clearable={true}
                        numericValue={meterInfo.numTestBarometer}
                        precision={2}
                        updateFunction={(numTestBarometer: number|undefined) => updateCAE({ numTestBarometer })}
                        suffix={PressureUnits.PSIG}
                      />

                      <NumericTextField
                        label="Test Temperature"
                        numericValue={meterInfo.numTestTemp}
                        clearable={true}
                        precision={2}
                        updateFunction={(numTestTemp: number|undefined) => updateCAE({ numTestTemp })}
                        suffix={TemperatureUnits.FAHRENHEIT}
                      />

                      <NumericTextField
                        label="Test Pressure"
                        clearable={true}
                        numericValue={meterInfo.numTestPress}
                        precision={2}
                        updateFunction={(numTestPress: number|undefined) => updateCAE({ numTestPress })}
                        suffix={PressureUnits.DEGREES_WCG}
                      />

                      <Dropdown
                        label="Test Meter"
                        options={testMeters}
                        selectedKey={selectedTestMeter}
                        styles={dropdownStyles}
                        onChange={(event, option) => {
                          if (option) {
                            const numTestMeter = parseInt(option.key + '')
                            setSelectedTestMeter(numTestMeter)
                            saveMeterInfo({ numTestMeter })
                          }
                        }}
                      />

                      <TextField readOnly={true} label="CAE" style={boldText} value={formatNum(meterInfo.numCAE)} />

                      <Dropdown
                        label="Meter Series"
                        options={meterSeries}
                        selectedKey={selectedMeterSeries}
                        onChange={(event, option) => {
                          if (option?.key) {
                            setSelectedMeterSeries(option?.key)
                          }
                        }}
                        styles={dropdownStyles}
                      />
                    </Stack>

                    <ChoiceGroup
                      style={{ marginLeft: '2em' }}
                      options={optionsTestMeters}
                      selectedKey={testMeterVersion}
                      onChange={(ev, option) => {
                        if (option) {
                          setTestMeterVersion(option.key)

                          const meters = getFilteredTestMeters(allTestMeters, option.key)
                          setTestMeters(meters)

                          const testMeterIsValid = meters.some(meter => meter.key === selectedTestMeter)
                          if (!testMeterIsValid) {
                            setSelectedTestMeter(+meters[0].key)
                          }
                        }
                      }}
                      required={true}
                    />
                  </Stack>
                </Stack>

              </Stack>

            </Stack>
          </Stack>

          <Stack>
            <Stack styles={stackStyles} tokens={sectionStackTokens}>
              <PrintCalibrationDialog
                selectedMeterSeries={selectedMeterSeries}
                meterInfo={meterInfo}
                testMeters={allTestMeters.map(e => e.data)}
                disabled={!meterInfo["strSerial#"]}
              />

              <PrintShopCardDialog
                meterInfo={meterInfo}
                disabled={!meterInfo["strSerial#"]}
                calCurveOptions={calCurveOptions}
                label="Cal. Card"
              />

              <PrintShopCardDialog
                meterInfo={meterInfo}
                disabled={!meterInfo["strSerial#"]}
                calCurveOptions={calCurveOptions}
                isNegative={true}
                label="Cal. Info"
              />
            </Stack>
          </Stack>

        </Stack>

        <Stack tokens={sectionStackTokens}>
          <Stack.Item align="start" className="group-box" tokens={sectionStackTokens}>
            <table className="calibration-table" cellPadding={2} cellSpacing={2}>
              <thead>
                <tr className="calibration-table-headers">
                  <td>&nbsp;</td>
                  <td><Label>Scale Units (sec)</Label></td>
                  <td><Label>Post-Cal Units (sec)</Label></td>
                  <td><Label>Permissible Error + (%)</Label></td>
                  <td><Label>Permissible Error - (%)</Label></td>
                  <td><Label>Error of Reading (%)</Label></td>
                  <td><Label>Milli-Amp Reading (%)</Label></td>
                  <td><Label>Display Reading (%)</Label></td>
                </tr>
              </thead>

              <tbody>
                {percents.map((pct, index) => {
                  return <tr key={index}>
                    <td>
                      <TextField value={pct + '%'} borderless={true} readOnly={true} style={{width: '50px'}} />
                    </td>
                    <td>
                      <TextField value={formatNum(getScaleUnits(pct))} readOnly={true} />
                    </td>
                    <td>
                      <NumericTextField
                        clearable={true}
                        numericValue={get(meterInfo, `num${pct}%`)}
                        precision={2}
                        updateFunction={(val: number|undefined) => {
                          saveMeterInfo({ [`num${pct}%`]: isUndefined(val) ? null : val })
                        }} />
                    </td>
                    <td>
                      <TextField value={formatNum(getPermissibleError(pct), 3)} readOnly={true} />
                    </td>
                    <td>
                      <TextField value={formatNum(getPermissibleError(pct), 3)} readOnly={true} />
                    </td>
                    <td>
                      <TextField value={formatNum(getReadingError(pct))} readOnly={true} style={boldText} />
                    </td>
                    <td>
                      <TextField />
                    </td>
                    <td>
                      <TextField />
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </Stack.Item>

          <Stack.Item align="start" className="group-box" tokens={sectionStackTokens}>
            <Stack styles={stackStyles}>
              <TextField
                label="Notes"
                multiline
                rows={3}
                name="strNotes"
                value={meterInfo.strNotes}
                onChange={updateMeterInfoField}
              />

              <Stack horizontal verticalAlign={'start'} wrap tokens={headingStackTokens}>

                <Stack>
                  <NumericTextField
                    label="Float"
                    numericValue={meterInfo.numFloat}
                    precision={4}
                    updateFunction={(numFloat: number|undefined) => saveMeterInfo({ numFloat })}
                  />

                  <NumericTextField
                    label="Weights"
                    numericValue={meterInfo.numWeight}
                    precision={4}
                    updateFunction={(numWeight: number|undefined) => saveMeterInfo({ numWeight })}
                  />

                  <NumericTextField
                    label="TFRA"
                    numericValue={meterInfo.numTFRA}
                    precision={4}
                    updateFunction={(numTFRA: number|undefined) => saveMeterInfo({ numTFRA })}
                  />

                  <NumericTextField
                    label="#Weights"
                    numericValue={meterInfo.numCountWeight}
                    precision={2}
                    updateFunction={(numCountWeight: number|undefined) => saveMeterInfo({ numCountWeight })}
                  />

                  <TextField label="Z-In" disabled />

                  <TextField
                    disabled
                    label="Rod Length"
                    value={meterInfo.numTrnLength}
                  />
                </Stack>

                <Stack>
                  <Dropdown
                    inputMode="text"
                    label="Calibrated By"
                    options={calibrationTechs}
                    styles={dropdownStyles}
                    selectedKey={meterInfo.strCalibrator}
                    onChange={(event, option) => saveMeterInfo({ strCalibrator: option?.key })}
                  />

                  <DatePicker
                    label="Date"
                    value={(meterInfo.strCalDate instanceof Date) ? meterInfo.strCalDate : undefined}
                    onSelectDate={strCalDate => saveMeterInfo({ strCalDate })}
                  />

                  {/*<Dropdown*/}
                  {/*  label="Final Insp. By"*/}
                  {/*  options={calibrationTechs}*/}
                  {/*  styles={dropdownStyles}*/}
                  {/*/>*/}

                  {/*<DatePicker*/}
                  {/*  label="Date"*/}
                  {/*/>*/}
                </Stack>

                <Stack tokens={sectionStackTokens}>
                  <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                    <Label>Needle</Label><p>{meterInfo["strMVNeedle#"]}</p>
                  </Stack>
                  <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                    <Label>Tronic Model</Label><p>{meterInfo.TronicModel}</p>
                  </Stack>
                  <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                    <Label>Tronic Sensor SN</Label><p>{meterInfo.TronicSensorSN}</p>
                  </Stack>
                  <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                    <Label>Tronic CBSN</Label><p>{meterInfo.TronicCBSN}</p>
                  </Stack>
                  <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                    <Label>Tronic Motor SN</Label><p>{meterInfo.TronicMotorSN}</p>
                  </Stack>
                </Stack>

              </Stack>

            </Stack>
          </Stack.Item>
        </Stack>

      </Stack>

    </div>
  )
}
