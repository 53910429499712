import { apiRequest, graphConfig } from '../authConfig'
import { msalInstance } from '../index'
import { map, isPlainObject, isEmpty } from 'lodash'

const CACHE_TOKENS = false

let token: any = null

let tokenExpirationTimestamp = 0

const getToken = async () => {
  if (CACHE_TOKENS && !!token && Date.now() < tokenExpirationTimestamp) {
    return Promise.resolve(token)
  }

  const account = msalInstance.getActiveAccount()
  if (!account) {
    return Promise.resolve(new Error('No active account! Verify a user has been signed in and setActiveAccount has been called.'))
  }

  token = await msalInstance.acquireTokenSilent({ ...apiRequest, account })

  if (token) {
    tokenExpirationTimestamp = (new Date(token.expiresOn)).getTime()
  } else {
    tokenExpirationTimestamp = 0
  }

  return token
}

export async function callAzureFunction (functionName: string, method: string = 'GET', params: object = {}) {
  try {
    const { accessToken } = await getToken()

    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`
    headers.append('Authorization', bearer)

    const options = { method, headers, ...params }

    const isLocalhost = (window.location.hostname === 'localhost')
    const baseUrl = isLocalhost ? 'http://localhost:7071/api/' : graphConfig.functionBaseUrl
    const fullUrl = baseUrl + functionName

    const response = await fetch(fullUrl, options)
    return response.json()
  } catch (e) {
    console.log(e)
    return { OK: true }
  }
}

export async function callAzureFunctionGet (functionName: string, params: Object = {}) {
  if (isPlainObject(params) && !isEmpty(params)) {
    const querystring = map(params, (value, key) => `${key}=${value}`).join('&')
    return callAzureFunction(`${functionName}?${querystring}`)
  } else {
    return callAzureFunction(functionName)
  }
}

export async function callAzureFunctionPost (functionName: string, params: Object = {}) {
  if (isPlainObject(params) && !isEmpty(params)) {
    const body = JSON.stringify(params)
    return callAzureFunction(`${functionName}`, 'POST', { body })
  } else {
    return callAzureFunction(functionName)
  }
}

export async function callAzureFunctionPut (functionName: string, params: Object = {}) {
  if (isPlainObject(params) && !isEmpty(params)) {
    const body = JSON.stringify(params)
    return callAzureFunction(`${functionName}`, 'PUT', { body })
  } else {
    return callAzureFunction(functionName)
  }
}

export async function callAzureFunctionDelete (functionName: string, params: Object = {}) {
  if (isPlainObject(params) && !isEmpty(params)) {
    const body = JSON.stringify(params)
    return callAzureFunction(`${functionName}`, 'DELETE', { body })
  } else {
    return callAzureFunction(functionName)
  }
}
