import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DefaultButton, Dropdown, IComboBoxOption, IDropdownOption, ITextFieldStyles, Stack, TextField } from '@fluentui/react'
import {
  dropdownStyles,
  headingStackTokens,
  sectionStackTokens,
  unitDropdownStyles
} from '../../../styles'
import { has, clone, forEach, isUndefined } from 'lodash'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { searchMeters, selectGasTypes, selectMeterSizes } from '../../../utils/azure_function_calls'
import { IMeterSizeDropdownOption, TTblSearchResult, TTblSearchResults } from '../../../interfaces'
import { units } from '../../../utils/constants'
import { MeterSearchResults } from './meterSearchResults'

const LOG_ENABLED = false

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200 } }

const yesNoOptions: (IDropdownOption|IComboBoxOption)[] = [
  { key: 'select', text: 'Any', data: undefined },
  { key: 'yes', text: 'Yes', data: true },
  { key: 'no', text: 'No', data: false },
]

export default function DynamicReportsPage () {
  const [searchParams, setSearchParams] = useState({
    'strSerial#': undefined,
    strBillName: undefined,
    strShipName: undefined,
    strGas: undefined,
    strMeterSize: undefined,
    numScale: undefined,
    strScaleUnit: undefined,
    numPressure: undefined,
    strPressUnit: undefined,
    ynLiquid: undefined,
    Cap: undefined,
    ynManualValve: undefined,
    fFloTronic: undefined,
    fValveTronicPlus: undefined,
    'ynFlo-Alarm': undefined,
    PurgeOrifice: undefined
  })

  const [meterSizes, setMeterSizes] = useState<IMeterSizeDropdownOption[]>([])
  const [gasTypes, setGasTypes] = useState([])
  const [searchResults, setSearchResults] = useState<TTblSearchResults>([])

  const { inProgress } = useMsal()
  const history = useHistory()

  const search = () => {
    const sp = clone(searchParams)

    forEach(sp, (val, key) => {
      if (isUndefined(val)) {
        // @ts-ignore
        delete sp[key]
      }
    })

    searchMeters(searchParams).then(setSearchResults)
  }

  const onTextChanged = (ev: any, newValue?: string): void => {
    const key = ev?.target?.name
    if (key) {
      if (LOG_ENABLED) {
        console.log(`setSearchParams.${key}`, newValue)
      }
      setSearchParams({ ...searchParams, [key]: newValue })
    }
  }

  const onDropdownChanged = (ev: any, option: any): void => {
    const key = ev?.target.id
    if (key && option) {
      let newValue = has(option, 'data') ? option.data : option.key

      if (newValue && newValue.strMeterSize) {
        newValue = newValue.strMeterSize
      } else if (newValue && newValue.strGasName) {
        newValue = newValue.strGasName
      }

      if (LOG_ENABLED) {
        console.log(`setSearchParams.${key}`, newValue)
      }

      setSearchParams({ ...searchParams, [key]: newValue })
    }
  }

  // fetch init data
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      selectGasTypes().then(setGasTypes)
      selectMeterSizes().then(setMeterSizes)
    }
  }, [inProgress])

  return (
    <div>

      <h3>Dynamic Reporting</h3>

      <Stack tokens={sectionStackTokens}>

        <Stack horizontal wrap>
          <DefaultButton className="w-default-button" text="Search" onClick={search}/>
        </Stack>

        <Stack horizontal wrap tokens={headingStackTokens}>
          <Stack>
            <TextField
              label="Serial #"
              name="strSerial#"
              styles={textFieldStyles}
              value={searchParams['strSerial#']}
              onChange={onTextChanged}
            />
            <TextField
              label="Sold To"
              name="strBillName"
              styles={textFieldStyles}
              value={searchParams.strBillName}
              onChange={onTextChanged}
            />
            <TextField
              label="Ship To"
              name="strShipName"
              styles={textFieldStyles}
              value={searchParams.strShipName}
              onChange={onTextChanged}
            />
          </Stack>
          <Stack>
            <Dropdown
              label="Tab"
              id="strGas"
              placeholder="Select a tab"
              options={gasTypes}
              styles={dropdownStyles}
              onChange={onDropdownChanged}
            />
            <TextField
              label="Scale"
              type="number"
              name="numScale"
              value={searchParams.numScale}
              onChange={onTextChanged}
            />
            <TextField
              label="Pressure"
              type="number"
              name="numPressure"
              value={searchParams.numPressure}
              onChange={onTextChanged}
            />
          </Stack>
          <Stack>
            <Dropdown
              label="Meter Size"
              id="strMeterSize"
              placeholder="Select a meter size"
              options={meterSizes}
              styles={dropdownStyles}
              onChange={onDropdownChanged}
            />
            <Dropdown
              label="Unit"
              id="strScaleUnit"
              options={units.scale}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />
            <Dropdown
              label="Unit"
              id="strPressUnit"
              options={units.pressure}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />
          </Stack>
          <Stack>
            <Dropdown
              label="Liquid"
              id="ynLiquid"
              placeholder="Any"
              options={yesNoOptions}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />

            <Dropdown
              label="Flo-Tronic Plus"
              id="fFloTronic"
              placeholder="Any"
              options={yesNoOptions}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />

            <Dropdown
              label="Flo-Alarm"
              id="ynFlo-Alarm"
              placeholder="Any"
              options={yesNoOptions}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />
          </Stack>

          <Stack>
            <Dropdown
              label="Cap"
              id="Cap"
              placeholder="Any"
              options={yesNoOptions}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />
            <Dropdown
              label="Valve-Tronic Plus"
              id="fValveTronicPlus"
              placeholder="Any"
              options={yesNoOptions}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />
            <Dropdown
              label="Purge Orifice"
              id="PurgeOrifice"
              placeholder="Any"
              options={yesNoOptions}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />
          </Stack>

          <Stack>
            <Dropdown
              label="Manual Valve"
              id="ynManualValve"
              placeholder="Any"
              options={yesNoOptions}
              styles={unitDropdownStyles}
              onChange={onDropdownChanged}
            />
          </Stack>
        </Stack>

        <Stack tokens={sectionStackTokens}>
          <Stack>
            <MeterSearchResults
              results={searchResults}
              onSelect={(item: TTblSearchResult) => {
                history.push(`/processing/shopCard/${item['strSerial#']}`)
              }}/>
          </Stack>
        </Stack>

      </Stack>

    </div>
  )
}
