import * as React from 'react'
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom'
import { Stack, IStackTokens } from '@fluentui/react'
import { Navigation } from './components/Navigation'
import { AppBar } from './components/AppBar'
import routes from './routes'
import './App.css'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import Redirect from './containers/Redirect'
import { MsalProvider } from "@azure/msal-react"
import { CustomNavigationClient } from "./utils/NavigationClient";

const headingStackTokens: IStackTokens = { childrenGap: 30 }

const customSpacingStackTokens: IStackTokens = {
  childrenGap: 50,
  padding: '0 5rem 0 0',
}

export const App: React.FunctionComponent <any> = ({ pca }: any) => {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <Router>
      <MsalProvider instance={pca}>
        <Stack>
          <AppBar/>
          <AuthenticatedTemplate>
          <Stack horizontal disableShrink tokens={headingStackTokens}>
            <Stack>
              <Navigation/>
            </Stack>
            <Stack tokens={customSpacingStackTokens} styles={{ root: { height: 'calc(100vh - 50px)', width: 'calc(100% - 280px)', paddingRight: 30, overflow: 'auto' } }}>
              {routes.map(({ path, exact, component }, index) => (
                <Route key={index} exact={exact} path={path} component={component} />
              ))}
            </Stack>
          </Stack>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Redirect/>
          </UnauthenticatedTemplate>
        </Stack>
      </MsalProvider>
    </Router>
  )
}
