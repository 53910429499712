import { PressureUnits } from '../utils/units'

export const pressureConversionFactor = {
  [PressureUnits.DEGREES_HG]: 0.49115,
  [PressureUnits.DEGREES_WCG]: .0361,
  [PressureUnits.ATMOS]: 0,
  [PressureUnits.BAR]: 14.504,
  [PressureUnits.G_PER_CM2]: .0141955,
  [PressureUnits.KG_PER_CM2]:	14.223,
  [PressureUnits.KPA]: .145,
  [PressureUnits.MB]:	.0145,
  [PressureUnits.MBAR]: .0145,
  [PressureUnits.MM_AG]: .00142,
  [PressureUnits.MM_H2O]: .00142,
  [PressureUnits.MM_HG]: .019,
  [PressureUnits.MPA]: 145.0377,
  [PressureUnits.OSIG]: .0625,
  [PressureUnits.PSIA]: 0,
  [PressureUnits.PSIG]: 1, // @TODO VERIFY_IF_VALUE_CORRECT
  [PressureUnits.mmWCG]: .00142
}

export function pressureToPSIG (inputValue: number, inputUnit: PressureUnits) {
  return inputValue * pressureConversionFactor[inputUnit]
}
