import * as React from 'react'
import { CSSProperties, useEffect, useState } from 'react'
import { set } from 'lodash'
import {
  Checkbox,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  FontWeights,
  getTheme,
  IColumn,
  IconButton,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  SelectionMode,
  Stack,
  TextField
} from '@fluentui/react'
import { sectionStackTokens } from '../../../styles'
import ChoiceSize from '../../../components/ChoiceSize'
import DatePicker from '../../../components/DatePicker'
import { useBoolean } from '@uifabric/react-hooks'
import { callAzureFunctionGet, callAzureFunctionPost } from '../../../utils/callAzureFunction'
import { ITblMeterInfo } from '../../../interfaces'
import { PrintShopCardDialog } from '../../processing/ShopCardPage/PrintShopCard'
import { persistMeterInfo } from '../../processing/ShopCardPage/meterInfoUtils'
import { IWeightSearchResult, WeightSearchResults } from './weightSearchResults'
import {formatNum, formatSAE} from '../../../utils/formatters'

const boldText: CSSProperties = { fontWeight: 'bold' }

const modalPropsStyles = { main: { minWidth: 450 } }

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Selection Reports'
}

const theme = getTheme()

const iconButtonStyles = {
  root: { color: theme.palette.neutralPrimary },
  rootHovered: { color: theme.palette.neutralDark }
}

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px'
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden'
  }
})

const searchIcon: IIconProps = { iconName: 'Search' }
const cancelIcon: IIconProps = { iconName: 'Cancel' }

const precision = 4

const AssignWeightsPage: React.FunctionComponent = () => {
  const [calCurveOptions, setCalCurveOptions] = useState([])
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [tfraStrings, setTfraStrings] = useState<string[]>([])
  const [floatStrings, setFloatStrings] = useState<string[]>([])
  const [meters, setMeters] = useState([])
  const [selectedMeter, setSelectedMeter] = useState<ITblMeterInfo|undefined>(undefined)

  const updateMeterAtIndex = (meter: ITblMeterInfo, index: number | undefined) => {
    if (index !== undefined) {
      const newArr = [...meters]
      // @ts-ignore
      newArr[index] = meter
      setMeters(newArr)
    }
  }

  const updateRush = (meter: ITblMeterInfo, rush: boolean, index: number | undefined) => {
    meter.ynRush = rush
    updateMeterAtIndex(meter, index)
    persistMeterInfo(meter, { ynRush: rush })
  }

  const updateMeterFromModal = (modalData: any, index: number) => {
    if (selectedMeter) {
      persistMeterInfo(selectedMeter, {
        numSugTFRA: parseFloat(modalData.numTFRA),
        numSugFloat: parseFloat(modalData.numFloat),
        numWeight: parseFloat(modalData.numWeight)
      })

      selectedMeter.numSugTFRA = parseFloat(modalData.numTFRA)
      selectedMeter.numSugFloat = parseFloat(modalData.numFloat)
      selectedMeter.numWeight = parseFloat(modalData.numWeight)

      updateMeterAtIndex(selectedMeter, index)

      setSimilarMeters([])
      setSelectedMeter(undefined)
    }
  }

  const [similarMeters, setSimilarMeters] = useState([])

  const searchForSimilarMeters = (meter: ITblMeterInfo) => {
    console.log('searchForSimilarMeters', meter["strSerial#"])

    setSelectedMeter(meter)

    callAzureFunctionPost('SelectMetersWithSimilarSae', {
      sae: meter.numSAE,
      size: meter.strMeterSize
    }).then(results => {
      results = results.map((res: IWeightSearchResult) => {
        return {
          numWeight: formatNum(res.numWeight,2),
          numFloat: formatNum(res.numFloat, 2),
          numSAE: formatSAE(res.numSAE),
          numTFRA: formatNum(res.numTFRA, 2),
          strMeterSize: res.strMeterSize
        }
      })
      setSimilarMeters(results)
    })
  }

  const safeNumber = (str: string|undefined): number|undefined => {
    const num = parseFloat(str || '')
    return Number.isFinite(num) ? num : undefined
  }

  const applyMeterChanges = (meter: ITblMeterInfo, index: number, delta: any) => {
    persistMeterInfo(meter, delta)
    for (const key of Object.keys(delta)) {
      set(meter, key, delta[key])
    }
    updateMeterAtIndex(meter, index)
  }

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Rush',
      minWidth: 32,
      maxWidth: 32,
      onRender: (meter: ITblMeterInfo, index: number | undefined) => {
        return <Checkbox
          defaultChecked={meter.ynRush}
          onChange={(ev, checked) => updateRush(meter, !!checked, index)}
        />
      }
    },
    {
      key: 'column2',
      name: 'Serial #',
      minWidth: 100,
      maxWidth: 1000,
      onRender: (item: ITblMeterInfo) => <span>{item["strSerial#"]}</span>
    },
    {
      key: 'column3',
      name: 'Size',
      minWidth: 100,
      maxWidth: 1000,
      onRender: (item: ITblMeterInfo) => <span>{item.strMeterSize}</span>
    },
    {
      key: 'column4',
      name: 'SAE',
      minWidth: 100,
      maxWidth: 1000,
      onRender: (item: ITblMeterInfo) => <span>{formatNum(item.numSAE)}</span>
    },
    {
      key: 'column5',
      name: 'TFRA',
      minWidth: 100,
      maxWidth: 1000,
      onRender: (meter: ITblMeterInfo, index: number | undefined) => {
        if (index !== undefined) {
          return <TextField
            style={boldText}
            type="number"
            value={tfraStrings[index]}
            onChange={(event, newValue) => {
              tfraStrings[index] = newValue || ''
              setTfraStrings(tfraStrings)
              applyMeterChanges(meter, index, { numSugTFRA: safeNumber(newValue) })
            }}
            onBlur={event => {
              tfraStrings[index] = formatNum(parseFloat(tfraStrings[index]), 4)
              setTfraStrings(tfraStrings)

              setTimeout(() => {
                event.target.value = tfraStrings[index]
              }, 0)
            }}
          />
        }
      }
    },
    {
      key: 'column6',
      name: 'Float',
      minWidth: 100,
      maxWidth: 1000,
      onRender: (meter: ITblMeterInfo, index: number | undefined) => {
        if (index !== undefined) {
          return <TextField
            type="number"
            style={boldText}
            value={floatStrings[index]}
            onChange={(event, newValue) => {
              floatStrings[index] = newValue || ''
              setFloatStrings(floatStrings)
              applyMeterChanges(meter, index, { numSugFloat: safeNumber(newValue) })
            }}
            onBlur={event => {
              floatStrings[index] = formatNum(parseFloat(floatStrings[index]), 4)
              setFloatStrings(floatStrings)

              setTimeout(() => {
                event.target.value = floatStrings[index]
              }, 0)
            }}
          />
        }
      }
    },
    {
      key: 'column8',
      name: '',
      minWidth: 80,
      maxWidth: 80,
      onRender: (meter: ITblMeterInfo) => {
        return <Stack horizontal={true}>
          <PrintShopCardDialog
            meterInfo={meter}
            calCurveOptions={calCurveOptions}
            condensed={true}
            label="Print Shop Card"
          />
          <IconButton
            styles={iconButtonStyles}
            iconProps={searchIcon}
            onClick={() => searchForSimilarMeters(meter)}
          />
          {similarMeters.length > 0 && <Modal isOpen={true} isBlocking={true} containerClassName={contentStyles.container} isModeless={true}>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close modal popup"
              onClick={() => setSimilarMeters([])}
            />

            <WeightSearchResults
              results={similarMeters}
              onSelect={(item: any, index: number) => updateMeterFromModal(item, index)}
            />
          </Modal>}
        </Stack>
      }
    }
  ]

  useEffect(() => {
    callAzureFunctionGet('SelectCalCurveOptions')
      .then(setCalCurveOptions)
      .then(() => callAzureFunctionGet('SelectMetersToAssignWeights'))
      .then(meters => {
        const tfraStrings = meters.map((meter: ITblMeterInfo) => formatNum(meter.numSugTFRA, precision))
        const floatStrings = meters.map((meter: ITblMeterInfo) => formatNum(meter.numSugFloat, precision))
        setMeters(meters)
        setTfraStrings(tfraStrings)
        setFloatStrings(floatStrings)
      })
  }, [setMeters, setCalCurveOptions])

  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: modalPropsStyles
    }), [])

  return (
    <div>

      <h3>Meter Specifications</h3>

      <Stack tokens={sectionStackTokens}>

        {/*<Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>*/}
        {/*  <DefaultButton text="Print Shop Cards" allowDisabledFocus/>*/}
        {/*  <DefaultButton text="Print Selection Charts" onClick={toggleHideDialog} allowDisabledFocus/>*/}
        {/*  <DefaultButton text="Refresh" allowDisabledFocus/>*/}
        {/*</Stack>*/}

        <Stack>
          <DetailsList
            items={meters}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </Stack>

      </Stack>

      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <ChoiceSize/>
        <DatePicker label="Date"/>
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="Print"/>
          <DefaultButton onClick={toggleHideDialog} text="Cancel"/>
        </DialogFooter>
      </Dialog>

    </div>
  )
}

export default AssignWeightsPage
