import {
  IGasType,
  IGasTypeDropdownOption,
  IMeterSize,
  IMeterSizeDropdownOption,
  IFloMeterParameters, ITblMeterInfo
} from '../interfaces'
import { callAzureFunctionGet, callAzureFunctionPost } from './callAzureFunction'
import { isString, isUndefined } from 'lodash'
import { ITag } from '@fluentui/react'
import { SAECalculator } from '../calculations/calculate_sae'
import { lookupAllMatchingNeedles } from './lookup_needle_size'
import { unifyScaleUnits, unifyTemperatureUnit } from './constants'

const mapGasType = (type: IGasType): IGasTypeDropdownOption => ({ key: type.autonum, text: type.strGasName, data: type })

export const mapMeterSizeAsDropdownOption = (size: IMeterSize): IMeterSizeDropdownOption => ({ key: size.strMeterSize, text: size.strMeterSize, data: size })

export async function selectMeterSizes (meterParameters: IFloMeterParameters|undefined = undefined, calc: SAECalculator|undefined = undefined): Promise<IMeterSizeDropdownOption[]> {
  let params

  const sae = calc ? calc.sae : undefined

  if (isUndefined(meterParameters)) {
    params = {}
  } else if (sae !== undefined && sae > 0) {
    params = { sae, ...meterParameters }
  } else {
    params = meterParameters
  }

  if (calc?.gasType?.key === 23) {
    params = { ...params, liquid: true } // Methanol
  } else if (calc?.gasType?.key === 34) {
    params = { ...params, liquid: true } // Water
  }

  const sizes = await callAzureFunctionGet('SelectMeterSizes', params) as IMeterSize[]

  if (calc && sizes.length === 0) {
    const needles = await lookupAllMatchingNeedles(calc)

    // MTypes = 'LPX-21 LPX-22 ....'
    const sizes1 = needles.map(n => (n.MTypes || '').split(' ')).flat()

    // extract unique meter names
    const params2: any = { sizes: Array.from(new Set(sizes1)) }

    if (sae !== undefined && sae > 0) {
      params2.sae = sae
    }

    if (params.liquid) {
      params2.liquid = params.liquid
    }

    const foundSizes = await callAzureFunctionPost('SelectMeterSizes', params2) as IMeterSize[]

    for (const s of foundSizes) {
      sizes.push(s)
    }
  }

  const uniqueNames = new Set()
  const uniqueSizes = []

  for (const s of sizes) {
    if (!uniqueNames.has(s.strMeterSize)) {
      uniqueSizes.push(s)
      uniqueNames.add(s.strMeterSize)
    }
  }

  return uniqueSizes.map(mapMeterSizeAsDropdownOption)
}

export function selectGasTypes () {
  return callAzureFunctionGet('SelectGasTypes').then(types => types.map(mapGasType))
}

export function searchMeterInfoBySerialNumber (sn: string): Promise<ITag[]> {
  return callAzureFunctionGet('SearchMeterInfo', { sn })
}

export function searchMeters (conditions: object): Promise<ITblMeterInfo[]> {
  return callAzureFunctionPost('SearchMeters', conditions)
}

export function selectMeterInfo (sn?: string, autonum?: number): Promise<ITblMeterInfo> {
  return callAzureFunctionGet('SelectMeterInfo', autonum ? { autonum } : { sn })
    .then(info => {
      if (isString(info.datCalB)) {
        info.datCalB = new Date(info.datCalB)
      }
      if (isString(info.datShipDate)) {
        info.datShipDate = new Date(info.datShipDate)
      }
      if (isString(info.strEntryDate)) {
        info.strEntryDate = new Date(info.strEntryDate)
      }
      if (isString(info.strCalDate)) {
        info.strCalDate = new Date(info.strCalDate)
      }
      unifyTemperatureUnit(info)
      unifyScaleUnits(info)
      return info
    })
}

export function insertMeterInfo (dat: object): Promise<any> {
  return callAzureFunctionPost('CreateMeterInfo', dat)
}

export function createNewMeterSize (meterSize: IMeterSize): Promise<IMeterSize> {
  return callAzureFunctionPost('CreateNewMeterSize', meterSize)
    .then((resp: any) => ({ ...meterSize, autonum: resp.numAuto }))
}

type TAbasAddressData = {
  nummer: string
  ans: string // Name
  ans2: string // Ship-to name
  str: string // Street
  str2: string // Ship-to street
  nort: string // City
  nort2: string // Ship-to city
  "region^kenn": string // State
  "region2^kenn": string // Ship-to state
  "staat^iso3": string // Country (ISO3 code)
  "staat2^iso3": string  // Ship-to country (ISO3 code)
  plz: string // Postal or ZIP code
  plz2: string // Ship-to postal or ZIP code
}

export type TAbasCriteria = {
  salesOrder?: string
  clientId?: string
  clientName?: string
}

export function getAbasCustomerData (criteria: TAbasCriteria): Promise<{customer: TAbasAddressData|null, order: TAbasAddressData|null}> {
  return callAzureFunctionGet('GetAbasData', criteria)
}
