import { IDropdownOption, IComboBoxOption } from '@fluentui/react'
import { flowMultiplicationFactor } from './calculate_flow'
import { pressureToPSIG } from './calculate_psig'
import { PressureUnits, TemperatureUnits } from '../utils/units'
import { celsiusToFahrenheit } from './calculate_temperature'
import { ISaeCalculatorInput, ISAeCalculatorState } from '../interfaces'
import { formatNum, formatSG } from '../utils/formatters'

const SAE_PRECISION = 2

const DEBUG = false

export class SAECalculator {
  private readonly _name: string

  private _state: ISAeCalculatorState = {
    pressure: undefined,
    pressureUnit: undefined,
    scale: undefined,
    scaleUnit: undefined,
    temperature: undefined,
    temperatureUnit: undefined,
    gasType: undefined,
    gasSpecificGravity: 0,
    gasSpecificGravityAsString: '',
    userProvidedSpecificGravity: '',
    sae: -1,
    saeAsString: ''
  }

  private _updateCallback: Function|undefined = undefined

  constructor (name: string) {
    this._name = name
    this._log(`new SAE Calculator [${name}]`)
  }

  resetState () {
    this._state.pressure = undefined
    this._state.pressureUnit = undefined
    this._state.scale = undefined
    this._state.scaleUnit = undefined
    this._state.temperature = undefined
    this._state.temperatureUnit = undefined
    this._state.gasType = undefined
    this._state.gasSpecificGravity = 0
    this._state.gasSpecificGravityAsString = ''
    this._state.sae = -1
    this._state.saeAsString = ''
    this._log('did reset state')
  }

  setUpdateCallback (callback: Function | undefined) {
    if (this._updateCallback !== callback) {
      this._updateCallback = callback
    }
  }

  formatSAEAsString (sae: number) {
    return sae > 0 ? formatNum(sae, SAE_PRECISION) : ''
  }

  private _setSae (sae: number) {
    this._log(`setSae ${sae}`)
    this._state.sae = sae
    this._state.saeAsString = this.formatSAEAsString(sae)

    if (this._updateCallback) {
      this._updateCallback()
    }
  }

  private _log (what: any) {
    if (DEBUG) {
      console.log(this._name, what)
    }
  }

  private _markAsInvalid (reason: string) {
    this._log(`markAsInvalid [${reason}]`)
    return this._setSae(-1)
  }

  get gasType () {
    return this._state.gasType
  }

  getState () {
    return this._state
  }

  canEditSpecificGravity () {
    return this._state.gasType?.key === '25' || this._state.gasType?.key === 25
  }

  getInletPressurePSIG () {
    if (this._state && this._state.pressure && this._state.pressureUnit && this._state.pressureUnit.key) {
      return pressureToPSIG(parseFloat(this._state.pressure), this._state.pressureUnit?.key as PressureUnits)
    } else {
      return NaN
    }
  }

  private _invalidate () {
    if (!this._state.pressure) {
      return this._markAsInvalid('pressure invalid')
    } else if (!this._state.pressureUnit?.key) {
      return this._markAsInvalid('no pressure unit')
    } else if (!this._state.scale) {
      return this._markAsInvalid('no scale')
    } else if (!this._state.scaleUnit?.key) {
      return this._markAsInvalid('no scale unit')
    } else if (!this._state.temperature) {
      return this._markAsInvalid('no temperature')
    } else if (!this._state.temperatureUnit?.key) {
      return this._markAsInvalid('no temperature unit')
    } else if (!this._state.gasType?.data) {
      return this._markAsInvalid('no gas type')
    }

    // Maximum Scale flow rate of the Flo-Meter in CFH
    // @ts-ignore
    const flowScale = parseFloat(this._state.scale) * flowMultiplicationFactor[this._state.scaleUnit?.key]
    if (isNaN(flowScale)) {
      return this._markAsInvalid('flowScale invalid')
    }

    // Inlet Pressure in PSIG
    // @ts-ignore
    // const inletPressure = pressureToPSIG(parseFloat(this._state.pressure), this._state.pressureUnit?.key)
    const inletPressure = this.getInletPressurePSIG()
    if (isNaN(inletPressure)) {
      return this._markAsInvalid('inlet pressure invalid')
    }

    let inletTemperature = parseFloat(this._state.temperature || '')
    if (this._state.temperatureUnit?.key === TemperatureUnits.CELSIUS) {
      inletTemperature = celsiusToFahrenheit(inletTemperature)
    }

    if (isNaN(inletTemperature)) {
      return this._markAsInvalid('inlet temperature invalid')
    }

    if (isNaN(this._state.gasSpecificGravity)) {
      return this._markAsInvalid('invalid specific gravity')
    }

    const sae = calculateSAE(flowScale, inletPressure, inletTemperature, this._state.gasSpecificGravity)
    this._log({ flowScale, inletPressure, inletTemperature, sg: this._state.gasSpecificGravity, sae })

    if (isNaN(sae)) {
      this._markAsInvalid('invalid calculated SAE')
    } else {
      this._setSae(sae)
    }
  }

  setScale (value: string|undefined) {
    this._state.scale = value
    this._log(`setScale ${value}`)
    this._invalidate()
  }

  setScaleUnit (option: IDropdownOption|IComboBoxOption|undefined) {
    this._state.scaleUnit = option
    this._log(`setScaleUnit ${option}`)
    this._invalidate()
  }

  setPressure (value: string|undefined) {
    this._state.pressure = value
    this._log(`setPressure ${value}`)
    this._invalidate()
  }

  setUserProvidedSpecificGravity (value: string|undefined) {
    this._state.userProvidedSpecificGravity = value || ''
    this._log(`setSpecificGravity ${value}`)
    this._updateGasTypeProperties()
    this._invalidate()
  }

  setPressureUnit (option: IDropdownOption|IComboBoxOption|undefined) {
    this._state.pressureUnit = option
    this._log(`setPressureUnit ${option}`)
    this._invalidate()
  }

  setTemperature (value: string|undefined) {
    this._state.temperature = value
    this._log(`setTemperature ${value}`)
    this._invalidate()
  }

  setTemperatureUnit (option: IDropdownOption|IComboBoxOption|undefined) {
    this._state.temperatureUnit = option
    this._log(`setTemperatureUnit ${option}`)
    this._invalidate()
  }

  _updateGasTypeProperties () {
    if (this._state.userProvidedSpecificGravity) {
      this._state.gasSpecificGravity = parseFloat(this._state.userProvidedSpecificGravity)
      this._state.gasSpecificGravityAsString = formatSG(this._state.gasSpecificGravity)
    } else if (this._state.gasType) {
      this._state.gasSpecificGravity = this._state.gasType.data.numSG
      this._state.gasSpecificGravityAsString = formatSG(this._state.gasType.data.numSG)
    } else {
      this._state.gasSpecificGravity = NaN
      this._state.gasSpecificGravityAsString = ''
    }
  }

  setGasType (option: IDropdownOption|IComboBoxOption|undefined) {
    this._state.gasType = option
    this._updateGasTypeProperties()
    this._invalidate()
  }

  updateFromState (state: ISaeCalculatorInput) {
    this._state.scale = state.scale
    this._state.scaleUnit = state.scaleUnit
    this._state.temperature = state.temperature
    this._state.temperatureUnit = state.temperatureUnit
    this._state.pressure = state.pressure
    this._state.pressureUnit = state.pressureUnit
    this._state.gasType = state.gasType
    this._updateGasTypeProperties()
    this._log('state after update ' + JSON.stringify(this._state))
    this._invalidate()
  }

  isSufficientlyFilled () {
    return this._state.sae > 0
  }

  get specificGravity () {
    return this._state.gasSpecificGravity
  }

  get specificGravityAsString () {
    return this._state.gasSpecificGravityAsString
  }

  get sae () {
    return this._state.sae
  }

  get saeAsString () {
    return this._state.saeAsString
  }

  getNotesComponents (dualScale: number|undefined, dualScaleUnit: string|undefined) {
    const components: string[] = []

    if (this._state.sae > 0) {
      if (this._state.gasType) {
        components.push(`${this._state.gasType?.text} (SG=${this.specificGravityAsString})`)
      }
      components.push(`Scale ${this._state.scale} ${this._state.scaleUnit?.text}`)
      if (dualScale && dualScaleUnit) {
        components.push(`Dual scale ${dualScale} ${dualScaleUnit}`)
      }
      components.push(`Pressure: ${this._state.pressure} ${this._state.pressureUnit?.text}`)
      components.push(`Temperature ${this._state.temperature} ${this._state.temperatureUnit?.text}`)
      components.push(`Inlet conditions: SAE ${this.saeAsString}`)
    }

    return components
  }
}

export function calculateSAE (scale: number, pressurePsig: number, inletTemperatureFahrenheit: number, specificGasGravity: number) {
  return scale * Math.sqrt(14.7 / (14.7 + pressurePsig)) * Math.sqrt((460 + inletTemperatureFahrenheit) / 530) * Math.sqrt(specificGasGravity)
}

export function calculateCAE (sae: number, calibrationInletPressureWcg: number, calibrationInletTemperatureFahrenheit: number) {
  return sae * Math.sqrt((406.89 + calibrationInletPressureWcg) / 406.89) * Math.sqrt(530 / (460 + calibrationInletTemperatureFahrenheit))
}
