import { ITblMeterInfo } from '../interfaces'
import { percents } from '../containers/processing/CalibrationPage'

export default function isMeterCalibrated (meter: ITblMeterInfo | undefined| null) {
  if (!meter) {
    return false
  }

  let numCalibrations = 0

  for (const p of percents) {
    const key = `num${p}%` as keyof ITblMeterInfo
    if (meter[key]) {
      numCalibrations++
    }
  }

  return numCalibrations >= 3 &&
    !!meter.numFloat &&
    !!meter.numWeight &&
    !!meter.numTFRA &&
    !!meter.numCountWeight &&
    !!meter.strCalibrator &&
    !!meter.strCalDate
}
