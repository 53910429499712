import * as React from 'react'
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react'

const options: IChoiceGroupOption[] = [
  {
    key: 'small',
    text: 'Small',
  },
  {
    key: 'medium',
    text: 'Medium',
  },
  {
    key: 'large',
    text: 'Large',
  }
]

const ChoiceSize: React.FunctionComponent = () => {
  return <ChoiceGroup label="Pick meter size" defaultSelectedKey="small" options={options}/>
}

export default ChoiceSize
