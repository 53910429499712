import * as React from 'react'
import {
  DefaultButton,
  Stack,
  Modal,
  IconButton,
  IIconProps,
  Dropdown,
  TextField,
  getTheme,
  mergeStyleSets
} from '@fluentui/react'
import { dropdownStyles, rowSpacingStackTokens, sectionStackTokens } from '../../../../styles'
import Table from './table'
import { useId, useBoolean } from '@uifabric/react-hooks'
import { IMeterSizeDropdownOption } from '../../../../interfaces'

const theme = getTheme()

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
})
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }

interface IAddNotesDialogProps {
  meterSizes: IMeterSizeDropdownOption[]
}

const AddNotesDialog = (props: IAddNotesDialogProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title')

  return (
    <div>
      <DefaultButton text="Add Notes" onClick={showModal} disabled={true} />
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Meter Notes Form</span>

          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close modal popup"
            onClick={hideModal}
          />
        </div>

        <div className={contentStyles.body}>

          <Stack tokens={sectionStackTokens}>

            <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
              <Dropdown
                label="Find Meter"
                options={props.meterSizes}
                styles={dropdownStyles}
              />
              <TextField label="Serial#" />
            </Stack>

            <Stack>
              <Table/>
            </Stack>

          </Stack>
        </div>
      </Modal>
    </div>
  )
}

export default AddNotesDialog
