import * as React from 'react'
import { DatePicker, DayOfWeek, IDatePicker, IDatePickerStrings, mergeStyleSets } from '@fluentui/react'

const DayPickerStrings: IDatePickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
  closeButtonAriaLabel: 'Close date picker',
  monthPickerHeaderAriaLabel: '{0}, select to change the year',
  yearPickerHeaderAriaLabel: '{0}, select to change the month',

  isRequiredErrorMessage: 'Start date is required.',

  invalidInputErrorMessage: 'Invalid date format.',
}

const controlClass = mergeStyleSets({
  control: {
    maxWidth: '300px',
  },
})

const onFormatDate = (date?: Date): string => {
  return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100)
}

const desc = 'This field is required. One of the support input formats is year dash month dash day.'
const firstDayOfWeek = DayOfWeek.Sunday

interface IProps {
  label?: string
}

const DatePickerComponent: React.FC <IProps> = ({ label }: IProps) => {
  const [value, setValue] = React.useState<Date | null | undefined>(null)
  const datePickerRef = React.useRef<IDatePicker>(null)

  const onSelectDate = (date: Date | null | undefined): void => {
    setValue(date)
  }

  const onParseDateFromString = (val: string): Date => {
    const date = value || new Date()
    const values = (val || '').trim().split('/')
    const day = val.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate()
    const month = val.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth()
    let year = val.length > 2 ? parseInt(values[2], 10) : date.getFullYear()
    if (year < 100) {
      year += date.getFullYear() - (date.getFullYear() % 100)
    }
    return new Date(year, month, day)
  }

  return (
      <DatePicker
        componentRef={datePickerRef}
        className={controlClass.control}
        label={label}
        isRequired={false}
        allowTextInput={true}
        ariaLabel={desc}
        firstDayOfWeek={firstDayOfWeek}
        strings={DayPickerStrings}
        value={value!}
        // eslint-disable-next-line react/jsx-no-bind
        onSelectDate={onSelectDate}
        formatDate={onFormatDate}
        // eslint-disable-next-line react/jsx-no-bind
        parseDateFromString={onParseDateFromString}
      />
  )
}

export default DatePickerComponent
