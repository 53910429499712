import {
  IDropdownStyles,
  ITextFieldStyles,
  IStackStyles,
  IStackTokens, ISeparatorStyles, IComboBoxStyles
} from '@fluentui/react'

export const sectionStackTokens: IStackTokens = { childrenGap: 10 }

export const headingStackTokens: IStackTokens = { childrenGap: 30 }

export const rowSpacingStackTokens: IStackTokens = { childrenGap: 25 }

export const columnSpacingStackTokens: IStackTokens = { childrenGap: 50 }

export const stackStyles: Partial<IStackStyles> = { root: { width: '100%' } }

export const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 200 } }

export const comboBoxStyles: Partial<IComboBoxStyles> = {
  optionsContainerWrapper: { maxHeight: 500 }
}

export const unitDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 100 } }

export const seaTextFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: 150 } }

export const textFieldStyles: Partial<ITextFieldStyles> = {root: { padding: '10px 0 0 0' }}

export const separatorStyles: Partial<ISeparatorStyles> = { root: [{ selectors: { '::before': {} } }] }
