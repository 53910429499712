import React from 'react'
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Scatter,
  ResponsiveContainer
} from 'recharts'

interface ICalibrationChartProps {
  data?: any[]
}

const DotShape = (props: any): JSX.Element => {
  return props.cx && props.cy && <circle {...props} r={2}/>
}

export function Chart (props: ICalibrationChartProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={props.data}
        margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
      >
        <CartesianGrid stroke="#a8a8a8"/>

        <XAxis
          dataKey="percentage"
          type="number"
          tickCount={21}
          tickLine={false}
          tickFormatter={v => v % 10 === 0 ? v : ''}
          domain={[0, 100]}
          label={{ value: '% Flow', position: 'bottom', offset: 0 }}
        />

        <YAxis
          unit=""
          tickCount={10}
          tickLine={false}
          tickFormatter={v => v % 10 === 0 ? v : ''}
          domain={[-60,60]}
          type="number"
          label={{ value: '% Error', angle: -90, position: 'insideLeft' }}
        />

        <Scatter
          name="readError"
          dataKey="readError"
          fill="black"
          shape={DotShape}
          isAnimationActive={false}
        />

        <Line
          dataKey="permissibleErrorPlus"
          isAnimationActive={false}
          stroke="black"
          type="monotone"
          dot={false}
          activeDot={false}
          legendType="none"
        />

        <Line
          dataKey="permissibleErrorMinus"
          isAnimationActive={false}
          stroke="black"
          type="monotone"
          dot={false}
          activeDot={false}
          legendType="none"
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
