import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  ITag,
  SelectionMode,
  Stack,
  TagPicker,
  TooltipHost
} from '@fluentui/react'
import { searchMeterInfoBySerialNumber } from '../../../utils/azure_function_calls'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { callAzureFunctionGet } from '../../../utils/callAzureFunction'
import map from 'lodash/map'
import { sectionStackTokens } from '../../../styles'
import { meterInfoLabels } from '../../../utils/constants'
import { ITblMeterInfo } from '../../../interfaces'

export default function MeterHistoryPage () {
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<ITag[]>([])
  const [changes, setChanges] = useState([])
  const [hasMoreChanges, setHasMoreChanges] = useState(false)
  const [from, setFrom] = useState(0)

  let limit = 25

  const columns: IColumn[] = [
    {
      key: 'Date',
      name: 'Date',
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: any) => <span>{(new Date(item.timestamp * 1000)).toLocaleString()}</span>
    },
    {
      key: 'Changes',
      name: 'Changes',
      minWidth: 300,
      onRender: (item: any) => (
        <Stack tokens={sectionStackTokens}>{map(JSON.parse(item.changes), (value, key: keyof ITblMeterInfo) => (
          <p className="ma-0" key={key}>{'Changed '}<b>{meterInfoLabels[key]}</b>{' to '}<b>{value}</b></p>
        ))}</Stack>
      )
    },
    {
      key: 'User',
      name: 'User',
      minWidth: 300,
      onRender: (item: any) => (
        <TooltipHost
          content={item.userID}
          id={item.changeID}
        >
          <span>{item.userName}</span>
        </TooltipHost>
      )
    }
  ]

  const loadChanges = (append = false) => {
    if (!selectedSerialNumbers.length) {
      return
    }

    const sn = selectedSerialNumbers[0].name
    callAzureFunctionGet('SelectMeterChanges', { sn, from, limit })
      .then(loaded => {
        setChanges(append ? changes.concat(loaded) : loaded)
        setHasMoreChanges(loaded.length >= limit)
      })
  }

  const onSerialNumberSelected = (items: ITag[]|undefined) => {
    if (!items?.length) {
      setSelectedSerialNumbers([])
    } else {
      setSelectedSerialNumbers([items[0]])
    }
  }

  const loadMoreChanges = () => {
    setFrom(from + limit)
    loadChanges(true)
  }

  useEffect(() => {
    if (selectedSerialNumbers.length) {
      setFrom(from + limit)
      loadChanges()
    } else {
      setFrom(0)
      setHasMoreChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSerialNumbers])

  return <Stack>
    <h3>Meter Changes</h3>

    <Stack horizontal>
      <TagPicker
        itemLimit={1}
        inputProps={{ placeholder: 'Find S/N' }}
        selectedItems={selectedSerialNumbers}
        onChange={items => onSerialNumberSelected(items)}
        onResolveSuggestions={searchMeterInfoBySerialNumber}
        resolveDelay={200}
      />
    </Stack>

    {changes.length && <DetailsList
      items={changes}
      compact={false}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
    />}

    {hasMoreChanges && <Stack>
      <p style={{textAlign: 'center'}}>
        <DefaultButton onClick={loadMoreChanges}>Load more</DefaultButton>
      </p>
    </Stack>}
  </Stack>
}
