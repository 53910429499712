import * as React from 'react'
import { Stack, Text } from '@fluentui/react'
import { sectionStackTokens, headingStackTokens } from '../../styles'

export default function NoAccessPage () {

  return (
    <Stack tokens={sectionStackTokens}>
      <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={headingStackTokens}>
        <Text>no access rights</Text>
      </Stack>
    </Stack>
  )
}
