import { ScaleUnits } from '../utils/units'

export const flowMultiplicationFactor = {
  [ScaleUnits.CFH]: 1,
  [ScaleUnits.CFM]: 60,
  [ScaleUnits.CMH]: 35.315,
  [ScaleUnits.GPH]: .13368,
  [ScaleUnits.GPM]: 8.0208,
  [ScaleUnits.KG_CM2]: 14.2173,
  [ScaleUnits.L_H]: .03531,
  [ScaleUnits.L_MIN]: 2.1186,
  [ScaleUnits.M3_H]: 35.315,
  [ScaleUnits.M3_MIN]: 2118.88,
  [ScaleUnits.PPH]: 21.74
}

export function calculateFlow (fromValue: number, fromUnit: ScaleUnits, toUnit: ScaleUnits) {
  // @ts-ignore
  const f1 = flowMultiplicationFactor[fromUnit]

  // @ts-ignore
  const f2 = flowMultiplicationFactor[toUnit]

  const flowInCfh = fromValue / f1

  // console.log({ fromValue, fromUnit, toUnit, f1, f2, flowInCfh })

  // @ts-ignore
  return flowInCfh * f2
}
