import * as React from 'react'
import {
  DetailsList, IColumn, SelectionMode
} from '@fluentui/react'

export interface IWeightSearchResult {
  numFloat: number,
  numSAE: number,
  numTFRA: number,
  numWeight: number,
  strMeterSize: string
}

export interface IWeightSearchResultsProps {
  results: IWeightSearchResult[],
  onSelect: Function
}

export function WeightSearchResults (props: IWeightSearchResultsProps) {
  const columns: IColumn[] = [
    {
      key: 'column5',
      name: 'Meter Size',
      minWidth: 100,
      maxWidth: 1000,
      fieldName: 'strMeterSize'
    },
    {
      key: 'column2',
      name: 'SAE',
      minWidth: 100,
      maxWidth: 1000,
      fieldName: 'numSAE'
    },
    {
      key: 'column1',
      name: 'Float',
      minWidth: 32,
      maxWidth: 32,
      fieldName: 'numFloat'
    },
    {
      key: 'column3',
      name: 'TFRA',
      minWidth: 100,
      maxWidth: 1000,
      fieldName: 'numTFRA'
    },
    {
      key: 'column4',
      name: 'Weight',
      minWidth: 100,
      maxWidth: 1000,
      fieldName: 'numWeight'
    }
  ]

  return <DetailsList
    selectionMode={SelectionMode.none}
    items={props.results} columns={columns}
    onActiveItemChanged={item => props.onSelect(item)}
    compact={true}
  />
}
