import * as React from 'react'
import * as ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import { mergeStyles } from '@fluentui/react'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import reportWebVitals from './reportWebVitals'
// MSAL imports
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { msalConfig } from './authConfig'

export const msalInstance = new PublicClientApplication(msalConfig)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
})
initializeIcons(/* optional base url */)

ReactDOM.render(
  <React.StrictMode>
    <App pca={msalInstance}/>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
