export enum PressureUnits {
  // DEGREES_WCG = '°WCG',
  // DEGREES_HG = '°HG',
  DEGREES_WCG = '"WCG',
  DEGREES_HG = '"HG',
  ATMOS = 'Atmos',
  BAR = 'Bar',
  G_PER_CM2 = 'g/cm2',
  KG_PER_CM2 = 'kg/cm2',
  KPA = 'KPA',
  MB = 'mb',
  MBAR = 'mbar',
  MM_AG = 'mmAg',
  MM_H2O = 'mmH2O',
  MM_HG = 'mmHg',
  mmWCG = 'mmWCG',
  MPA = 'MPa',
  OSIG = 'Osig',
  PSIA = 'Psia',
  PSIG = 'Psig'
}

export enum ScaleUnits {
  CFH = 'CFH',
  CFM = 'CFM',
  CMH = 'CMH',
  GPH = 'GPH',
  GPM = 'GPM',
  L_H = 'l/h',
  L_MIN = 'l/min',
  M3_H = 'm3/h',
  M3_MIN = 'm3/min',
  PPH = 'PPH',
  CC_MIN = 'cc/min',
  KG_CM2 = 'kg/cm2'
}

export enum TemperatureUnits {
  CELSIUS = '°C',
  FAHRENHEIT = '°F'
}
