import * as React from 'react'
import { TextField, Dropdown, Label, Stack, Checkbox, ComboBox } from '@fluentui/react'
import { units } from '../../../utils/constants'
import {
  rowSpacingStackTokens,
  sectionStackTokens,
  stackStyles,
  unitDropdownStyles, columnSpacingStackTokens
} from '../../../styles'
import { useEffect, useRef, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { selectGasTypes } from '../../../utils/azure_function_calls'
import { SAECalculator } from '../../../calculations/calculate_sae'
import GasTypeSelector from '../../../components/GasTypeSelector'

const USE_DROPDOWNS = false

export default function SeaCalculatorPage () {
  const calc = useRef(new SAECalculator('SaeCalculatorPage')).current
  const { inProgress } = useMsal()

  const [saeString, setSaeString] = useState('')
  const [gasTypes, setGasTypes] = useState([])
  const [specificGravity, setSpecificGravity] = useState('')

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      // fetch gas (tab) types and meter sizes on page enter
      selectGasTypes().then(setGasTypes)
    }

    calc.setUpdateCallback(() => {
      if (specificGravity !== calc.specificGravityAsString) {
        setSpecificGravity(calc.specificGravityAsString)
      }

      if (saeString !== calc.saeAsString) {
        setSaeString(calc.saeAsString)
      }
    })

    return () => {
      // component unmount
      calc.setUpdateCallback(undefined)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress])

  return (
    <div>

      <h3>SAE Calculator</h3>

      <Stack tokens={sectionStackTokens}>

        <Stack horizontal verticalAlign="start" tokens={columnSpacingStackTokens}>
          <Label>Meter Specification</Label>
          <Stack className="group-box">
            <TextField label="SAE" readOnly value={saeString} />
          </Stack>
        </Stack>

        <Stack horizontal wrap tokens={columnSpacingStackTokens}>
          <Stack>
            <Stack styles={stackStyles}>
              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Scale"
                  onChange={(event, newValue) => calc.setScale(newValue)}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.scale}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setScaleUnit(option)}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.scale}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setScaleUnit(option)}
                />}

              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Pressure"
                  onChange={(event, newValue) => calc.setPressure(newValue)}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.pressure}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setPressureUnit(option)}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.pressure}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setPressureUnit(option)}
                />}
              </Stack>

              <Stack horizontal verticalAlign="end" tokens={rowSpacingStackTokens}>
                <TextField
                  label="Temperature"
                  onChange={(event, newValue) => calc.setTemperature(newValue)}
                />

                {USE_DROPDOWNS && <Dropdown
                  label="Unit"
                  options={units.temperature}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setTemperatureUnit(option)}
                />}

                {!USE_DROPDOWNS && <ComboBox
                  openOnKeyboardFocus={true}
                  label="Unit"
                  options={units.temperature}
                  styles={unitDropdownStyles}
                  onChange={(event, option) => calc.setTemperatureUnit(option)}
                />}
              </Stack>

              <Label>Select a tab</Label>

              <GasTypeSelector gasTypes={gasTypes} calc={calc} />

              {/*<Dropdown*/}
              {/*  placeholder="Select a tab"*/}
              {/*  label="Tab"*/}
              {/*  options={gasTypes}*/}
              {/*  onChange={(event, option) => setGasType(option)}*/}
              {/*  styles={dropdownStyles}*/}
              {/*/>*/}
            </Stack>
          </Stack>

          <Stack tokens={{ padding: '29px 0 0 0', ...rowSpacingStackTokens }} verticalAlign="space-between">
            <Checkbox label='Liquid' />
            <TextField label="Specific Gravity" value={specificGravity} readOnly/>
          </Stack>
        </Stack>

      </Stack>

    </div>
  )
}
