import * as React from 'react'
import {
  DefaultButton,
  getTheme,
  IconButton,
  IIconProps,
  Image,
  IStackStyles,
  mergeStyleSets,
  Modal
} from '@fluentui/react'
import { useBoolean, useId } from '@uifabric/react-hooks'
import { iTblLookupCalFactor, ITblMeterInfo } from '../../../../interfaces'
import ReactToPrint from 'react-to-print'
import { persistMeterInfo } from '../../ShopCardPage/meterInfoUtils'
import { Chart } from './chart'
import './index.css'
import { get, isUndefined } from 'lodash'
import { formatDate, formatNum } from '../../../../utils/formatters'
import { percents, permissibleErrors } from '../index'
import waukee_engineering from '../../../../assets/images/waukee_engineering.jpg'
import united_process_controls from '../../../../assets/images/united_process_controls.jpg'
import calibration from '../../../../assets/images/calibration.jpg'
import { unifyScaleUnits, unifyTemperatureUnit } from '../../../../utils/constants'
import { PressureUnits, TemperatureUnits } from '../../../../utils/units'

const theme = getTheme()

const showAccreditation = false

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  header: [
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      justifyContent: 'flex-end'
    }
  ]
})

const imageStyles: Partial<IStackStyles> = {
  root: {
    display: 'flex',
    justifyContent: 'center'
  }
}

const iconButtonStyles = {
  root: { color: theme.palette.neutralPrimary },
  rootHovered: { color: theme.palette.neutralDark }
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }
const printerIcon: IIconProps = { iconName: 'Print' }

interface IPrintCalibrationDialogProps {
  meterInfo: ITblMeterInfo | null
  selectedMeterSeries: string | number
  testMeters: iTblLookupCalFactor[]
  calCurveOptions?: any[]
  disabled?: boolean
}

export function PrintCalibrationDialog (props: IPrintCalibrationDialogProps) {
  if (props.meterInfo) {
    unifyTemperatureUnit(props.meterInfo)
    unifyScaleUnits(props.meterInfo)
  }

  const titleId = useId('title')
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)
  let elementToPrint: any = null

  const testMeter = props.testMeters.find(meter => meter.numTestMeter === props.meterInfo?.numTestMeter)

  const formatError = (err: number|undefined, digits = 3): string => {
    const str = formatNum(err, digits)
    return str === '' ? str : `${str} %`
  }

  const getScaleUnits = (percent: number) => {
    let val
    if (props.meterInfo?.numTestMeter && props.meterInfo?.numTestMeter > 11000) {
      val = props.meterInfo?.numCAE || 0
    } else {
      val = props.meterInfo?.numScale || 0
    }

    return val * percent / 100
  }

  const testProcedure = ['Water', 'Methane'].includes(props.meterInfo?.strGas || '') ? '5017P' : '5003P'
  const testGas = props.meterInfo?.ynLiquid ? 'Water' : 'Air'

  const leftColClass = 'column w-40'
  const rightColClass = 'column input w-60'

  const dataAscendingOrder = percents.map((percentage, index) => {
    let readError = undefined

    const key = `num${percentage}%`
    const postCalUnit = get(props.meterInfo, key, undefined)
    // @ts-ignore
    const permissibleErrorPlus = permissibleErrors[props.selectedMeterSeries][index]
    const permissibleErrorMinus = -permissibleErrorPlus

    if (postCalUnit !== null && !isUndefined(postCalUnit) && postCalUnit !== '') {
      const units = getScaleUnits(percentage)
      readError = ((postCalUnit - units) / units) * 100
    }

    return { key, percentage, postCalUnit, permissibleErrorPlus, permissibleErrorMinus, readError }
  })

  const getContentToPrint = () => {
    if (props.meterInfo) {
      persistMeterInfo(props.meterInfo, { ynPrinted: true })
    }
    return elementToPrint
  }

  return (
    <div>
      <DefaultButton className="w-default-button" onClick={showModal} disabled={props.disabled} text="Cal. Curve" allowDisabledFocus/>
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <ReactToPrint
            trigger={() => <IconButton styles={iconButtonStyles} iconProps={printerIcon} ariaLabel="Print"/>}
            content={getContentToPrint}
          />
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close modal popup"
            onClick={hideModal}
          />
        </div>
        <div style={{ width: '100%', minWidth: 700, maxWidth: 800 }} ref={el => elementToPrint = el}>
          <div id="printCalibration">
            <div id="header" className="row">
              <div className="column w-100">
                <div className="column w-50 small-text" style={{ height: '100%', alignItems: 'flex-end' }}>
                  <div>
                    <div className="row" style={{ marginBottom: 4 }}>
                      <Image
                        src={waukee_engineering}
                        shouldFadeIn={false}
                        shouldStartVisible={true}
                        height={50}
                        style={{ marginRight: 6 }}
                        alt="Waukee Engineering"
                      />

                      <Image
                        src={united_process_controls}
                        height={50}
                        shouldFadeIn={false}
                        shouldStartVisible={true}
                        alt="United Process Controls"
                      />
                    </div>
                    {/*<span><strong>ISO/IEC 17025-205</strong></span>*/}
                  </div>
                </div>

                <div className="column w-30 header">
                  Flo-Meter Calibration Certificate
                </div>

                <div className="column w-20 small-text" style={{ height: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                  {showAccreditation && <div>
                    <Image
                      shouldFadeIn={false}
                      shouldStartVisible={true}
                      styles={imageStyles}
                      src={calibration}
                      height={50}
                      alt="PJLA Accreditation"
                    />
                    <span><strong>Accreditation #59186</strong></span>
                  </div>}
                </div>
              </div>
            </div>

            <div id="verticalCaption">
              <div id="sn">
                <b>S/N:</b>{` ${props.meterInfo?.["strSerial#"]}`}
              </div>
              <div id="nist">
                NIST-traceable calibration performed on the unit referenced in this document in accordance with <br/> customer requirements, published specifications and the laboratory's standard operating procedures.
              </div>
            </div>

            <div className="v-spacer"/>

            <div id="calibrationInformation">
              <div className="w-50 inline-block">
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Customer:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {props.meterInfo?.strBillName}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Sales Order:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {props.meterInfo?.strShopOrder}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Serial Number:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {props.meterInfo?.["strSerial#"]}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Model:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {props.meterInfo?.strMeterSize}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Scale:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {formatNum(props.meterInfo?.numScale)} &nbsp; {props.meterInfo?.strScaleUnit}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Gas:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {props.meterInfo?.strGas} &nbsp; &nbsp; &nbsp; <label><b>S.G.</b></label> &nbsp; {formatNum(props.meterInfo?.numSpecificGravity)}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Pressure:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {formatNum(props.meterInfo?.numPressure)} &nbsp; {props.meterInfo?.strPressUnit}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Temperature:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {formatNum(props.meterInfo?.numTemperature)} &nbsp; {props.meterInfo?.strTempUnit}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Date of Calibration:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {formatDate(props.meterInfo?.strCalDate)}
                  </div>
                </div>
              </div>

              <div className="w-50 inline-block">
                <div className="row">
                  <div className="column w-100">
                    <label><u><b>CALIBRATION TEST CONDITIONS</b></u></label>
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Procedure #:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {testProcedure}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Test Standard:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {testMeter?.strManf}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Meter Serial #:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {testMeter?.["numSerial#"]}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Test Gas:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {testGas} &nbsp; &nbsp; &nbsp; <label><b>S.G</b></label> &nbsp; 1.0
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Ref. Temp.</b></label>
                  </div>
                  <div className={rightColClass}>
                    {/*this temp unit is ALWAYS FAHRENHEIT*/}
                    {formatNum(props.meterInfo?.numTestTemp)} {TemperatureUnits.FAHRENHEIT}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>Ref. Pressure</b></label>
                  </div>
                  <div className={rightColClass}>
                    {formatNum(props.meterInfo?.numTestPress)} {PressureUnits.DEGREES_WCG.toLowerCase()}
                  </div>
                </div>
                <div className="row">
                  <div className={leftColClass}>
                    <label><b>CMC:</b></label>
                  </div>
                  <div className={rightColClass}>
                    {formatNum(testMeter?.CMC, 2)}
                  </div>
                </div>
              </div>
            </div>

            <br/>

            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>% of Scale</th>
                  <th>Scale Flow ({props.meterInfo?.strScaleUnit})</th>
                  <th>Actual Flow ({props.meterInfo?.strScaleUnit})</th>
                  <th>Permissable Error +/-</th>
                  <th>Relative % Error</th>
                </tr>
              </thead>
              <tbody>
                {dataAscendingOrder.map(row => {
                  return <tr key={row.percentage}>
                    <td>{row.percentage} %</td>
                    <td>{formatNum((row.percentage / 100) * (props.meterInfo?.numScale || 0))}</td>
                    <td>{formatNum(row.postCalUnit, 2)}</td>
                    <td>{formatError(row.permissibleErrorPlus, 2)}</td>
                    <td>{formatError(row.readError, 2)}</td>
                  </tr>
                })}
              </tbody>
            </table>

            <br/>

            <div id="calibrationChart" className="row" style={{ marginBottom: '-15px', marginTop: '-15px' }}>
              <div className="column w-100">
                <Chart data={dataAscendingOrder.reverse()}/>
              </div>
            </div>

            <p>
              {/*The instrument referenced above was calibrated using standards traceable to the National Institute of*/}
              {/*Standards and Technology (NIST) and through NIST to the International System of Units (SI).*/}
              {/*Accuracy: VDE/VDI 3513 sh2 qG=50%. Refer to our accreditation certificate for our full uncertainty statement.*/}
              {/*The results reported above apply only to the instrument referenced in this document. United Process*/}
              {/*Controls calibration services are accredited by PJLA to ISO/IEC Guide 17025:2005. This certificate is not*/}
              {/*an endorsement by PJLA, or any agency of the federal government. This calibration report may not be*/}
              {/*reproduced, except in full, without the written consent of United Process Controls.*/}

              This Instrument referenced above was calibrated using standards traceable through (NIST) to the International
              System of Units (SI). Accuracy: VDE/VDI 3513 sh2 qG=50%. Refer to our accreditation certificate for our full
              uncertainty statement. The results reported above apply only to the instrument referenced in this document.
              This calibration report may not be reproduced, except in full, without the written consent of United
              Process Controls.
            </p>

            <div className="row">
              <div className="column w-25">
                <b><small>Authorised/Calibrated By:</small></b>
              </div>
              <div className="column w-30">
                <small>{props.meterInfo?.strCalibrator}</small>
              </div>
              <div className="column w-25">
                <b><small>Date of issue:</small></b> &nbsp; {formatDate(props.meterInfo?.strCalDate)}
              </div>
              <div className="column w-40">
                <b><small>Signature:</small></b>
                <div className="column input w-100 signature"/>
              </div>
            </div>

            <br/>

            <div className="row">
              <div className="column w-50"><b>Waukee Engineering Company Inc.</b></div>
              <div className="column w-50 flex-end"><b>Page 1 of 1</b></div>
            </div>

            <hr style={{ marginTop: -8, marginBottom: -8 }}/>

            <div className="row extra-small-text">
              {/*<div className="column w-25">5600 W. Florist Ave, Milwaukee WI 53218.</div>*/}
              <div className="column w-30">6724 South 13th Street, Oak Creek, WI 53134.</div>
              <div className="column w-20 flex-end">Phone: (414) 462-8200</div>
              <div className="column w-25 flex-end">Internet: www.group-upc.com</div>
              {/*<div className="column w-25 flex-end">5028F-Rev8 - 8/10/2015</div>*/}
              <div className="column w-25 flex-end">5028F-Rev9 - 9/21/2019</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
